.status-block {
  text-align: center;
  line-height: 1.15;

  &__text {
    display: inline-block;
    background: #F5F5F5;
    border-radius: 4px;
    font-size: 10px;
    letter-spacing: 1px;
    color: #303030;
    @include heavy;
    text-transform: uppercase;
    padding: 7px 10px;

    &:before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #10AF59;
      margin-right: 6px;
    }
  }

  &--red {
    .status-block__text {
      &:before {
        background-color: $danger-color;
      }
    }
  }
}