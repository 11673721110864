.set-profile {
  &__inner {
    padding: 40px;
    background-color: #fff;

    @include mobile {
      padding: 16px;
    }
  }

  &__body {
    padding-bottom: 56px;

    @include mobile {
      padding-bottom: 30px;
    }
  }

  &__note {
    font-size: 16px;
    color: #A1A1A1;

    @include no-mobile {
      padding: 7px 0;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &__foot {
    border-top: 1px solid #ECECEC;
    padding-top: 40px;

    @include mobile {
      padding-top: 20px;
    }
  }

  &__action {
    margin-top: 48px;

    @include mobile {
      margin-top: 30px;
    }

    .btn {
      @include no-mobile {
        min-width: 227px;
        margin-right: 16px;
      }

      @include mobile {
        margin-bottom: 16px;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}