.change-tariff {
  padding: 16px 0;

  &__head {
    margin-bottom: 24px;
  }

  &__title {
    font-size: 30px;
    color: #303030;
    @include heavy;
  }

  &__body {
      padding-bottom: 26px;
        padding: 24px 0;
  }

  &__row {
    display: flex;
    margin: 0 -5px;
  }

  &__col {
    // flex: 0 0 25%;
    // max-width: 35%;
     padding: 0 5px;

    //  @media only screen and (max-width: 767px){
    //   max-width: 240px;
    // }
  }
}