.logo-block {
  &__inner {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid #FDA21A;
    overflow: hidden;
    display: flex;

    ._img {
      max-height: 100%;
      margin: auto;
    }
  }

  &--abs {
    position: absolute;
    right: 40px;
    top: 40px;
  }
}