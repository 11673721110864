.popup-improve {
  &__inner {
    @include desktop {
      display: flex;
    }
  }

  &__desc {
    line-height: 20px;
    font-size: 14px;

    @include desktop {
      flex: 0 0 775px;
      max-width: 775px;
      margin-right: 68px;
    }

    @include tablet-and-phone {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__action {
    @include desktop {
      flex: 1;
    }

    @include mobile {
      .btn {
        width: 100%;
      }
    }
  }
}