.news-block {
  &__row {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
      margin: -12px -13px;
    }
  }

  &__col {
    @include desktop {
      padding: 12px 13px;
      flex: 0 0 25%;
      max-width: 25%;
    }

    @include tablet {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--big {
      @include desktop {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}