.multi-select {
  position: relative;

  &__head {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    height: 50px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #ffffff;

    &--placeholder {
      .multi-select__text {
        color: #A1A1A1;
      }
    }

    &:hover {
      border-color: #C0C0C0;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    @include sprite('arrow-small');
    z-index: 1;
  }

  &__text {
    display: block;
    padding: 0 30px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 48px;
  }

  &__qty {
    @include heavy;
    font-size: 11px;
    letter-spacing: 1px;
    color: #303030;
    padding: 5px 8px;
    background: #E7E7E7;
    border-radius: 4px;
    margin-left: 10px;
    line-height: 1;
  }

  &__box {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: #ffffff;
    max-height: 300px;
    border: 1px solid $blue-color;
    border-top: 0;
    overflow: auto;
    z-index: 1;
    border-radius: 0 0 2px 2px;
    max-width: 100%;

    .mCustomScrollBox & {
      position: static;
    }
  }

  &__list {
    display: block;
    white-space: nowrap;
  }

  &__item {
    border-bottom: 1px solid #E0E0E0;
    padding: 0 20px 0 52px;
    display: block;
    color: #000;
    text-transform: none;
    line-height: 50px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
    text-align: left;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 12px;
      left: 20px;
      width: 24px;
      height: 24px;
      background: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      transition: .3s;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 23px;
      left: 26px;
      transform: translateY(-50%);
      @include sprite('checked-blue');
      opacity: 0;
    }

    &:hover {
      color: #000;
      background-color: #F5F5F5;
    }

    &.selected {
      &:after {
        opacity: 1;
      }
    }
  }

  &.opened {
    .multi-select {
      &__head {
        border-color: $blue-color;
        border-radius: 2px 2px 0 0;
      }

      &__arrow {
        @include sprite('arrow-small-blue');
        transform: translateY(-50%) rotate(180deg);
      }

      &__box {
        display: block;
        z-index: 2;
      }

      &--inline {
        .dropdown {
          &__arrow {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}

.js-multi-select select {
  display: none;
}