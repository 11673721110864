.page-title {
  margin-bottom: 48px;

  @include mobile {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {

  }

  &__title {
    margin-bottom: 22px;

    @include tablet {
      margin-bottom: 18px;
    }

    @include mobile {
      margin-bottom: 16px;
      font-size: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ._text {
      margin-right: 16px;
    }

    &--middle {
      @include mobile {
        font-size: 26px;
      }
    }
  }

  &__label {
    @include heavy;
    font-size: 10px;
    letter-spacing: 1px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    padding: 6px;
    line-height: 11px;
    vertical-align: middle;
    text-transform: uppercase;
  }

  &__desc {
    line-height: 20px;
    font-size: 16px;
    color: #474A4F;
    max-width: 834px;

    @include tablet-and-phone {
      line-height: 24px;
    }
  }
}