.header--lk{
  box-shadow: 0 0px 10px;
  max-height: 115px;
  position: fixed;
  left: 0px;
  width: 100%;
  z-index: 100000;
  background-color: #ffffff;
  min-height: 88px;
}

header,
.header--lk{
  &:not(.header_main_blue){
    + .wrapper__inner,{
      padding-top: 110px;
    }
  }
}

.header {
  background-color: #ffffff;
  box-shadow: 0 0px 10px;

  &__inner {
    display: flex;
    align-items: center;
    padding: 14px 0;
    justify-content: space-between;
  }

  &__title {
    font-size: 28px;
    @include black;
  }

  &__logo {
    @include sprite('logo');
  }

  &__login {
    display: none;

    @include desktop {
      display: block;
    }

    .btn {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__profile {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &__action {
    margin-left: auto;
    display: none;

    @include mobile {
      display: block;
    }
  }

  &__links {
    @include mobile {
      display: none;
    }

    a {
      font-size: 14px;
      letter-spacing: 1px;
      margin: 0 28px;
      text-transform: uppercase;
      @include heavy;

      &:hover {
        color: #1967FD;
      }

      @include mobile {
        display: block;
        margin: 0;
        box-sizing: border-box;
        padding: 32px 16px 32px 0;
        @include heavy;
        font-size: 12px;
        border-bottom: 1px solid #ECECEC;
      }
    }
  }

  &.opened {
    .header {
      &__action {
        ._open {
          position: relative;
          z-index: 200;
          &:before {
            content: '';
            @include sprite('popup-close');
          }
        }
      }

      &__links {
        @include mobile {
         position: fixed;
          top: 0;
          left: 0;
          z-index: 100;
          display: block;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 57px 0 32px 32px;
          background-color: #fff;

          &::before {
            content: 'Меню';
            display: block;
            margin-bottom: 16px;
            @include heavy;
            font-size: 30px;
            line-height: 40px;
            color: #303030;
          }
        }
      }
    }
  }

  &.header_main{
    max-height: 115px;
    position: fixed;
    left: 0px;
    width: 100%;
    z-index: 100000;
  }
}



.free_signup{
  padding-top: 12px;
  text-align: center;
  color: white;
  background-color: #fda21a;
  height: 40px;
  width: 100%;
  z-index: 100000;
}






