.static {
  max-width: 1032px;
  margin: 0 auto;

  h1 {
    max-width: 940px;
  }

  p {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;

    @include mobile {
      line-height: 26px;
      font-size: 14px;
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    margin-bottom: 105px;
    position: relative;
    margin-top: 104px;
    padding: 60px 0;
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);

    @include tablet {
      margin-bottom: 64px;
      margin-top: 56px;
    }

    @include mobile {
      margin: 55px 0;
      padding: 45px 0;
    }

    p {
      line-height: 40px;
      font-size: 24px;
      margin-bottom: 0;
      color: #303030;
      letter-spacing: 0.02em;

      @include mobile {
        line-height: 30px;
        font-size: 18px;
        letter-spacing: normal;
      }
    }

    footer {
      padding-top: 40px;
      color: #A1A1A1;
      display: block;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        padding-top: 24px;
        line-height: 24px;
        font-size: 14px;
      }
    }

    p:before, &:before {
      content: '«';
      left: 50%;
      z-index: 1;
      position: absolute;
      width: 60px;
      height: 70px;
      background: #fff;
      line-height: 70px;
      font-size: 70px;
      color: #FDA21A;
      margin-left: -30px;
    }

    &:before {
      top: -45px;
      padding-left: 15px;
    }

    p:before {
      content: '«';
      padding-left: 15px;
      bottom: -45px;
      transform: rotate(180deg);
    }
  }

  figure {
    margin: 0 0 70px 0;

    @include tablet {
      margin: 0 0 48px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .note {
    margin-top: 60px;
    line-height: 40px;
    font-size: 24px;
    padding-left: 43px;
    position: relative;
    margin-bottom: 76px;

    @include mobile {
      margin-top: 0;
      margin-bottom: 48px;
      line-height: 32px;
      font-size: 18px;
      padding-left: 24px;
    }

    &:before {
      left: 0;
      width: 5px;
      position: absolute;
      background: #FDA21A;
      top: 10px;
      bottom: 10px;
      border-radius: 2px;
      z-index: 1;
      display: block;
      content: '';

      @include mobile {
        bottom: 0;
        top: 0;
      }
    }
  }

  h2 {
    font-size: 30px;
    margin-bottom: 35px;
    display: block;
    @include heavy;

    @include mobile {
      line-height: 30px;
      font-size: 20px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
    @include heavy;

    @include mobile {
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul, ol {
    margin-bottom: 60px;
    line-height: 32px;
    font-size: 18px;

    @include tablet {
      margin-bottom: 48px;
    }

    @include mobile {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      padding-left: 42px;
      position: relative;

      @include mobile {
        padding-left: 32px;
      }

      &:before {
        content: '';
        width: 16px;
        height: 2px;
        background: #FDA21A;
        position: absolute;
        top: 13px;
        left: 0;
        z-index: 1;
        display: block;
      }
    }
  }

  li {
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    margin-left: 15px;

    @include mobile {
      margin-left: 17px;
    }

    li {
      padding-left: 15px;
      color: #FDA21A;

      @include mobile {
        padding-left: 0;
      }

      span {
        color: #303030;
      }
    }
  }
}