.acco-work {
  &__nds {
    margin-bottom: 8px;
    text-align: right;
    font-size: 13px;
    line-height: 16px;
    color: #A1A1A1;
  }

  &__item {
    position: relative;
    
    .acco-work__item & {
      padding-left: 20px;
    }

    &.active {
      .acco-work__head::before {
        transform: translateY(-50%) rotate(0);
      }
    }
  }

  &__head {
    padding: 15px 0 15px 18px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    position: relative;
    cursor: pointer;
    margin-top: -1px;
    transition: .3s;

    &:before {
      content: '';
      @include sprite('arrow-small-dark');
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(-90deg);
      transition: .3s;
    }

    &.active {
      &:before {
        transform: translateY(-50%) rotate(0);
      }
    }

    &:hover, &.hover {
      background-color: #F5F5F5;
      border-color: #F5F5F5;
      z-index: 1;

      .acco-work__action {
        opacity: 1;
        visibility: visible;
      }
    }

    .acco-work__form-qty, .acco-work__form-metric {
      line-height: 20px;
      font-size: 12px;
      color: #A1A1A1;
    }
  }

  &__title {
    font-size: 13px;
    color: #303030;
    @include bold;
    line-height: 22px;
  }

  &__comment {
    flex-shrink: 0;

    .btn-comment {
      margin: -6px 0 -6px 13px;
    }
  }

  &__action {
    padding-left: 20px;
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    ._btn {
      color: #a1a1a1;
      margin-right: 16px;
      transition: .3s;
      min-height: 20px;
      min-width: 20px;
      display: flex;

      .icon {
        margin: auto;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #303030;
      }
    }
  }

  &__sale {
    @include heavy;
    font-size: 13px;
    color: #303030;
    position: relative;

    ._old-price {
      margin-right: 16px;
      color: #C3C3C3;
      text-decoration: line-through;
      @include regular;
    }

    ._current-price {

    }

    ._sale {
      font-size: 12px;
      color: #10AF59;
      @include bold;
      position: absolute;
      left: calc(100% + 11px);
      top: 50%;
      transform: translateY(-50%);

      &--orange {
        color: #FDA21A;
      }
    }
  }

  &__body {
    display: none;
  }

  &__form {
    padding: 2px 0;

    .form {
      &-group {
        &--disabled {
          .dropdown__header {
            border-color: #fff;
            background-color: #fff;
            cursor: default;
            pointer-events: none;

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
              z-index: 5;
            }

            &:hover {
              border-color: #E0E0E0;
            }
          }
        }
      }

      &-control {
        height: 26px;
        border-width: 0 0 1px 0;
        padding: 0;

        &:disabled {
          background: #fff;
          border-width: 0 0 1px 0;

          &:active, &:focus, &:hover {
            background: #fff;
            border-width: 0 0 1px 0;
          }
        }
      }
    }

    .dropdown {
      &__header {
        border-color: transparent;
      }

      &.opened {
        .dropdown {
          &__header {
            border-color: #1967fd;
          }
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ECECEC;

      &:last-child {
        border-bottom: 0;
      }

      &--read {
        font-size: 13px;
        line-height: 16px;

        > div {
          padding: 19px 0;
        }
      }
    }

    &-text {
      margin-right: 67px;
      flex: 1;
    }

    &-qty {
      margin-right: 5px;
      flex: 0 0 150px;
      max-width: 150px;
    }

    &-metric {
      flex: 0 0 120px;
      max-width: 120px;
    }
  }

  &__footer {
    display: flex;
    margin-top: 40px;
    align-items: center;

    &-field {
      margin-left: auto;
      flex: 0 0 533px;
      max-width: 533px;
      display: flex;
      padding-left: 10px;

      .form-group {
        flex: 1;
        margin-right: -4px;
      }

      .btn {
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        background-color: #ffffff;

        &:hover {
          background-color: #303030;
        }
      }
    }
  }

  &__total {
    margin-left: auto;
    font-size: 20px;
    line-height: 24px;
    flex: 0 0 410px;
    max-width: 410px;

    &-item {
      margin-bottom: 17px;
      display: flex;

      ._label {
        flex: 0 0 210px;
        max-width: 210px;
        padding-right: 10px;
      }

      ._value {
        flex: 0 0 200px;
        max-width: 200px;
        text-align: right;

        &--left {
          text-align: left;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--inline {
        justify-content: flex-end;
        text-align: right;

        ._label {
          flex: 0 0 auto;
          max-width: none;
        }

        ._value {
          flex: 0 0 auto;
          max-width: none;
          text-align: right;

          &--left {
            text-align: left;
          }
        }
      }

      &--green {
        color: #1FB463;
      }

      &--orange {
        color: #FDA21A;
      }
    }
  }

  &--offer {
    .acco-work {
      &__item {
        .acco-work__item {
          .acco-work__head {
            background-color: #fff;
            padding-left: 18px;

            &:before {
              left: 0;
            }

            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }

      &__head {
        padding-left: 26px;

        &:before {
          left: 5px;
        }

        &:hover {
          background-color: #F5F5F5;

          .acco-work__action {
            opacity: 1;
            visibility: visible;
          }

          .acco-work__sale {
            display: none;
          }
        }
      }

      &__action {
        transition: none;
        padding-right: 20px;
      }
    }
  }

  &--compare {
    padding-left: 5px;

    .acco-work {
      &__action {
        box-sizing: border-box;
        min-width: 115px;
        opacity: 1;
        visibility: visible;
      }

      &__head {
        &:after {
          content: '';
          display: block;
          width: 5px;
          position: absolute;
          bottom: -1px;
          top: -1px;
          left: -5px;
          background-color: transparent;
          border-radius: 5px 0 0 5px;
          transition: .3s;
        }

        &:hover {
          &:after {
            background-color: #F5F5F5;
          }
        }
      }
    }
  }

  &--body {
    .acco-work {
      &__head {
        min-height: 53px;

        &::before {
          display: none;
        }
      }
      &__item {
        .acco-work__item {
          padding-left: 0;
        }
      }

      &__title {
        display: none;
      }

      &__comment {
        display: none;
      }

    }
  }
}