.main {
  padding: 48px 40px 55px;

  &__head {
    margin-bottom: 40px;
    display: flex;

    ._action {
      margin-left: auto;
      flex-shrink: 0;
      padding-left: 20px;
    }

    &--margin-b {
      margin-bottom: 60px;
    }
  }

  &__label {
    font-size: 16px;
    color: #A1A1A1;
    margin-bottom: 16px;
    display: block;

    &--small {
      font-size: 14px;
    }
  }

  &__title {
    flex: 1;
    font-size: 40px;
    @include heavy;
    color: #303030;
    display: flex;
    align-items: center;

    ._label {
      display: inline-block;
      padding: 7px;
      @include heavy;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      color: #303030;
      text-transform: uppercase;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: 16px;
      margin-top: 5px;
    }
  }

  &__info {
    max-width: 650px;
  }

  &__table {
    margin-left: auto;
    flex: 0 0 400px;
    max-width: 400px;
    background-color: #ffffff;
    padding: 24px;

    .info-list {
      &__item {
        font-size: 14px;
      }

      &__label {
        flex: 0 0 115px;
        max-width: 115px;
      }
    }
  }

  &__body {

  }
}