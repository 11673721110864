.ui {
  max-width: 1220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 10px;

  &__title {
    text-align: center;
    padding: 30px 0;
    flex-shrink: 0;
  }

  &__container {
    flex-shrink: 0;
    flex-grow: 1;
    background-color: #fcfcfc;
    //margin: 0 -10px;
    display: flex;
    width: 100%;
  }

  &__aside {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%;
    box-sizing: border-box;
    padding: 0 10px;
    background-color: #f9f9f9;

    .aside {
      &__inner {
        padding: 40px 0;
      }

      &__nav {
        ._link {
          display: block;
          font-size: 20px;
          padding: 10px 30px;
          width: 100%;
          text-align: left;

          &:hover {
            background-color: darken(#f9f9f9, 5%);
          }
        }
      }
    }
  }

  &__main {
    flex-basis: 75%;
    width: 75%;
    max-width: 75%;
    box-sizing: border-box;
    padding: 0 10px;

    .main {
      &__section {
        padding: 30px 20px;
        border-bottom: 1px solid darken(#f5f5f5, 5%);

        ._body {
          margin-top: 20px;

          .row + .row {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__colors {
    display: flex;

    ._item {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.25), 0 0 1px 0 rgba(0, 0, 0, 0.25);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}