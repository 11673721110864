.header-profile {
  &__inner {
    position: relative;

    &:hover {
      .header-profile {
        &__name {
          color: $blue-color;
        }

        &__body {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid $primary-color;
    overflow: hidden;
    display: flex;

    ._img {
      margin: auto;
    }
  }

  &__name {
    padding-left: 16px;
    flex-shrink: 0;
  }

  &__body {
    background: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    padding: 16px 16px 14px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 190px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &__list {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__link {
    &:hover {
      color: $blue-color;
    }
  }

  &__action {
    border-top: 1px solid #ECECEC;
    padding-top: 12px;
  }
}