.offer-item-compare {
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  background-color: #fff;

  transition: .3s;

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 30px;
  }

  &__body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-left {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: auto;
      margin-right: 0;

      @include media(1365) {
        min-width: 210px;
      }

      .btn {
        padding: 5px 0;
        font-size: 10px;
      }

      .offer-item-compare {
        &__options {
          display: block;
        }

        &__option {
          max-width: none;
          font-size: 18px;
          line-height: 24px;

          ._label {
            color: #303030;
            margin-bottom: 3px;
          }

          ._value {
            color: #303030;
            @include heavy;
          }
        }
      }
    }
  }

  &__link {
    margin-bottom: 28px;

    ._link {
      font-size: 14px;
      line-height: 20px;
      color: #1967fd;

      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }

  &__label {
    margin-bottom: 16px;

    ._text {
      display: inline-block;
      @include heavy;
      font-size: 10px;
      letter-spacing: 1px;
      color: #303030;
      background: #fff;
      border-radius: 4px;
      padding: 8px 11px;
      border: 1px solid rgba(48, 48, 48, 0.15);
    }
  }

  &__title {
    line-height: normal;
    font-size: 24px;
    margin-bottom: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__desc {
    line-height: 20px;
    font-size: 14px;
  }

  &__options {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: auto -10px -10px;
  }

  &__option {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    ._label {
      color: #a1a1a1;
      margin-bottom: 5px;
    }

    ._value {
      color: #303030;
    }
  }

  &__action {
    display: flex;
  }

  &__rating {
    display: flex;
    align-items: center;
    margin: 6px 0;

    ._value {
      font-size: 14px;
      color: #FFFFFF;
      @include heavy;
      background: #10AF59;
      border-radius: 4px;
      line-height: 31px;
      padding: 0 6px;
      margin-right: 11px;
    }

    ._qty {
      font-size: 14px;
      color: #303030;
    }

    &--orange {
      ._value {
        background: #FDA21A;
      }
    }

    &--red {
      ._value {
        background: #FD1A35;
      }
    }
  }

  &__btn {
    margin-left: auto;
    font-size: 0;

    ._btn {
      margin-right: 14px;
      margin-left: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__footer {
    margin-top: 40px;
    display: flex;
  }

  &__contact {
    line-height: 17px;
    font-size: 14px;
    color: #303030;

    a {
      &:hover {
        text-decoration: underline;
      }

      &[href*='mailto'] {
        color: #1967FD;
      }
    }

    ._item {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__review {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 164px;

    @include media(1365) {
      flex-direction: column;
      align-items: flex-start;
    }

    .offer-item-compare__rating {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--best {
    box-sizing: border-box;
    border: 2px solid #10AF59;

    .offer-item-compare {
      &__label {
        margin-bottom: 16px;

        ._text {
          background: #10AF59;
          border-color: #10AF59;
          color: #FFFFFF;
        }
      }
    }
  }

  &--comment {
    .offer-item-compare {
      &__btn {
        ._btn {
          margin-right: 22px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}