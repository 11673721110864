.team-item {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 24px 32px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  &__main {
    margin-right: 20px;
    flex: 1;
  }

  &__title {
    line-height: 20px;
    font-size: 14px;
    color: #303030;
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__mail {
    padding-left: 16px;
    line-height: 20px;
    font-size: 14px;
    color: #303030;
    position: relative;

    ._icon {
      width: 8px;
      height: 8px;
      background: #34BB12;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      &--orange {
        background: #FDA21A;
      }
    }
  }

  &__field {
    flex: 0 0 637px;
    max-width: 637px;
    margin-right: 20px;
  }

  &__wait {
    @include heavy;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #A1A1A1;
  }

  &__action {
    .btn-remove {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}