.add-field {
  display: flex;

  &__input {
    flex: 1;
  }

  &__btn {
    margin-left: 24px;
    flex: 0 0 114px;
    max-width: 114px;
  }
}