.btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border: 1px solid transparent;
  text-align: center;
  padding: 15px 43px;
  @include heavy;
  letter-spacing: 1px;
  border-radius: 4px;
  line-height: 1.5;
  font-size: 12px;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    min-height: 1px;
    margin-right: 5px;
    margin-top: -2px;
  }

  span + svg {
    margin-right: 0;
    margin-left: 5px;
  }

  &-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #ffffff;

    &:hover, &:focus, &:active {
      background-color: $orange-hover-color;
      border-color: $orange-hover-color;
      color: #ffffff;
    }

    &:active {
      opacity: .8;
    }

    &:disabled,
    &.disabled {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #ffffff;
    }
  }

  &-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: #ffffff;

    &:hover {
      background-color: darken($secondary-color, 5%);
      border-color: darken($secondary-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: #ffffff;
    }
  }

  &-success {
    background-color: $success-color;
    border-color: $success-color;
    color: #ffffff;

    &:hover {
      background-color: darken($success-color, 5%);
      border-color: darken($success-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $success-color;
      border-color: $success-color;
      color: #ffffff;
    }
  }

  &-danger {
    background-color: $danger-color;
    border-color: $danger-color;
    color: #ffffff;

    &:hover {
      background-color: darken($danger-color, 5%);
      border-color: darken($danger-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $danger-color;
      border-color: $danger-color;
      color: #ffffff;
    }
  }

  &-warning {
    background-color: $warning-color;
    border-color: $warning-color;
    color: #ffffff;

    &:hover {
      background-color: darken($warning-color, 5%);
      border-color: darken($warning-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $warning-color;
      border-color: $warning-color;
      color: #ffffff;
    }
  }

  &-light {
    background-color: $light-color;
    border-color: $light-color;
    color: #ffffff;

    &:hover {
      background-color: darken($light-color, 5%);
      border-color: darken($light-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $light-color;
      border-color: $light-color;
      color: #ffffff;
    }
  }

  &-dark {
    background-color: $dark-color;
    border-color: $dark-color;
    color: #ffffff;

    &:hover {
      background-color: lighten($dark-color, 5%);
      border-color: lighten($dark-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: $dark-color;
      border-color: $dark-color;
      color: #ffffff;
    }
  }

  &-outline-primary {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;

    &:hover {
      background-color: darken($primary-color, 5%);
      border-color: darken($primary-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $primary-color;
      color: $primary-color;
    }

    &.btn-loading:before {
      border-color: rgba(0, 123, 255, 0.75) rgba(0, 123, 255, 0.75) rgba(0, 123, 255, 0.25) rgba(0, 123, 255, 0.25);
    }
  }

  &-outline-secondary {
    background-color: transparent;
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: #ffffff;
    }

    &:active {
      opacity: .8;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $secondary-color;
      color: $secondary-color;
    }

    &.btn-loading:before {
      border-color: rgba(134, 142, 150, 0.75) rgba(134, 142, 150, 0.75) rgba(134, 142, 150, 0.25) rgba(134, 142, 150, 0.25);
    }
  }

  &-outline-success {
    background-color: transparent;
    border-color: $success-color;
    color: $success-color;

    &:hover {
      background-color: darken($success-color, 5%);
      border-color: darken($success-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $success-color;
      color: $success-color;
    }

    &.btn-loading:before {
      border-color: rgba(40, 167, 69, 0.75) rgba(40, 167, 69, 0.75) rgba(40, 167, 69, 0.25) rgba(40, 167, 69, 0.25);
    }
  }

  &-outline-danger {
    background-color: transparent;
    border-color: $danger-color;
    color: $danger-color;

    &:hover {
      background-color: darken($danger-color, 5%);
      border-color: darken($danger-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $danger-color;
      color: $danger-color;
    }

    &.btn-loading:before {
      border-color: rgba(220, 53, 69, 0.75) rgba(220, 53, 69, 0.75) rgba(220, 53, 69, 0.25) rgba(220, 53, 69, 0.25);
    }
  }

  &-outline-warning {
    background-color: transparent;
    border-color: $warning-color;
    color: $warning-color;

    &:hover {
      background-color: darken($warning-color, 5%);
      border-color: darken($warning-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $warning-color;
      color: $warning-color;
    }

    &.btn-loading:before {
      border-color: rgba(255, 193, 7, 0.75) rgba(255, 193, 7, 0.75) rgba(255, 193, 7, 0.25) rgba(255, 193, 7, 0.25);
    }
  }

  &-outline-light {
    background-color: transparent;
    border-color: $light-color;
    color: $light-color;

    &:hover {
      background-color: darken($light-color, 5%);
      border-color: darken($light-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $light-color;
      color: $light-color;
    }

    &.btn-loading:before {
      border-color: rgba(23, 162, 184, 0.75) rgba(23, 162, 184, 0.75) rgba(23, 162, 184, 0.25) rgba(23, 162, 184, 0.25);
    }
  }

  &-outline-dark {
    background-color: transparent;
    border-color: $dark-color;
    color: $dark-color;

    &:hover {
      background-color: lighten($dark-color, 5%);
      border-color: lighten($dark-color, 5%);
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $dark-color;
      color: $dark-color;
    }

    &.btn-loading:before {
      border-color: rgba(52, 58, 64, 0.75) rgba(52, 58, 64, 0.75) rgba(52, 58, 64, 0.25) rgba(52, 58, 64, 0.25);
    }
  }

  &-more {
    background-color: transparent;
    border-color: #EBEBEB;
    color: $secondary-color;

    &:hover {
      background-color: transparent;
      border-color: $secondary-color;
      color: $secondary-color;
    }

    &:active {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #ffffff;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: #EBEBEB;
      color: $secondary-color;
    }

    &.btn-loading:before {
      border-color: rgba(134, 142, 150, 0.75) rgba(134, 142, 150, 0.75) rgba(134, 142, 150, 0.25) rgba(134, 142, 150, 0.25);
    }
  }

  &-loading {
    color: transparent;
    pointer-events: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      height: 20px;
      width: 20px;
      border-width: 3px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25);
      border-radius: 100%;
      animation: clockwise .5s linear infinite;
    }
  }

  &-lg {
    font-size: 14px;
    padding: 13px 40px;

    &.btn-loading:before {
      margin-top: -12px;
      margin-left: -12px;
      height: 25px;
      width: 25px;
    }
  }

  &-small {
    font-size: 12px;
    padding: 8px 37px;

    &.btn-loading:before {
      margin-top: -7px;
      margin-left: -7px;
      height: 15px;
      width: 15px;
    }
  }

  &-remove {
    width: 31px;
    height: 31px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    border: 1px solid #E0E0E0;
    text-align: center;
    border-radius: 50%;
    color: #A1A1A1;
    padding-top: 2px;

    &:hover {
      color: $secondary-color;
    }
  }

  &-comment {
    height: 34px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    border: 1px solid transparent;
    font-size: 0;
    padding: 6px;
    text-align: center;
    color: #A1A1A1;
    border-radius: 4px;

    &:hover {
      background: #F5F5F5;
      border-color: #F5F5F5;
    }

    .icon {
      margin-right: 8px;
      vertical-align: middle;
    }

    ._qty {
      display: inline-block;
      vertical-align: middle;
      height: 19px;
      min-width: 19px;
      background: #303030;
      @include heavy;
      font-size: 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #FFFFFF;
      line-height: 19px;
      border-radius: 9px;
      padding: 0 6px;
    }
  }

  &-add {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    text-align: center;
    border-radius: 4px;
    color: #A1A1A1;
    font-size: 0;
    padding: 14px 9px 8px 13px;
    background-color: #F5F5F5;

    @include mobile {
      width: 40px;
      height: 40px;
      padding: 6px 0px 0px 8px;
    }

    &:hover {
      color: $secondary-color;
    }

    &:active {
      color: #fff;
      background-color: $green-color;
    }

    .icon {
      width: 23px;
      height: 23px;
    }

    &--active {
      color: #fff;
      background-color: $green-color;

      &:hover {
        color: #fff;
        background-color: $green-color;
      }
    }
  }

  &-icon {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    text-align: center;
    border-radius: 4px;
    color: #A1A1A1;
    font-size: 0;
    padding: 14px;
    background-color: #F5F5F5;

    @include mobile {
      width: 40px;
      height: 40px;
      padding: 6px 0px 0px 8px;
    }

    &:hover {
      color: $secondary-color;
    }

    &:active {
      color: #fff;
      background-color: $green-color;
    }

    .icon {
      width: 23px;
      height: 23px;
    }

    &--active {
      color: #fff;
      background-color: $green-color;

      &:hover {
        color: #fff;
        background-color: $green-color;
      }
    }
  }

  &-reset {
    padding-left: 18px;
    padding-right: 18px;
    color: #a1a1a1;
    transition: .3s;

    &:hover, &:focus, &:active {
      color: $secondary-color;
    }
  }

  &-edit {
    line-height: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include heavy;
    color: #1967FD;
    transition: .3s;

    &:hover, &:active {
      color: #1967FD;
    }

    svg {
      margin-right: 3px;
    }
  }

  &-upload {
    line-height: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include heavy;
    color: #1967FD;
    transition: .3s;

    &:hover, &:active {
      color: #1967FD;
    }

    svg {
      margin-right: 3px;
      vertical-align: middle;
    }
  }

  &-compare {
    font-size: 12px;
    padding: 10px 24px;
    background-color: #ffffff;
    border-color: #EBEBEB;
    color: $secondary-color;

    &:hover {
      background-color: #ffffff;
      border-color: $secondary-color;
      color: $secondary-color;
    }

    &:active {
      opacity: .8;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }

    ._qty {
      min-width: 19px;
      background: #303030;
      @include heavy;
      letter-spacing: 1px;
      line-height: 19px;
      height: 19px;
      font-size: 10px;
      color: #fff;
      border-radius: 9px;
      margin-left: 8px;
      transition: .3s;
      padding: 0 5px;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: $secondary-color;
      color: $secondary-color;
    }
  }

  &-count {
    position: relative;

    ._btn-qty {
      position: absolute;
      top: -7px;
      right: -7px;
      min-width: 19px;
      background: #303030;
      @include heavy;
      letter-spacing: 1px;
      line-height: 19px;
      height: 19px;
      font-size: 10px;
      color: #fff;
      border-radius: 9px;
      transition: .3s;
      padding: 0 5px;
    }
  }

  &-w100 {
    width: 100%;
  }

  &-w114 {
    width: 114px;
  }

  &-lk {
    min-width: 257px;
  }

  &-ml {
    margin-left: auto;
  }

  &-white-bg {
    background-color: #fff;
    border-color: #EBEBEB;

    &:hover {
      border-color: #EBEBEB;
    }

    &:disabled,
    &.disabled {
      background-color: #fff;
      border-color: #EBEBEB;
    }
  }

  &-download {
    box-sizing: border-box;
    padding: 15px 40px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #fff;
    border-color: #EBEBEB;

    &:hover {
      border-color: $secondary-color;
    }

    &:disabled,
    &.disabled {
      background-color: #fff;
      border-color: #EBEBEB;
    }
  }

  &-plus {
    @include regular;
    font-size: 26px;
    line-height: 20px;
    padding: 14px;
  }

  &:disabled,
  &.disabled {
    opacity: .65;
  }

  &:disabled {
    cursor: default;
  }

  &.disabled {
    pointer-events: none;
  }
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

.btn-row {
  text-align: center;
  margin-top: 48px;

  .btn {
    display: inline-block;
  }
}