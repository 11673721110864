// .popup--portfolio{
//   .popup__box{
//     padding: 176px 0 56px;
//   }

//   .popup__close{
//     top: 135px;
//   }
// }

.popup-portfolio {
  &__section {
    margin-bottom: 24px;
    background-color: #ffffff;
    padding: 40px;

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 24px 16px;
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-head {
      margin-bottom: 40px;

      @include tablet {
        margin-bottom: 29px;
      }

      @include mobile {
        margin-bottom: 24px;
      }
    }

    &-title {
      @include heavy;
      font-size: 30px;
      line-height: 40px;
      color: #303030;

      @include mobile {
        font-size: 24px;
        line-height: normal;
      }
    }

    &-body {

    }
  }

  &__nav {
    padding-bottom: 24px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    margin-bottom: 40px;

    @include no-mobile {
      justify-content: space-between;
    }

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    &-btn {
      ._text {
        line-height: 20px;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        @include heavy;
        margin-right: 16px;

        @include mobile {
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      ._icon {
        display: inline-block;
        min-width: 50px;
        height: 50px;
        border-radius: 4px;
        text-align: center;
        border: solid 1px #EBEBEB;
        background-color: #ffffff;
        font-size: 16px;
        color: #3c4a51;
        @include bold;
        line-height: 17px;
        transition: .3s;
        padding: 15px 5px;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: .5;
      }
    }

    &-prev {
      ._icon {
      }
    }

    &-next {

    }
  }

  &__main {
    @include desktop {
      display: flex;
    }

    &-left {
      @include desktop {
        flex: 0 0 500px;
        max-width: 500px;
      }

      @include tablet-and-phone {
        max-width: 500px;
        margin-bottom: 24px;
      }
    }

    &-status {
      margin-bottom: 6px;

      ._text {
        display: inline-block;
        background: #F5F5F5;
        border-radius: 4px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #303030;
        @include heavy;
        text-transform: uppercase;
        padding: 7px 10px;

        &:before {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #10AF59;
          margin-right: 6px;
        }
      }

      &--red {
        ._text {
          &:before {
            background-color: $danger-color;
          }
        }
      }
    }

    &-title {
      font-size: 20px;
    }

    &-right {
      @include desktop {
        flex: 1;
        padding-left: 78px;
      }
    }

    &-confirm {
      padding-left: 27px;
      position: relative;
      line-height: 24px;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      @include heavy;
      color: #10AF59;
      margin-bottom: 24px;

      @include tablet {
        font-size: 10px;
      }

      @include mobile {
        margin-bottom: 16px;
        font-size: 10px;
      }

      &:before {
        content: '';
        @include sprite('icon-confirm-green');
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &--red {
        color: #FD1A35;

        &:before {
          @include sprite('icon-confirm-red');
        }
      }
    }

    &-option {
      ._list {

      }

      ._item {
        margin-bottom: 10px;
        line-height: 20px;
        font-size: 14px;

        @include no-mobile {
          display: flex;
        }

        @include mobile {
          line-height: 16px;
          margin-bottom: 24px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #1a67fd;

          &:hover {
            text-decoration: underline;
            color: #1a67fd;
          }
        }
      }

      ._label {
        color: #A1A1A1;

        @include desktop {
          flex: 0 0 188px;
          max-width: 188px;
        }

        @include tablet {
          flex: 0 0 200px;
          max-width: 200px;
        }

        @include mobile {
          margin-bottom: 8px;
        }
      }

      ._value {
        @include no-mobile {
          flex: 1;
          padding-left: 20px;
        }
      }
    }
  }

  &__info {
    &-list {
      max-width: 770px;
      margin-bottom: 48px;

      @include mobile {
        margin-bottom: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      ._item {
        margin-bottom: 10px;
        line-height: 26px;
        font-size: 16px;

        @include no-mobile {
          display: flex;
        }

        @include mobile {
          line-height: 16px;
          margin-bottom: 24px;
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #1a67fd;

          &:hover {
            text-decoration: underline;
            color: #1a67fd;
          }
        }
      }

      ._label {
        color: #A1A1A1;

        @include no-mobile {
          flex: 0 0 180px;
          max-width: 180px;
        }

        @include mobile {
          margin-bottom: 8px;
        }
      }

      ._value {
        @include no-mobile {
          flex: 1;
          padding-left: 20px;
        }
      }
    }

    &-option {
      max-width: 770px;

      ._title {
        margin-bottom: 19px;
        @include heavy;
        font-size: 16px;
      }

      ._item {
        margin-bottom: 12px;

        @include no-mobile {
          display: flex;
          margin-bottom: 16px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ._label {
        line-height: 20px;
        color: #A1A1A1;

        @include no-mobile {
          font-size: 16px;
          position: relative;
          flex: 1;
          margin-right: 5px;

          span {
            display: inline-block;
            background-color: #fff;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            z-index: 1;
            padding-right: 10px;
          }

          &:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #ECECEC;
            position: absolute;
            bottom: 4px;
            right: 0;
            left: 0;
          }
        }

        @include mobile {
          font-size: 14px;
          line-height: 24px;

          span {
            &:after {
              content: ':';
            }
          }
        }
      }

      ._value {
        flex-shrink: 0;
        font-size: 16px;
        line-height: 20px;
        color: #303030;

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  &__more {
    ._desc {
      overflow: hidden;

      @include desktop {
        height: 71px;
      }

      @include tablet {
        height: 104px;
      }

      @include mobile {
        height: 156px;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 16px;

        p {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }

    ._action {
      @include no-mobile {
        padding: 24px 0;
      }
    }

    ._btn {
      margin-left: auto;
      font-size: 16px;
      color: #1967FD;
      position: relative;
      padding-right: 21px;

      @include mobile {
        font-size: 14px;
      }

      &:after {
        content: '';
        @include sprite('arrow-small-blue');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s;
      }

      &:before {
        content: attr(data-open);
      }
    }

    &.opened {
      ._desc {
        overflow: visible;
        height: auto;
      }

      ._btn {
        &:before {
          content: attr(data-close);
        }

        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}