.edit-popup {
  background-color: #f5f5f5;
  padding: 0 0 0 88px;

  &__inner {
    padding: 48px 40px 55px;
    cursor: default;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__close {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    ._btn {
      display: inline-block;
      min-width: 50px;
      height: 50px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px #EBEBEB;
      background-color: #ffffff;
      font-size: 16px;
      color: #3c4a51;
      @include bold;
      line-height: 17px;
      transition: .3s;
      padding: 15px 5px;
    }
  }

  &__title {
    @include heavy;
    line-height: 52px;
    font-size: 40px;
    color: #303030;
  }

  &__body {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-lk {
      &__head {
        padding-top: 48px;
        border-top: 1px solid #ECECEC;

        &:first-child {
          padding-top: 0;
          border-top: 0;
        }
      }

      &__body {
        margin-bottom: 48px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}