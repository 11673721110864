.signup {
  &__block {
    margin: 40px auto;
    max-width: 1198px;
    background: #fff;
    padding: 40px;

    @include mobile {
      margin: 20px 10px;
    }

    @include mobile {
      padding: 15px;
    }
  }

  &__title {
    @include heavy;
    font-size: 30px;
    line-height: 100%;
    display: block;
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  &-form {
    &__section {
      border-top: 1px solid #ECECEC;
      margin-top: 30px;
      padding-top: 45px;
      &:first-child {
        border-top: 0 none;
        margin-top: 15px;
        @include mobile {
          margin-top: 0;
        }
      }

      @include mobile {
        padding-top: 30px;
      }
    }

    .form-group {
      margin-bottom: 30px;

      @include mobile {
        margin-bottom: 0;
      }
    }

    .form-control {
      &:disabled {
        color: #a1a1a1;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;

      .btn {
        width: 320px;
        @include tablet-and-phone {
          margin-top: 20px;
        }
        @include mobile {
          width: 100%;
        }
      }

      @include tablet-and-phone {
        margin-top: 20px;
        display: block;
      }
    }

    &__agreement {
      a {
        color: #1966FD;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__cancel {
      color: #A1A1A1;
      font-size: 12px;
      line-height: 20px;
      @include heavy;
      width: 170px;
      text-transform: uppercase;
      text-align: left;
      margin: 36px 0 0 80px;

      @include tablet-and-phone {
        margin: 36px 0 0 0px;
      }
    }
  }
}