.popup-comment {
  textarea.form-control {
    line-height: 22px;
    font-size: 14px;
    padding-right: 30px;
    height: 265px;

    @include placeholder {
      color: #A1A1A1;
    }
  }

  &__list {
    overflow: auto;
    max-height: 630px;
    margin-right: -32px;
  }

  &__item {
    margin-bottom: 24px;
    position: relative;
    padding-left: 80px;
    min-height: 55px;
    margin-right: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    &-logo {
      display: flex;
      width: 55px;
      height: 55px;
      position: absolute;
      border: 2px solid #E0E0E0;
      left: 0;
      top: 0;
      border-radius: 50%;

      ._img {
        max-height: 100%;
        margin: auto;
      }
    }

    &-content {
      padding: 24px;
      background-color: #F5F5F5;
      border-radius: 0 30px 30px 30px;
      line-height: 20px;
      font-size: 14px;

      p {
        line-height: 20px;
        font-size: 14px;
      }
    }

    &--current {
      .popup-comment__item-logo {
        border-color: #1967FD;
      }
    }
  }
}