.loader {
  position: relative;
  min-height: 90px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 39;
  }

  &__circle {
    @include align;
    z-index: 40;
    @include bold;

    &:before {
      content: '';
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto 10px;

      border: 2px solid #f5f5f5;
      border-top: 3px solid $primary-color;
      border-radius: 100%;

      animation: spin 1s infinite linear;
    }
  }

  &--trans {
    &:before {
      display: none;
    }
  }
}

.loader-small {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 30px;
  border-width: 5px;
  border-style: solid;
  border-color: hsla(221.9, 98.9%, 63.9%, 0.75) hsla(221.9, 98.9%, 63.9%, 0.75) hsla(221.9, 98.9%, 63.9%, 0.25) hsla(221.9, 98.9%, 63.9%, 0.25);
  border-radius: 100%;
  animation: clockwise .5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

.loader-block {
  position: relative;
  min-height: 100px;
}