.drop-list {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }

  &__header {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    border-radius: 2px;

    &:hover {
      border-color: #C0C0C0;
    }

    .opened & {
      border-color: $blue-color;
      border-radius: 2px 2px 0 0;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include sprite('arrow-small-blue');
    z-index: 1;

    .opened & {
      @include sprite('arrow-small-blue');
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__text {
    display: block;
    padding: 0 21px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    color: $blue-color;
  }

  &__box {
    display: none;
    position: absolute;
    margin-top: 6px;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: #ffffff;
    max-height: 300px;
    overflow: auto;
    z-index: 10;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    .opened & {
      z-index: 2;
      display: block;
    }
  }

  &__list {
    display: block;
    white-space: nowrap;

    li {
      display: block;
      margin-bottom: 15px;
      white-space: nowrap;

      &:hover {
        background: transparent;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      color: #000;
      text-transform: none;
      line-height: normal;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: $blue-color;
      }
    }
  }
}