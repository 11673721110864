.table-work {
  &__inner {
    .form {
      &-group {
        &--disabled {
          .dropdown__header {
            border-color: #fff;
            background-color: #fff;
            cursor: default;
            pointer-events: none;

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
              z-index: 5;
            }

            &:hover {
              border-color: #E0E0E0;
            }
          }
        }
      }

      &-control {
        height: 26px;
        border-width: 0 0 1px 0;
        padding: 0;
        border-radius: 0;
        font-size: 13px;

        @include placeholder {
          color: #a1a1a1;
          font-size: 13px;
        }

        &:disabled {
          background: #fff;
          border-width: 0 0 1px 0;

          &:active, &:focus, &:hover {
            background: #fff;
            border-width: 0 0 1px 0;
          }
        }
      }
    }

    .dropdown {
      &__header {
        border-color: transparent;
      }

      &.opened {
        .dropdown {
          &__header {
            border-color: #1967fd;
          }
        }
      }
    }
  }

  &__head {
    line-height: 20px;
    font-size: 12px;
    color: #A1A1A1;
    border-bottom: 1px solid #ececec;
  }

  &__body {
    font-size: 13px;
    line-height: 20px;
  }

  &__row {
    display: flex;
    padding: 17px 0;
    border-bottom: 1px solid #ececec;
    align-items: center;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__col {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    &._col-1 {
      flex: 1;
    }

    &._col-2 {
      flex: 0 0 70px;
      max-width: 70px;
    }

    &._col-3 {
      flex: 0 0 44px;
      max-width: 44px;
    }

    &._col-4 {
      flex: 0 0 94px;
      max-width: 94px;
    }

    &._col-5 {
      flex: 0 0 122px;
      max-width: 122px;
    }

    &._col-6 {
      flex: 0 0 101px;
      max-width: 101px;
    }

    &._col-7 {
      flex: 0 0 95px;
      max-width: 95px;
    }
  }

  &__sale {
    position: relative;
    margin: -10px 0;

    ._old-price {
      color: #C3C3C3;
      text-decoration: line-through;
      @include regular;
    }

    ._current-price {

    }

    ._sale {
      font-size: 12px;
      color: #10AF59;
      @include bold;
      position: absolute;
      left: calc(100% + 11px);
      top: 50%;
      transform: translateY(-50%);

      &--orange {
        color: #FDA21A;
      }
    }
  }

  .option-menu__action {
    font-size: 13px;
    line-height: 20px;
    color: #303030;
  }
}