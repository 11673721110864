.wrapper {
  display: flex;
  flex-direction: column;

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  &__inner {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }

  &--lk {
    padding-left: 88px;
    min-width: 1024px;
  }
}