.doc-load {
  &__inner {

  }

  &__head {
    position: relative;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .text-error {
      position: absolute;
      bottom: 10px;
      left: 17px;
      font-size: 16px;
      color: $danger-color;
    }
  }

  &__field {
    ._input {
      display: none;
    }
  }

  &__label {
    text-align: center;
    padding: 49px;
    display: block;
    border: 1px dashed #D6D6D6;
    border-radius: 4px;
    cursor: pointer;
    transition: .25s;

    &.highlight {
      border: 1px solid $orange-hover-color;
    }

    .btn {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ._label {
      font-size: 16px;
      color: #303030;
      display: block;
    }
  }

  &__body {

  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  &__col {
    padding: 10px;
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  }

  &__item {
    display: block;
    background: #C4C4C4;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      display: block;
      @include fill;
      background: linear-gradient(360deg, #080808 -15.96%, rgba(0, 0, 0, 0) 92.79%);
      transform: rotate(180deg);
      opacity: 0;
      visibility: hidden;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      padding-top: 64.7%;
    }

    &:hover {
      &:before, ._btn {
        opacity: 1;
        visibility: visible;
      }
    }

    ._img {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ._btn {
      position: absolute;
      top: 13px;
      right: 9px;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      z-index: 2;
    }
  }

  &__progress {
    background: #E5E5E5;
    border-radius: 2px;
    overflow: hidden;
    margin-top: -5px;

    ._line {
      background: #FDA21A;
      height: 5px;
    }
  }
}