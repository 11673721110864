.btn-list {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    margin-right: 10px;
    padding: 5px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}