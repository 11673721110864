.tooltip {
  position: sticky;
  width: auto;
  min-width: 320px;
  padding: 15px 10px;
  margin-right: 55px;
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  font-size: 14px;
  color: #303030;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  border-radius: 5px;
  z-index: 50;

  transition: opacity 0.3s;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid;
    border-bottom-color: #ffffff;
    border-color: transparent transparent #ffffff transparent;
    top: -18px;
    right: 22px;
  }
}

.tooltip li {
  display: flex;
  padding: 10px;
  margin: 0;
  width: auto;
  background-color: #ffffff;
  color: #000;
  cursor: pointer;
  list-style: none;
  box-sizing: border-box;

    &:first-child {
      .js-tooltip-count{
        position: relative;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          top: 5px;
          left: -18px;
          border-radius: 50%;
          background-color: #28a745;
        }
      }

      .js-tooltip-price {
        color: #28a745;
      }
    }

    .js-tooltip-title {
      margin-right: auto;
    }

    & span:last-child {
      min-width: 100px;
      margin-left: 15px;
    }
  }

.js-tooltip-field span {
  margin-left: 15px;

  &:last-child {
    margin-left: auto;
  }
}

.tooltip.visible {
  opacity: 1;
  transition-delay: 0s;
}

.tooltip.enabled {
  opacity: 0;
}

