.search-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  padding-top: 110px;
  z-index: 30;

  @include no-mobile {
    width: 475px;
    right: -500px;
  }

  @include mobile {
    right: 0;
    left: 0;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__head {
    flex-shrink: 0;
    position: relative;
    padding: 32px 32px 16px;

    @include mobile {
      padding: 32px 16px 8px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #D1D1D1;
  }

  &__title {
    font-size: 30px;
    color: #303030;
    @include heavy;
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    position: relative;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__body {
    flex-grow: 1;
    overflow: auto;

    &-inner {
      padding: 0 32px;
      min-height: 100%;

      @include mobile {
        padding: 0 16px;
      }
    }
  }

  &__foot {
    flex-shrink: 0;
    background: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);

    @include no-mobile {
      padding: 17px 32px;
      display: flex;
      align-items: center;
    }

    @include mobile {
      padding: 16px;
    }

    .btn {
      @include no-mobile {
        margin-right: 12px;
        flex-grow: 1;

        &:last-child {
          margin-right: 0;
        }
      }

      @include mobile {
        margin-bottom: 6px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ._btn {
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;
      color: #A1A1A1;
    }
  }

  &__section {
    border-bottom: 1px solid #ECECEC;

    &-head {
      font-size: 16px;
      color: #303030;
      position: relative;
      padding: 24px 0 24px 24px;
      @include heavy;
      cursor: pointer;

      &:after {
        content: '';
        @include sprite('arrow-small-dark');
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: .3s;
      }

      &.active {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &-body {
      padding: 8px 0 24px;
      display: none;

      ._title {
        font-size: 16px;
        color: #303030;
        @include heavy;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ._list {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
        .check-box,
        .form-group {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.opened {
    opacity: 1;
    visibility: visible;

    @include no-mobile {
      right: 0;
    }
  }

}