.company-common {
  background-color: #ffffff;
  border-radius: 4px;

  @include no-mobile {
    margin-bottom: 24px;
  }

  @include mobile {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    @include desktop {
      padding: 40px;
    }

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 24px 16px;
    }
  }

  &__head {
    @include desktop {
      margin-bottom: 38px;
    }

    @include tablet {
      margin-bottom: 29px;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 30px;
    color: #303030;
    @include heavy;

    @include mobile {
      font-size: 24px;
    }
  }

  &__body {

  }

  &__list {

  }

  &__item {

    @include no-mobile {
      margin-bottom: 16px;
      line-height: 20px;
      font-size: 16px;
      display: flex;
    }

    @include mobile {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ._label {
      color: #A1A1A1;

      @include no-mobile {
        flex: 0 0 210px;
        max-width: 210px;
      }

      @include mobile {
        margin-bottom: 8px;
      }

      a{
        &.company-common__item{
          
        }
      }
    }

    ._value {
      @include no-mobile {
        flex: 1;
        padding-left: 20px;
      }
    }
  }
}