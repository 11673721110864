.company-portfolio {
  &__inner {

  }

  &__row {
    @include desktop {
      display: flex;
      margin: -8px;
      flex-wrap: wrap;
    }
  }

  &__col {
    @include desktop {
      padding: 8px;
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include tablet {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    background-color: #fff;
    border-radius: 4px;
    transition: .3s;
    position: relative;

    &:hover {
      box-shadow: 0px 5px 8px rgba(130, 130, 130, 0.15);
    }
  }

  &__link {
    position: absolute;
    @include fill;
    z-index: 1;
  }

  &__head {
    border-bottom: 1px solid #ECECEC;

    @include no-mobile {
      display: flex;
      align-items: center;
      padding: 12px 12px 12px 34px;
    }

    @include tablet {
      padding-left: 24px;
    }

    @include mobile {
      padding: 16px;
    }
  }

  &__confirm {
    padding-left: 27px;
    position: relative;
    line-height: 24px;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include heavy;
    color: #10AF59;

    @include mobile {
      margin-bottom: 16px;
      font-size: 10px;
    }

    &:before {
      content: '';
      @include sprite('icon-confirm-green');
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &--red {
      color: #FD1A35;

      &:before {
        @include sprite('icon-confirm-red');
      }
    }
  }

  &__status {
    margin-left: auto;

    ._text {
      display: inline-block;
      background: #F5F5F5;
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 1px;
      color: #303030;
      @include heavy;
      text-transform: uppercase;
      padding: 7px 10px;

      &:before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #10AF59;
        margin-right: 6px;
      }
    }

    &--red {
      ._text {
        &:before {
          background-color: $danger-color;
        }
      }
    }
  }

  &__body {
    @include desktop {
      padding: 24px 32px 32px;
    }

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 24px 16px;
    }
  }

  &__title {
    @include heavy;
    font-size: 20px;
    color: #303030;
    margin-bottom: 8px;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__label {
    line-height: 20px;
    font-size: 14px;
    color: #303030;
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__option {
    &-list {

    }

    &-item {
      margin-bottom: 6px;
      line-height: 20px;
      font-size: 14px;

      @include no-mobile {
        display: flex;
      }

      @include mobile {
        line-height: 16px;
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #1a67fd;

        &:hover {
          text-decoration: underline;
          color: #1a67fd;
        }
      }

      ._label {
        color: #A1A1A1;

        @include no-mobile {
          flex: 0 0 200px;
          max-width: 200px;
        }

        @include mobile {
          margin-bottom: 8px;
        }
      }

      ._value {
        @include no-mobile {
          padding-left: 20px;
        }
      }
    }
  }
}