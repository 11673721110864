.pagination {
  margin: 40px 0;

  @include mobile {
    margin: 24px 0;
  }

  &__inner {

  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    margin-right: 8px;

    &:hover:not(.pagination__item--disabled):not(.pagination__item--current) {
      .pagination__link {
        border-color: #4A4A4A;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &--current {
      .pagination__link {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
      }
    }

    &--next, &--end  {
      @include no-mobile {
        margin-left: 16px;
      }

      .pagination__link {
        padding: 15px 5px;
      }
    }

    &--prev, &--start {
      @include no-mobile {
        margin-right: 24px;
      }
      .pagination__link {
        padding: 15px 5px;
      }
    }

    &--disabled {
      .pagination__link {
        cursor: default;
        pointer-events: none;
        color: #E0E0E0;
      }
    }
  }

  &__link {
    display: inline-block;
    min-width: 50px;
    height: 50px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px #EBEBEB;
    background-color: #ffffff;
    font-size: 16px;
    color: #3c4a51;
    @include bold;
    line-height: 17px;
    padding: 16px 5px;
    transition: .3s;
  }
}