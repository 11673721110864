.radio {
  &-box {
    display: block;
    position: relative;
  }
}

[type="radio"] {
  display: none;

  &:checked ~ .radio-label {
    &:before {
      border-color: $primary-color;
      box-shadow: inset 0 0 0 6px $primary-color;
    }

    &:hover {
      &:before {
        border-color: $primary-color;
      }
    }
  }

  ~ .radio-label {
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: $base-color;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: #fff;
      border: 1px solid #D7D7D7;
      transition: .3s;
    }

    &:hover {
      &:before {
        border-color: #C0C0C0;
      }
    }
  }
}