.info-list {
  &__more-btn {
    margin-left: auto;
    margin-bottom: 16px;
    font-size: 16px;
    color: #1967FD;
    position: relative;
    padding-right: 21px;

    @include mobile {
      font-size: 14px;
    }

    &::after {
      content: '';
      @include sprite('arrow-small-blue');
      position: absolute;
      right: 0;
      top: 58%;
      transform: translateY(-50%);
      transition: .3s;
    }

    &::before {
      content: attr(data-open);
    }
  }

  &__more-box {
    display: none;
  }

  &__item {
    display: flex;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    flex: 0 0 220px;
    max-width: 220px;
    margin-right: 20px;
    color: #A1A1A1;
  }

  &__value {
    flex: 1;
    color: #303030;
    max-width: 555px;
  }

  &--small {
    .info-list__label {
      flex: 0 0 180px;
      max-width: 180px;
    }
  }

  &--vertical {
    .info-list {
      &__more-box {
        box-sizing: border-box;
        padding: 32px;
        background-color: #FFFFFF;
      }

      &__item {
        margin-bottom: 24px;
        flex-direction: column;
      }

      &__label {
        flex-basis: auto;
      }
    }
  }

  &.opened {
    .info-list {
      &__more-btn {
        &::before {
          content: attr(data-close);
        }

        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &__more-box {
        display: block;
      }
    }
  }
}