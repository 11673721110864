.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
}

.popup {
  position: fixed;
  overflow-x: hidden;
  background: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10000;
  overflow-y: auto;
  padding: 133px 16px;

  @include tablet {
    padding: 50px 16px;
  }

  @include mobile {
    padding: 16px;
  }

  &__box {
    width: 100%;
    max-width: 1190px;
    padding: 40px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0 8px 40px rgba(15, 31, 59, 0.15);
    border-radius: 4px;
    position: relative;
    cursor: default;

    @include tablet {
      padding: 32px;
    }

    @include mobile {
      padding: 36px 16px 24px;
    }

    &-inner {
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    @include sprite('popup-close');
  }

  &__title {
    @include heavy;
    font-size: 30px;
    margin-bottom: 40px;

    @include mobile {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 32px;
    }

    &--margin-b-small {
      margin-bottom: 24px;
    }
  }

  &__body {
    margin-bottom: 56px;

    @include mobile {
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {

  }

  &__action {
    font-size: 0;

    .btn {
      @include no-mobile {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }

      @include mobile {
        margin-bottom: 16px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-primary {
        @include no-mobile {
          min-width: 209px;
        }
      }
    }

    &--right {
      text-align: right;
    }

    &--feedback {
      display: flex;

      .btn {
        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }
  }

  &--fill {
    @include tablet-and-phone {
      padding: 0;
      .popup {
        &__box {
          border-radius: 0;
        }
      }
    }
  }

  &--feedback {
    @include mobile {
      padding: 0;
    }

    .popup {
      &__box {
        max-width: 820px;
        padding: 32px;

        @include mobile {
          border-radius: 0;
          padding: 16px;
        }
      }

      &__action {
        @include mobile {
          display: flex;
          flex-direction: column-reverse;

          .btn {
            margin-bottom: 0;
            margin-top: 16px;

            &:last-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &--feedback2 {
    @include mobile {
      padding: 0;
    }

    .popup {
      &__box {
        max-width: 945px;
        padding: 32px;

        @include mobile {
          padding: 16px;
          border-radius: 0;
        }
      }

      &__action {
        @include mobile {
          display: flex;
          flex-direction: column-reverse;

          .btn {
            margin-bottom: 0;
            margin-top: 16px;

            &:last-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &--portfolio {
    background-color: #f3f3f3;
    padding: 0;

    .popup {
      &__box {
        padding: 76px 0 56px;
        box-shadow: none;
        background-color: transparent;

        @include tablet {
          padding: 80px 0 32px;
        }

        @include mobile {
          padding: 16px 0;
        }
      }

      &__close {
        @include tablet {
          top: 24px;
          right: 24px;
        }

        @include mobile {
          top: 24px;
          right: 16px;
        }
      }
    }
  }

  &--user {
    .popup {
      &__box {
        max-width: 540px;
        padding: 32px;
      }

      &__title {
        font-size: 24px;
      }
    }
  }

  &--comment {
    .popup {
      &__box {
        max-width: 820px;
        padding: 32px;
      }

      &__title {
        font-size: 24px;
        margin-bottom: 20px;
      }

      &__body {
        margin-bottom: 30px;
      }
    }
  }

  &--winner, &--sale {
    .popup {
      &__box {
        max-width: 818px;
        padding: 32px;
      }

      &__title {
        font-size: 24px;
      }

      &__body {
        font-size: 18px;

        p {
          font-size: 18px;
        }
      }

      &__action {
        .btn-primary {
          min-width: 145px;
        }
      }
    }
  }

  &--compare {
    .popup {
      &__box {
        max-width: 1220px;
        padding: 32px;
        background: #F5F5F5;
        box-shadow: 0 8px 40px rgba(15, 31, 59, 0.15);
      }

      &__title {
        font-size: 24px;
      }

      &__body {
        font-size: 18px;

        .compare__offers {
          width: auto;
        }

        .compare__params-option-item ._label {
          color: #a1a1a1;
        }

        p {
          font-size: 18px;
        }
      }

      &__action {
        .btn-primary {
          min-width: 145px;
        }
      }
    }
  }
}