.company-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    border-radius: 4px;
    background-color: #ffffff;

    @include tablet {
      position: relative;
    }
  }

  &__head {
    line-height: 24px;
    font-size: 14px;
    color: #A1A1A1;
    border-bottom: 1px solid #ECECEC;

    @include desktop {
      display: flex;
      align-items: center;
      padding: 12px 32px;
    }

    @include tablet {
      padding: 19px 74px 19px 24px;
    }

    @include mobile {
      padding: 16px;
    }
  }

  &__type {
    @include desktop {
      margin-right: 30px;
    }

    @include mobile {
      margin-bottom: 12px;

      ._inline {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__year {
    @include desktop {
      margin-left: auto;
    }

    @include mobile {
      ._inline {
        display: block;
      }
    }
  }

  &__body {
    padding: 30px;

    @include no-mobile {
      display: flex;
    }

    @include desktop {
      min-height: 235px;
      position: relative;
    }

    @include mobile {
      position: relative;
      padding: 16px;
    }
  }

  &__left {
    @include no-mobile {
      flex: 0 0 120px;
      max-width: 120px;
      margin-right: 40px;
    }

    @include mobile {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__logo {
    border: 3px solid #FDA21A;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-bottom: 24px;

    @include mobile {
      flex: 0 0 50px;
      height: 50px;
      max-width: 50px;
      margin-right: 16px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ._img {
    }
  }

  &__status {
    text-align: center;

    ._text {
      display: inline-block;
      background: #F5F5F5;
      border-radius: 4px;
      font-size: 10px;
      letter-spacing: 1px;
      color: #303030;
      @include heavy;
      text-transform: uppercase;
      padding: 7px 10px;

      &:before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #10AF59;
        margin-right: 6px;
      }
    }

    &--red {
      ._text {
        &:before {
          background-color: $danger-color;
        }
      }
    }
  }

  &__main {
    flex: 0 0 495px;
    max-width: 495px;
  }

  &__title {
    font-size: 24px;
    color: #303030;
    margin-bottom: 16px;
    @include heavy;

    @include tablet-and-phone {
      color: #1967FD;
    }

    @include mobile {
      font-size: 20px;
    }

    ._link {
      &:hover {
        text-decoration: underline;
        color: #303030;

        @include tablet-and-phone {
          color: #1967FD;
        }
      }
    }
  }

  &__address {
    line-height: 20px;
    font-size: 14px;
    color: #303030;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__rating {
    display: flex;
    align-items: center;

    @include desktop {
      position: absolute;
      top: 30px;
      right: 30px;
    }

    @include tablet-and-phone {
      margin-bottom: 24px;
    }

    @include mobile {

    }

    &:last-child {
      margin-bottom: 0;
    }

    ._value {
      font-size: 14px;
      color: #FFFFFF;
      @include heavy;
      background: #10AF59;
      border-radius: 4px;
      line-height: 31px;
      padding: 0 6px;
      margin-right: 11px;
    }

    ._qty {
      font-size: 14px;
      color: #303030;
    }
  }

  &__table {
    @include mobile {
      padding-bottom: 30px;
      position: relative;
    }

    &-head {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      ._title {
        line-height: 30px;
        font-size: 16px;
        color: #303030;
        @include heavy;
      }

      ._btn {
        margin-left: auto;
        font-size: 16px;
        color: #1967FD;
        position: relative;
        padding-right: 21px;

        @include mobile {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:after {
          content: '';
          @include sprite('arrow-small-blue');
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: .3s;
        }

        &:before {
          content: attr(data-open);
        }
      }
    }

    &-body {
      height: auto;
      overflow: hidden;

      @include mobile {
        height: 108px;
      }
    }

    &.opened {
      .company-item__table-body {
        overflow: visible;
        height: auto;
      }

      .company-item__table-head {
        ._btn {
          &:before {
            content: attr(data-close);
          }

          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }

  &__option {
    &-item {
      margin-bottom: 12px;

      @include no-mobile {
        display: flex;
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      ._label {
        line-height: 20px;
        color: #A1A1A1;

        @include no-mobile {
          font-size: 16px;
          position: relative;
          flex: 1;
          margin-right: 5px;

          span {
            display: inline-block;
            background-color: #fff;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            z-index: 1;
            padding-right: 10px;
          }

          &:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #ECECEC;
            position: absolute;
            bottom: 4px;
            right: 0;
            left: 0;
          }
        }

        @include mobile {
          font-size: 14px;
          line-height: 24px;

          span {
            &:after {
              content: ':';
            }
          }
        }
      }

      ._value {
        flex-shrink: 0;
        font-size: 16px;
        line-height: 20px;
        color: #303030;

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  &__action {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 156px;
    right: 30px;

    @include tablet-and-phone {
      top: 17px;
      right: 24px;
    }

    @include mobile {
      top: 21px;
      right: 16px;
    }
  }
}