.news-item {
  &__inner {
    background: #FBFBFB;
    position: relative;
    border-radius: 4px;

    @include tablet {
      display: flex;
      height: 180px;
    }
  }

  &__badge {
    z-index: 1;
    font-size: 10px;
    letter-spacing: 1px;
    position: absolute;
    height: 25px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    background: #FDA21A;
    border-radius: 4px;
    top: 15px;
    left: 15px;
    padding: 0 8px;

    @include tablet {
      top: 39px;
      left: 19px;
    }
  }

  &__link {
    display: block;

    @include tablet {
      flex: 0 0 245px;
      max-width: 245px;
    }
  }

  &__img {
    display: block;
    overflow: hidden;
    position: relative;
    border-radius: 4px 4px 0 0;
    font-size: 0;

    @include desktop {
      .home-news .news-item--big & {
        &:before {
          padding-top: 66.6%;
        }
      }
    }

    @include tablet {
      height: 100%;
      border-radius: 4px 0 0 4px;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 63.6%;
    }

    ._img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    min-height: 162px;

    @include tablet {
      padding: 32px 157px 32px 32px;
    }

    @include mobile {
      min-height: 124px;
      padding: 16px;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
    display: block;
    @include heavy;

    &:hover {
      color: #1966FD;
    }
  }

  &__date {
    line-height: 100%;
    font-size: 14px;
    color: #A1A1A1;
    display: block;
    margin-top: auto;

    @include mobile {
      font-size: 12px;
      height: 20px;
    }
  }

  &--big {
    .news-item {
      &__inner {
        @include tablet {
          display: block;
          height: auto;
        }
      }

      &__link {
        @include tablet {
          max-width: none;
        }
      }

      &__img {
        @include tablet {
          border-radius: 4px 4px 0 0;
          height: auto;

          &:before {
            padding-top: 43.6%;
          }
        }
      }

      &__content {
        @include desktop {
          padding: 32px;
          max-width: 430px;
        }

        @include tablet {
          padding: 32px;
        }

        @include no-mobile {
          min-height: 204px;
        }
      }

      &__title {
        @include no-mobile {
          font-size: 26px;
          line-height: 32px
        }
      }
    }
  }

  &--vertical {
    .news-item {
      &__inner {
        @include desktop {
          display: flex;
          height: 180px;
        }
      }

      &__link {
        @include desktop {
          flex: 0 0 245px;
          max-width: 245px;
        }
      }

      &__img {
        @include desktop {
          height: 100%;
          border-radius: 4px 0 0 4px;
        }
      }

      &__content {
        @include desktop {
          padding: 32px;
          flex: 1;
        }
      }
    }
  }
}