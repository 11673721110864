.works-selector__container { 
    min-width: 1197px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox; 
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 80px 43px 20px; 
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; 
    overflow-y: scroll;

    .works-selector__checkbox-group--uncheck{
      .works-selector__checkboxes-container{
        display: none;

        &.works-selector__checkboxes-container__show{
          display: block;
        }
      }
    }
}


@media (max-width:1023px) {
    .works-selector__container {
      min-width: auto;
      padding: 40px 16px;
  }
}

@media (max-width:767px) {
    .works-selector__container {
      padding: 16px;
  }
}

.works-selector__checkbox-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 360px;
    max-width: 360px;
    height: 349px;
    overflow-y: scroll;
    background-color: #f5f5f5;
    margin-right: 16px;
    padding: 31px 22px 0 24px;
    margin-bottom: 15px;
}

.works-selector__checkbox-group--check .works-selector__check-button, .works-selector__checkbox-group--uncheck .works-selector__check-button, .works-selector__checkbox-group--uncheck .works-selector__uncheck-button {
    display: block;
}

@media (max-width:1023px) {
    .works-selector__checkbox-group {
      width: calc(50% - 8px);
      padding-right: 8px;
      padding-top: 28px;
      min-width: auto;
      max-width: none;
  }
  .works-selector__checkbox-group:nth-child(2n) {
      margin-right: 0;
  }
}

@media (max-width:767px) {
    .works-selector__checkbox-group {
      width: 100%;
      margin-right: 0;
      padding-left: 16px;
      padding-right: 16px;
      height: auto;
      max-height: 349px;
  }
}

.works-selector__checkboxes-container {
    overflow-y: scroll;
}

.works-selector__checkboxes-header, .works-selector__checkboxes-header--light {
    display: inline-block;
    font-family: "Lato-Bold";
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
}

.works-selector__checkboxes-header--light {
    color: #a1a1a1;
    margin-bottom: 17px;
}

.works-selector__checkboxes-header--additional {
    margin-top: 9px;
}

.works-selector__checkboxes-header--small-width {
    min-width: 204px;
    max-width: 204px;
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .works-selector__checkboxes-header--secondary {
      margin-bottom: 11px;
  }
}

@media screen and (max-width: 767px){
    .works-selector__checkboxes-header--light:not(:last-child):not(.works-selector__checkboxes-header--secondary), .works-selector__checkboxes-header:not(:last-child):not(.works-selector__checkboxes-header--secondary) {
      margin-bottom: 18px;
  }
  .works-selector__checkboxes-header--light {
      margin-top: 10px;
  }
  .works-selector__checkboxes-header--secondary {
      margin-bottom: 9px;
  }
}

.works-selector__checkbox-row {
    margin-bottom: 16px;
}

@media (max-width:1023px) {
    .works-selector__checkbox-row {
      margin-bottom: 22px;
  }
}

@media (max-width:767px) {
    .works-selector__checkbox-row {
      margin-bottom: 20px;
  }
}

.works-selector__checkbox-row--small-width {
    padding-right: 30px;
}

.works-selector__header-container {
    position: relative;
    margin-bottom: 20px;
    min-height: 27px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width:767px) {
    .works-selector__header-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
  }
}

.works-selector__uncheck-button {
    display: none;
    background-color: #fff;
    min-width: 101px;
    line-height: 24px;
    letter-spacing: 1px;
}

.works-selector__check-button, .works-selector__uncheck-button {
    color: #303030;
    border: 1px solid #e0e0e0;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px;
    text-align: center;
}

.works-selector__check-button span, 
.works-selector__uncheck-button span {
    color: #b4b4b4;
    font-size: 14px;
    font-weight: 800;
}

@media (max-width:1023px) {
    .works-selector__check-button, .works-selector__uncheck-button {
      position: static;
  }
}

.works-selector__check-button {
    position: relative;
    display: none;
    min-width: 117px;
    background-color: #fff;
    min-height: 27px;
    line-height: 10px;
    padding-left: 18px;
    letter-spacing: .6px;
}

.works-selector__check-button:before {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url(../images/svg/sprite.svg);
    -webkit-background-size: 972px 705px;
    background-size: 972px 705px;
    width: 14px;
    height: 14px;
    background-position: -150px -133px;
    position: absolute;
    top: 4px;
    left: 7px;
}

@media (max-width:1023px) {
    .works-selector__check-button {
      position: relative;
      right: auto;
  }
}

.works-selector__check-button:hover,
.works-selector__uncheck-button:hover {
    opacity: .7;
}

.works-selector__selected-items {
    min-width: 1112px;
    max-width: 1112px;
    background-color: #f5f5f5;
    padding: 31px 22px 25px 24px;
}

@media (max-width:1023px) {
    .works-selector__selected-items {
      width: calc(50% - 8px);
      min-width: auto;
      max-width: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 349px;
      overflow-y: scroll;
  }
  .works-selector__selected-items:nth-child(2n) {
      margin-right: 0;
  }
}

@media (max-width:767px) {
    .works-selector__selected-items {
      width: 100%;
      margin-right: 0;
      height: auto;
      padding: 25px 14px 25px 16px;
  }
}

.works-selector__selected-items li {
    margin-bottom: 10px;
}

.works-selector__selected-items li svg {
    color: #cecece;
    width: 20px;
    height: 20px;
}

.works-selector__selected-items span {
    vertical-align: top;
    margin-left: 5px;
    z-index: 1;
}

.works-selector__selected-items li svg:hover {
    color: #b6b6b6;
}

.works-selector__selected-items button span {
    position: absolute;
    display: none;
    top: 22px;
    left: -29px;
    padding-top: 7px;
    width: 68px;
    height: 31px;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.works-selector__selected-item {
    font-family: "Lato-Medium";
    font-style: normal;
    font-size: 16px;
    padding-left: 51px;
}

@media (max-width:1023px) {
    .works-selector__selected-item {
      display: block;
      font-size: 14px;
      line-height: 17px;
      padding-left: 23px;
      padding-right: 45px;
  }
}

@media (max-width:767px) {
    .works-selector__selected-item {
      padding-right: 0;
  }
}

.works-selector__selected-item--small-padding {
    display: inline-block;
    padding-left: 21px;
    max-width: 800px;
}

.offer-item-compare__inner {
    transition: all 0.4s ease;
}

.compare-slider__list .slick-track {
    transition: all 0.4s ease;
}

.side-nav {
  background-color: #292929;
  position: fixed;
  left: 0;
  width: 88px;
  bottom: 0;
  top: 0;
  z-index: 10;
  // margin-top: 89px;
  margin-top: 168px;
}

.works-selector__container-title {
  display: block !important;
  width: 100%;
  margin-bottom: 25px;
}

.works-selector__container-title span {
  font-weight: 800;
  font-size: 40px;
  line-height: 52px;
  color: #303030;
}

.works-selector__checkbox-group {
  display: inline-block;
  margin: 0 12px 15px 0 !important;
}

.works-selector__header-container .title-works-selector {
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 36px !important;
  color: #303030 !important;
}

.typeOfWork {
  margin-bottom: 25px;
}

.typeOfWork:last-of-type {
  margin-bottom: 0;
}

.typeOfWork > .row {
  margin: 0;
}

.typeOfWork > .row > div {
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  padding-top: 17px;
}

.typeOfWork > .row > div button {
  display: block;
  margin-left: 23px;
}

.typeOfWork > .row .table-value {
  padding-right: 41px;
  padding-top: 0;
}

.table-title > .row {
  margin: 0;
}

.table-title > .row > div {
  padding: 0;
}

.table-title > .row > div span {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #303030;
  margin-left: 0 !important;
  margin-bottom: 16px;
  display: block;
}

.works-selector__selected-items {
  padding-bottom: 40px !important;
}

.card-wrapper_box {
  padding-left: 128px;
  padding-top: 40px;
  padding-bottom: 100px;
  padding-right: 40px;
}

.cardProduct {
  background: white;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 24px;
}

.cardProduct:hover {
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}

.cardProduct .row {
  margin: 0;
}

.cardProduct .row div {
  padding: 0;
}

.cardProduct .date,
.cardProduct .name {
  font-size: 14px;
  line-height: 24px;
  color: #A1A1A1;
}

.cardProduct .date {
  margin-right: 48px;
}

.cardProduct .information {
  padding: 17px 0 15px 32px !important;
}

.cardProduct .status {
  padding: 17px 32px 12px 0 !important;
}

.cardProduct .status .status-block {
  background: #F5F5F5;
  padding: 3px 10px 6px 23px !important;
  max-width: 97px;
  margin-left: auto;
}

.cardProduct .status .status-block .status-block_text {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  color: #303030;
  position: relative;
}

.cardProduct .status .status-block .status-block_text:before {
  content: '';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #54A880;
  top: 2px;
  left: -12px;
}

.cardProduct .cardTitle {
  border-bottom: 1px solid #ECECEC;
}

.cardProduct .cardBody {
  padding: 32px !important;
  position: relative;
}

.cardProduct .cardBody:hover .editInformation {
  visibility: visible !important;
  opacity: 1 !important;
}

.cardProduct .cardBody > div {
  padding: 0;
}

.cardProduct .cardBody .internal-item {
  margin-bottom: 20px;
}

.cardProduct .cardBody h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #303030;
  margin-bottom: 32px;
}

.cardProduct .cardBody .denotation {
  padding-right: 32px;
}

.cardProduct .cardBody .denotation.denotation_border{
  padding-right: 8px;
}

.cardProduct .cardBody .denotation.denotation_border p{
  border-bottom: 1px solid #ECECEC;
  height: 21px;
}

.cardProduct .cardBody .denotation.denotation_border span{
  display: inline-block;
  background-color: #FFFFFF;
  padding-right: 8px;
}

.cardProduct .cardBody .denotation span {
  font-size: 16px;
  color: #A1A1A1;
}

.cardProduct .cardBody .denotation-description span {
  font-size: 16px;
  color: #303030;
}

.cardProduct .cardBody .denotation-description span a {
  color: #1967FD;
}

.cardProduct .cardBody .denotation-description .denotation-description_text {
  max-width: 570px;
  display: block;
  height: 75px;
  overflow: hidden;
  margin-bottom: 17px;
}

.cardProduct .cardBody .denotation-description ._action {
  width: auto;
  position: relative;
  display: inline-block;
}

.cardProduct .cardBody .denotation-description ._action button {
  font-size: 16px;
  color: #1967fd;
  position: relative;
  padding-right: 21px;
}

.cardProduct .cardBody .denotation-description ._action:after {
  content: '';
  display: block;
  background-color: transparent;
  background-image: url(../images/svg/sprite.svg);
  background-size: 972px 705px;
  width: 13px;
  height: 8px;
  background-position: -48px -35px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.cardProduct .cardBody .tenders-text {
  position: absolute;
  bottom: 30px;
  right: 32px;
  text-align: end;
}

.cardProduct .cardBody .tenders-text span {
  font-weight: 600;
  font-size: 18px;
  color: #1967FD;
}

.cardProduct .cardBody .editInformation {
  position: absolute;
  top: 43px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cardProduct .cardBody .editInformation svg {
  margin-right: 5px;
}

.cardProduct .cardBody .editInformation span {
  text-transform: uppercase;
  line-height: 20px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Lato-Heavy","Arial","Helvetica","Tahoma",sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1967fd;
}

.cardProduct .none-padding {
  padding: 5px 40px 0 0 !important;
}

.portfolio {
  padding: 84px;
}

@media (min-width: 1023px){
  .works-selector__checkbox-group{
    width: calc(33% - 9px);
    float: left;
    min-width: 1px;
    max-width: 500px;
  }

  .works-selector__selected-items{
    width: 100%;
  }
}

@media (max-width: 1023px){
  .works-selector__checkbox-group{
    width: calc(33% - 9px);
  }  

  .works-selector__selected-items{
    width: 100%;
  } 
}

@media (max-width: 1023px){
  .works-selector__checkbox-group{
    width: 100%;
  }  

  .works-selector__selected-items{
    width: 100%;
  } 
}


.form-group--autocomplete__disabled input{
  pointer-events: none;
}

.form-group--autocomplete__disabled ._dropdown__box{
  display: none !important;
}

.form-group--autocomplete__disabled ._dropdown.opened input{
  border: 1px solid #e0e0e0 !important;
}

.multi-select__item.desabled{
      pointer-events: none;
    opacity: 0.5;
}

/*новые стили для страницы project-add*/

.documents-block .section-lk__head {
  margin-bottom: 40px;
}

.documents_inner{
  padding: 0px;
}

.documents-block .documents_inner > .row{
  margin-bottom: 16px;
  padding: 16px;
}

.documents-block .documents_inner a {
  display: inline-block;
  margin-right: 32px;
}

.documents-block .documents_inner a img {
  margin: 0;
}

.documents-block .documents_inner img {
  border-radius: 5px;
  margin-right: 24px;
  max-width: 83px;
  max-height: 53px;
}

.documents-block .documents_inner .inner-body {
  padding: 16px 24px;
  background: #F5F5F5;
  border-radius: 5px;
}

.documents-block .documents_inner .inner-text {
  vertical-align: middle;
}

.documents-block .documents_inner .inner-text b {
  font-size: 16px;
  line-height: 19px;
  display: block;
  margin-bottom: 5px;
}

.documents-block .documents_inner .inner-text b a{
  font-size: 16px;
  line-height: 19px;
  display: block;
}

.documents-block .documents_inner .inner-text small {
  font-size: 14px;
  color: #A1A1A1;
  text-transform: uppercase;
}

.documents-block .documents_inner .date-text {
  color: #A1A1A1;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  text-align: end;
}

.button_doc {
  display: inline-block;
  border: 1px solid #303030;
  border-radius: 4px;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #303030;
  padding: 12px 50px;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.button_doc:hover {
  background: #303030;
  color: #fff;
}

.button_doc:active {
  opacity: .8;
}

.document-add {
  padding: 48px 0;
}

.button-fil {
  border-radius: 4px !important;
}

.bolder {
  font-weight: 600;
}

.full {
  position: relative;
}

.full:before {
  content: '';
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  left: -webkit-calc(50% - 16px);
  left: calc(50% - 16px);
  top: -webkit-calc(50% - 16px);
  top: calc(50% - 16px);
  background: url(../images/plus.png) no-repeat, #ffffff;
  border-radius: 50%;
  background-position: 55% 0;
}

.modal .modal-dialog {
  max-width: 1200px;
}

.modal .modal-dialog .modal-content {
  padding: 0 40px 40px 40px;
  border: none;
}

.modal .modal-dialog .modal-content .modal-header {
  padding-top: 33px;
  padding-bottom: 29px;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.modal .modal-dialog .modal-content .modal-header button {
  opacity: .8;
}

.modal .modal-dialog .modal-content .modal-header button:hover {
  opacity: 1;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.hidden_inputs{
  display: none;
}

.list-documents__inner{
  padding: 0;
}

.message_doc,
.button_doc{
  display: inline-block;
  float: left;
}

.message_doc.disabled,
.button_doc.disabled{
  display: none;
}

#modal_documents_preview .modal-body{
  text-align: center;
}

#modal_documents_preview .modal-body img{
  display: inline-block;
  float: none;
}

.documents_inner [type="file"]{
  display: none;
}

.documents-block.documents-block__no-edit .documents_inner a img{
  max-width: 118px;
  max-height: 75px;
}

.documents-block.documents-block__no-edit .documents_inner .inner-body .col-auto img{
  margin-right: -8px;
}

.documents-block.documents-block__no-edit .documents_inner .inner-body .col-3{
  margin-right: -17px;
}

.documents-block.documents-block__no-edit .documents_inner > .row {
    margin-bottom: -16px;
}

.documents-block.documents-block__no-edit .documents_inner .inner-body .col-4{
  margin-left: auto;
  margin-right: 0px;
}

.documents-block.documents-block__no-edit .documents_inner a{
  margin-right: 0px;
}

.overlay-block{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  opacity: 0.4;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

#modal_edit_logo .modal-body{
  text-align: center;
}

.cr-overlay.maxOver{
  top: 0 !important;
  left: 0 !important;
  width: 400px !important;
  height: 400px !important;
}

.tender-item_collection .tender-item__body .tender-item__title a {
  font-weight: 800;
}

.tender-item_collection .tender-item__foot {
  padding-top: 0;
}

.tender-item_collection .tender-item__content-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 23px;
}

.tender-item_collection .tender-item__table-head {
  margin-bottom: 12px;
}

.tender-item_collection .tender-item__table-head .tender-item__table-row {
  margin-bottom: 12px;
}

.tender-item_collection .tender-item__table-body .tender-item__table-row {
  margin-bottom: 21px;
}

.tender-item_collection .tender-item__table-col_inner {
  position: relative;
  display: inline-block;
}

.tender-item_collection .tender-item__table-col_inner img {
  position: absolute;
  top: 0;
  right: -26px;
}

.tender-item_collection .tender-item__table-col._col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30.8%;
      -ms-flex: 0 0 30.8%;
          flex: 0 0 30.8%;
  max-width: 30.8%;
}

.tender-item_collection .tender-item__table-col._col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 17.3%;
      -ms-flex: 0 0 17.3%;
          flex: 0 0 17.3%;
  max-width: 17.3%;
}

.tender-item_collection .tender-item__table-col._col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 22.4%;
      -ms-flex: 0 0 22.4%;
          flex: 0 0 22.4%;
  max-width: 22.4%;
}

.tender-item_collection .tender-item__table-col._col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 19.5%;
      -ms-flex: 0 0 19.5%;
          flex: 0 0 19.5%;
  max-width: 19.5%;
}

.tender-item_collection .tender-item__table-col._col-5 {
  margin-left: 0;
}

.tender-item_collection .tender-item__action {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
  padding-bottom: 4px;
  position: relative;
}

.tender-item__option {
  .hint-block {
    margin-right: 20px;
  } 
}

.tender-item_collection .tender-item__action:before {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
  background: url(../images/Shape.svg) no-repeat center;
  background-size: cover;
  width: 13px;
  height: 8px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.tender-item_collection .js-open-block-buttom:before {
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.tender-item__foot .tender-item__foot_title {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  margin-bottom: 30px;
}

.tender-item__foot .group_of_works {
  display: block;
  width: 100%;
}

.tender-item__foot .group_of_works > div {
  display: inline-block;
}

.tender-item__foot .group_of_works .group_of_works_name {
  width: 248px;
  padding-right: 25px;
}

.group_of_works_inner,
.group_of_descr_inner {
  font-size: 16px;
  line-height: 1;
  color: #A1A1A1;
  display: block;
  margin-bottom: 22px;
}

.group_of_works_inner:last-of-type,
.group_of_descr_inner:last-of-type {
  margin-bottom: 0;
}

.group_of_descr_inner {
  color: #303030;
}

.info-list--vertical .info-list__item > div {
    display: inline-block;
    margin-right: 0;
}

.about_tenders_text .about_tenders_element {
    margin-bottom: 5px;
}

.about_tenders_text .about_tenders_element .about_tenders_element_label {
    font-size: 14px;
    color: #a1a1a1;
    margin-right: 20px;
}

.about_tenders_text .about_tenders_element .about_tenders_element_value {
    font-size: 14px;
    line-height: 24px;
    color: #000;
}

.offer-item-compare__body {
    align-items: start;
}

.js-resize_block {
    height: auto;
}

.js-resize_block .offer-item-compare__body-right {
    margin-left: 0!important;
    align-self: flex-end;
    margin-right: auto;
}

.offer-item-compare__body-right {
    max-width: 200px;
}

.compare-table__slider .table-work__head{
    font-size: 10px;
}

.offer-item--best .offer-item__label {
    display: block;
    width: 100%;
}

.footer__social{
    padding-top: 0;
}

.footer__social .social-footer-icon-block a{
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    line-height: 1;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transition: all 0s ease !important;
}

.footer__social .social-footer-icon-block a:last-of-type{
    margin-right: 0;
}

.footer{
  .footer__social .social-footer-icon-block a{
    &.facebook_footer{
        background: url(../images/icon-footer-facebook__bl.svg);
    }

    &.facebook_footer:hover {
        background: url(../images/icon-footer-facebook-color.svg);
    }

    &.inst_footer{
        background: url(../images/icon-footer-inst__bl.svg);
    }

    &.inst_footer:hover{
        background: url(../images/icon-footer-inst-color.svg);
    }

    &.vk_footer{
        background: url(../images/icon-foter-vk__bl.svg);
        background-position: 72% 72% !important;
    }

    &.vk_footer:hover{
        background: url(../images/icon-foter-vk-color.svg);
        background-position: 72% 72% !important;
    }

    &.linkin_footer{
        background: url(../images/icon-footer-linkin__bl.svg);
    }

    &.linkin_footer:hover{
        background: url(../images/icon-footer-linkin-color.svg);
    }
  }
  

  &.footer--black{
    .footer__social .social-footer-icon-block a{
      &.facebook_footer{
          background: url(../images/icon-footer-facebook.svg);
      }

      &.facebook_footer:hover {
          background: url(../images/icon-footer-facebook-color.svg);
      }

      &.inst_footer{
          background: url(../images/icon-footer-inst.svg);
      }

      &.inst_footer:hover{
          background: url(../images/icon-footer-inst-color.svg);
      }

      &.vk_footer{
          background: url(../images/icon-foter-vk.svg);
          background-position: 72% 72% !important;
      }

      &.vk_footer:hover{
          background: url(../images/icon-foter-vk-color.svg);
          background-position: 72% 72% !important;
      }

      &.linkin_footer{
          background: url(../images/icon-footer-linkin.svg);
      }

      &.linkin_footer:hover{
          background: url(../images/icon-footer-linkin-color.svg);
      }
    }
  }
}

.about_company_button_block .info-list__more-btn{
    margin-bottom: 0;
}

.about-company-block{
    padding-bottom: 40px;
    position: relative;
}

.about-company-block .about_company_button_block{
    position: absolute;
    left: 0;
    bottom: 5px;
}

.about-company-block span .hide {
  display:none;
}

.about-company-block span.more .hide {
  display:inline;
}

.about-company-block span.more .crop {
  display:none;
}
.hint-block{
    display: inline-block;
    position: relative;
    width: 1px;
    height: 16px;
}
.hint-parent{
    display: inline-block;
    position: absolute;
    width: 16px;
    top: 3px;
    left: 4.5px;
    height: 16px;
    background: url('../images/hint.svg') no-repeat center;
    transition: background 0.4s ease
}
.hint-parent:hover{
    cursor: pointer;
    background: url('../images/hint-color.svg') no-repeat center; 
}
.auth__form-row {
  margin: 0;
}
.form-group{
  margin: 0;
}
.form-group .help-block__head{
  padding: 0;
}
.section-lk__bottom--border_wrapper {
  padding-bottom: 0;
}
.section-lk__bottom--border {
  border-bottom: 1px solid #ececec;
  padding-bottom: 16px;
}
.download_logo_block {
  padding-left: 52px;
}
.filling-block_wrapper {
  max-width: 450px;
  padding: 14px 24px 14px 20px;
  background: #fff;
  margin-left: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 2px 15px 3px rgba(171, 171, 171, 0.08);
}
.filling_block_load{
  position: relative;
  cursor: pointer;
}
.filling_block_load:after{
  content: '';
  position: absolute;
  display: block;
  width: 17px;
  height: 17px;
  right: 0;
  top: calc(50% - 8.5px);
  background: url(../images/filling-arrow.svg) no-repeat center;
  transition: transform 0.5s ease;
}
.filling-open:after{
  transform: rotate(180deg);
}
.filling_block_text{
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.filling_block_text span{
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: block;
  color: #000;
}

.filling_block_text small{
  font-size: 14px;
  line-height: 20px;
  color: #FDA21A;

  // &.max-indicator{
  //   color: #10af59;
  // }

  // &.min-indicator{
  //   color: #fd1a35;
  // }
}

.filling_block_load{
  .percent_load{
    .bar,.fill{
      border-color: #fda21a !important;
    }
  }

  .filling_block_text small{
    color: #FDA21A !important;
  }

  &.max-indicator{
    .percent_load{
      .bar,.fill{
        border-color: #10af59 !important;
      }
    }

    .filling_block_text small{
      color: #10af59 !important;
    }
  }

  &.min-indicator{
    .percent_load{
      .bar,.fill{
        border-color: #fd1a35 !important;
      }
    }

    .filling_block_text small{
      color: #fd1a35 !important;
    }
  }
}

.filling_block_detail{
  padding: 28px 24px 24px 24px;
  background: #fff;
  display: none;
  position: absolute;
  right: 0;
  min-width: 100%;
  z-index: 5;
}
.filling_block_detail_inner,
.filling_block_detail_button{
  display: block;
  float: left;
  width: 100%;
}

.filling_block_detail_inner .fill-title,.filling_block_detail_inner .fill-value{
  display: inline-block;
  width: 60%;
  font-size: 16px;
  line-height: 38px;
  color: #303030;
    float: left;
}
.filling_block_detail_inner .fill-value{
  text-align: right;
  width: 39%;
}
.filling_block_detail_button{
  margin-top: 20px;
}

.filling_block_detail_button .col{
  width: 50% !important;
}

.filling_block_detail_button button{
  font-size: 12px;
  line-height: 20px;
  width: calc(100% - 5px);
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.filling_block_detail_button button:last-of-type{
  margin-right: 0;
}
.filling-button-row >div:nth-child(2n+1){
  text-align: left;
}
.filling-button-row >div:nth-child(2n){
  text-align: right;
}
.main_block_row{
  margin: 0;
  margin-bottom: 24px;
}
.main_block_row .main__head{
  margin-bottom: 0;
}
.hint-text{
    position: absolute;
    padding: 16px;
    border-radius: 5px;
    background: #fff;
    transform: translateX(-42%);
    top: 22px;
    min-width: 362px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 11;
    display: none;
}

.hint-block{
  // &:hover{
  //   .hint-text{
  //     display: block;
  //   }
  // }

  &.hint-block__right{
    .hint-text{
      top: auto;
      left: calc(100% + 30px);
      -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
      transform: translateX(0%);
      min-width: 462px;
      bottom: -20px;
    }
  }
}

.hint-text p{
    font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
}
.js-open-filling-block{
    -webkit-box-shadow: 0px 10px 17px -8px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 10px 17px -8px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 10px 17px -8px rgba(34, 60, 80, 0.2);
}
.filling-block_wrapper.js-open-filling-block{
    box-shadow: 0px 2px 15px 3px rgba(171, 171, 171, 0.15);
}
.social_element{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    vertical-align: middle;
}
.social_element:last-of-type{
    margin-right: 0;
}
.social_element_image{
    display: block;
}
.social_row{
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (min-width: 320px) and (max-width: 1200px){
    .footer__social .footer__contact{
        display: block;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px){
    .footer__contact-list{
        display: block;
    }
    .footer__social .footer__contact .footer__contact-item{
        display: block;
        margin-bottom: 10px;
    }
}

.rect-auto, .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie, .c100 .bar, .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #FDA21A;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill, .c100.p51 .bar:after, .c100.p51 .fill, .c100.p52 .bar:after, .c100.p52 .fill, .c100.p53 .bar:after, .c100.p53 .fill, .c100.p54 .bar:after, .c100.p54 .fill, .c100.p55 .bar:after, .c100.p55 .fill, .c100.p56 .bar:after, .c100.p56 .fill, .c100.p57 .bar:after, .c100.p57 .fill, .c100.p58 .bar:after, .c100.p58 .fill, .c100.p59 .bar:after, .c100.p59 .fill, .c100.p60 .bar:after, .c100.p60 .fill, .c100.p61 .bar:after, .c100.p61 .fill, .c100.p62 .bar:after, .c100.p62 .fill, .c100.p63 .bar:after, .c100.p63 .fill, .c100.p64 .bar:after, .c100.p64 .fill, .c100.p65 .bar:after, .c100.p65 .fill, .c100.p66 .bar:after, .c100.p66 .fill, .c100.p67 .bar:after, .c100.p67 .fill, .c100.p68 .bar:after, .c100.p68 .fill, .c100.p69 .bar:after, .c100.p69 .fill, .c100.p70 .bar:after, .c100.p70 .fill, .c100.p71 .bar:after, .c100.p71 .fill, .c100.p72 .bar:after, .c100.p72 .fill, .c100.p73 .bar:after, .c100.p73 .fill, .c100.p74 .bar:after, .c100.p74 .fill, .c100.p75 .bar:after, .c100.p75 .fill, .c100.p76 .bar:after, .c100.p76 .fill, .c100.p77 .bar:after, .c100.p77 .fill, .c100.p78 .bar:after, .c100.p78 .fill, .c100.p79 .bar:after, .c100.p79 .fill, .c100.p80 .bar:after, .c100.p80 .fill, .c100.p81 .bar:after, .c100.p81 .fill, .c100.p82 .bar:after, .c100.p82 .fill, .c100.p83 .bar:after, .c100.p83 .fill, .c100.p84 .bar:after, .c100.p84 .fill, .c100.p85 .bar:after, .c100.p85 .fill, .c100.p86 .bar:after, .c100.p86 .fill, .c100.p87 .bar:after, .c100.p87 .fill, .c100.p88 .bar:after, .c100.p88 .fill, .c100.p89 .bar:after, .c100.p89 .fill, .c100.p90 .bar:after, .c100.p90 .fill, .c100.p91 .bar:after, .c100.p91 .fill, .c100.p92 .bar:after, .c100.p92 .fill, .c100.p93 .bar:after, .c100.p93 .fill, .c100.p94 .bar:after, .c100.p94 .fill, .c100.p95 .bar:after, .c100.p95 .fill, .c100.p96 .bar:after, .c100.p96 .fill, .c100.p97 .bar:after, .c100.p97 .fill, .c100.p98 .bar:after, .c100.p98 .fill, .c100.p99 .bar:after, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-right: 23px;
  background-color: #E3E3E3;
}
.c100 *, .c100 *:before, .c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.c100 {
  font-size: 56px;
  vertical-align: middle;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 4em;
  line-height: 4em;
  font-weight: bold;
  font-size: 14px;
  color: #262626;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -o-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -o-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -o-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -o-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -o-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -o-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -o-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -o-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -o-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -o-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -o-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -o-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -o-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -o-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -o-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -o-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -o-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.lk_body_text {
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 0;
  color: #474a4f;
  font-weight: 500;
}

.form-group .help-block__head {
  padding: 0px;
}

.form-group .control-label {
  margin: 13px 0;
  margin-top: 0;
}

span._text {
  font-weight: 500 !important;
}

.dropdown__text {
  font-weight: 500;
}

p {
  font-weight: 500;
}

.works-selector__container {
  padding: 0px 0px 70px;
  min-width: 0px;
  max-width: 1125px;
}

.tagValues-field__col {
  min-width: 99%;
}

.address-field__col._col-6,
.address-field__col._col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  max-width: 30%;
}

.address-field__col._col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
  max-width: 40%;
}

.address-field__col._col-3,
.address-field__col._col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  max-width: 30%;
}

.address-field__col._col-2,
.address-field__col._col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

.form-control {
  font-weight: 500;
  color: #303030;
}

[type=checkbox] ~ .check-label {
  font-weight: 500;
}

input {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.control-label-custom {
  margin: 20px 0;
}

.social_services .section-lk__body{
    margin-bottom: 5px;
}
.social_services .section-lk__body:last-of-type{
    margin-bottom: 50px;
}

// .section-lk.documents-block .form-group .row{
//   margin: 0px;
// }

// .section-lk.documents-block .documents_inner .row_inner_html{
//   padding-left: 0px;
//   padding-right: 0px;
// }

.section-lk.documents-block .document-add{
  padding-top: 0px;
}

.section-lk__body__confirm{
  padding-top: 0px;
  padding-bottom: 0px;
}

.icon-edit{
    background-image: url('../images/edit__icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.wrapper--lk{
    .auth__form-row{
        margin-bottom: 0px;
    }
}

.m-0 {
    margin: 0!important;
}

.p-0 {
    padding: 0!important;
}



.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100001 !important;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background-image: url('../images/close.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.ml-auto, .mx-auto {
    margin-left: auto!important;
}

.pb-0, .py-0 {
    padding-bottom: 0!important;
}

.mb-5, .my-5 {
    margin-bottom: 3rem!important;
}

.pl-0{
  padding-left: 0px !important;
}

.documents-block{
    + .section-lk__action.mb-5{
        .check-box{
            margin-bottom: .5rem;

            span{
                font-weight: bold;
            }
        }
    }
}

.edit-logo__img-inner{
    img {
        margin: auto;
        max-height: 100%;
        width: auto;
        -webkit-border-radius: 70px;
        border-radius: 70px;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.form-group{
  &.field-textarea,
  &.field-company-description{
    .help-block{
      bottom: auto;
      top: 0;
      right: 0;

      &:hover{
        .help-block__body{
          top: 20px;
          right: -70px;
        }
      }

      .help-block__body{
        top: 20px;
        right: -70px;
      }
    }

    .help-block__head{
      &:before{
        top: 0;
        right: 0;
      }
    }
  }

  &.field-company-description{
    .help-block{
      bottom: auto;
      top: 16px;
      right: 0;
    }
  }

  .help-block__head{
    &:before{
      top: auto;
      right: 19px;
      bottom: 17px;
    }
  }

  .help-block.help-block-error{
      right: -62px;
  }
}

.overlay-block{
  z-index: 100000 !important;
}

.typeWorks ul.info-list li.info-list__item .builds, 
.company-common__list .company-common__item .builds{
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    font-weight: bold;
}

.typeWorks ul.info-list li.info-list__item .info-list__label,
.company-common__list .company-common__item ._label{
    display: inline-block;
    margin: 10px;
    width: 20%;
    vertical-align: top;
    font-weight: bold;
}

.typeWorks ul.info-list li.info-list__item ._value,
.company-common__list .company-common__item ._value{
    display: inline-block;
    width: 75%;
    line-height: 23px;
    margin: 10px;
    font-weight: bold;
}

.typeWorks ul.info-list li.info-list__item,
.company-common__list li.company-common__item {
    display: block;
    list-style-type: none;
}

.info-list__label_custom{
  width: auto!important;

  a{
    transition: 0.3s;
    font-size: 18px;

    &:hover{
      color: #1967fd;
    }
  }
}

.popup--portfolio{
  .popup__box{
    padding: 176px 0 56px;
  }

  .popup__close{
    top: 135px;
  }
}

.works-selector__container :nth-child(3) {
    margin-right: 0px;
}

.hidden {
    display: none !important;
}

.works-selector__container {
    overflow: unset;
}
.works-selector__uncheck-button, .works-selector__check-button {
    margin-right: 20px;
    //display: block !important;
}
.works-selector__checkboxes-container {
    margin-right: 0px;
}

// @media only screen and (min-width: 1000px){
//   .company-item__main__about{
//     -ms-flex: 0 0 835px !important;
//     flex: 0 0 835px !important;
//     max-width: 835px !important;
//   }
// }

@media only screen and (min-width: 1231px){
    .company-item__main__about{
        -ms-flex: 0 0 835px !important;
        flex: 0 0 835px !important;
        max-width: 835px !important;
    }
    .company-item--blue_style{
        .company-item__body{
            min-height: 319.6px;
            .company-item__main{
                display: flex;
                flex-direction: column; 
            }
            .company-item_information{
                margin-top: auto;
            }
        }
    }
    .tender-item--construction {
        .tender-item__inner {
            min-height: 364.5px;
        }
    }
}


.company-item__table-body p{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.info-list__item__key-word a{
  text-transform: uppercase;
}

.overlay-ajax-popup{
  display: block;
  position: fixed;
  z-index: 7999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  opacity: 1;
}

/* СМЕТА */

.estimate{

    padding-left: 88px;

    .no_space{
        padding: 0;
    }

    .row_no_space{
        margin: 0;
        position: relative;
    }

    .estimate_inner{
        background: white;  
        padding: 40px 40px 0 40px;
        margin-bottom: 53px;
        max-width: 1650px;
        margin: 0 auto; 
        overflow: hidden;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style : none; /* IE 10+ */
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
    }

    .title_estimate{
        padding: 0;
        margin-bottom: 32px;
        span{
            font-family: "Lato-Medium";
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 36px;
            color: #303030;
        }

    }

    .text_estimate{
        margin-bottom: 48px;
    }

    .button_row_wrapper{

        position: sticky;
        left: 0;
        top: 168.09px;
        width: 100%;
        background: white;
        z-index: 12;

        // &.header_not_line{
        //   top: 168.6px;
        // }

        .button_row_estimate{
            background: #F5F5F5;
            border-radius: 4px;
            padding: 12px;

            >div{
                flex: 0 0 auto!important;
                max-width: fit-content!important;
            }

            .button_estimate{
                background: white; 
                border: 1px solid #EBEBEB;
                cursor: pointer;
                font-family: "Lato-Medium";
                padding: 13px 24px;
                font-style: normal;
                font-weight: 800;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 1px;
                text-transform: uppercase; 
                color: #303030;
                display: inline-block; 
                margin-right: 12px;
                transition: all 0.4s ease, border 0s ease; 
                border-radius: 4px;

                &:last-of-type{
                    margin-right: 0;
                }

                &.disabled{
                    cursor: initial;
                    pointer-events: none;
                    background: #ECECEC;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    border-radius: 4px;
                }

                &:hover{
                    background: #303030;
                    color: #FFFFFF;
                }

                &:active{
                    background: #303030;
                    opacity: 0.8;
                    color: #FFFFFF;
                }



            }

            input[type="file"]{
                display: none;
            }

            .download_block{

                display: inline-block; 

                label{
                    &.button_estimate{
                        margin-right: 12px;
                    }

                    &.disabled{
                        cursor: initial;
                        pointer-events: none;
                        background: #ECECEC;
                        border: 1px solid #E0E0E0;
                        box-sizing: border-box;
                        border-radius: 4px;
                    }
                }

            }
            
        }

    }

    .estimate_main{

        position: relative;

        .column_title_row{

            margin-top: 32px; 

            span{
                font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: #A1A1A1;
            }
            .column_title_row_item{
                border-right: 1px solid #ECECEC;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 6px;
                padding-bottom: 20px;
                // &:first-of-type{
                //     flex: 0 0 auto!important;
                //     max-width: 49px!important;
                // }
                // &:nth-of-type(5){
                //     flex: 0 0 auto!important;
                //     max-width: 178px!important;
                // }
                // &:nth-of-type(6){
                //     flex: 0 0 auto!important;
                //     max-width: 116px!important;
                // }
                &:last-of-type{
                    border-right: none;
                    // flex: 0 0 auto!important;
                    // max-width: 153px!important;
                }
            }
        }
        .table_row{

            min-height: 55px;
            border-top: 1px solid #ECECEC;
            transition: all 0.4s ease, border 0s ease; 
            cursor: pointer;

            &.disabled_row{
                pointer-events: none;
            }

            &.table_section{
                background: #FBFBFB;
            }

            .element_functions{

                position: absolute; 
                right: 5px;
                top: 0;
                opacity: 0; 
                z-index: -20; 
                transition: opacity 0.4s ease;
                background: white;
                padding: 0 10px;
                height: 100%;
                display: table;

                &:before{
                  content: '';
                  height: 100%;
                  display: block;
                  width: 43px;
                  position: absolute;
                  left: -21px;
                  top: 0;
                  background: linear-gradient(270deg, #FFFFFF 52.71%, rgba(255, 255, 255, 0) 93.33%);
                  z-index: -20;
                  transition: opacity 0.4s ease;
                  opacity: 0;
                }

                .element_functions_inner{
                  display: table-cell;
                  height: 100%;
                  vertical-align: middle;
                }

                a{
                    display: inline-block; 
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                    transition: background 0.4s ease; 
                    vertical-align: middle;

                    &:last-of-type{
                        margin-right: 0;
                    }

                    &.move{
                        background-image: url(../images/move.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/move_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }

                    &.inner_move{
                        background-image: url(../images/move.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/move_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }

                    &.l2_move{
                        background-image: url(../images/move.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/move_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }

                    &.l3_move{
                        background-image: url(../images/move.svg);
                        background-position: center;
                        background-repeat: no-repeat; 
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/move_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }

                    &.l4_move{
                        background-image: url(../images/move.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/move_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }

                    &.remove{
                        background-image: url(../images/remove.svg);   
                        background-position: center;
                        background-repeat: no-repeat; 
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/remove_color.svg);
                        }
                    }

                    &.comments{
                        background-image: url(../images/comment.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 19px;
                        &:hover{
                            background-image: url(../images/comment_color.svg);
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }
                }
            }

            .table_row_item{
                border-right: 1px solid #ECECEC;
                min-height: 55px;
                .count_item{
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #A1A1A1;
                    width: 100%;
                    text-align: center;
                }
                span{
                    font-family: "Lato-Medium";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #303030;
                }
                input{
                    width: 100%; 
                    border: none;
                    background: transparent; 
                    font-family: "Lato-Medium";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #303030;
                    text-align: center;
                    &.disabled{
                        pointer-events: none; 
                    }
                }
                textarea{
                    display: inline-block;
                    box-sizing: border-box;
                    resize: none;
                    font-family: "Lato-Medium";
                    font-style: normal;
                    font-weight: normal;
                    background: transparent; 
                    font-size: 14px;
                    line-height: 20px;
                    color: #303030;
                    border: none;
                    max-width: 420px;
                    min-width: 40px;
                    padding-top: 7px;
                    --animate-duration: 0.5s;
                    &::-webkit-scrollbar{  
                        width: 0;
                        height: 0;
                        background: transparent;
                    }
                    scrollbar-width: none;
                    -ms-overflow-style : none; /* IE 10+ */
                    overflow: -moz-scrollbars-none;
                }
                .dropdown{
                    width: 100%;
                    min-height: 20px;
                    height: 100%;

                    &.before{
                        .dropdown__box{
                            top: auto;
                            bottom: 100%;
                            border-top: 1px solid #ECECEC;
                            border-bottom: none;
                        }
                    }
                }
                .dropdown__header{
                    height: 100%;
                    border: none;
                    background: transparent;
                    .dropdown__arrow{
                        display: none;
                    }
                    .dropdown__text{
                        width: 100%;
                        height: 20px;
                        padding-left: 5px;
                        padding-right: 0;
                        position: relative;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                .dropdown__box{
                    border: 1px solid #ECECEC;
                    border-top: none;
                    z-index: 14;
                }
                .new_comment_block{
                  background: transparent;
                  border-radius: 4px;
                  padding: 7px;
                  display: inline-block;
                  vertical-align: top;
                  transition: background 0.4s ease;
                  display: none;
                  .comment_logo{
                    background-image: url(../images/comment_color.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 19px;
                    display: inline-block; 
                    width: 20px;
                    height: 20px;
                    margin-right: 5px; 
                    vertical-align: middle;
                  }
                  .comment_count{
                    font-family: "Lato-Medium";
                    font-size: 10px;
                    line-height: 20px;
                    background: #303030;
                    border-radius: 50%;
                    text-align: center;
                    letter-spacing: 1px;
                    color: #FFFFFF;
                    display: inline-block; 
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                  }
                  &:hover{
                    background: #F5F5F5;
                  }
                }
                &.disabled{  
                    background: #EAEAEA;
                    input{
                        pointer-events: none; 
                        color: #A1A1A1;
                    }
                    .dropdown{
                        pointer-events: none;
                    }
                    .dropdown__header{
                        .dropdown__text{
                            background: #EAEAEA;
                        }
                    }
                }
                &:first-of-type{
                    // flex: 0 0 auto!important;
                    // max-width: 49px!important;
                    text-align: center;
                    padding: 19px 0 19px 0;
                }
                &:nth-of-type(2){
                    // flex: 0 0 auto!important;
                    // max-width: 70px!important;
                    padding: 19px 15px 19px 15px;

                    a{
                        display: inline-block;
                        width: 14.5px;
                        height: 8px;
                        margin-right: 7px;
                        transition: background 0.4s ease; 

                        &.left{
                            background-image: url(../images/estimate_left.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background-image: url(../images/estimate_left_color.svg);
                            }
                        }

                        &.right{
                            background-image: url(../images/estimate_right.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background-image: url(../images/estimate_right_color.svg);
                            }
                        }

                        &:last-of-type{
                            margin-right: 0;
                        }
                    }

                }
                &:nth-of-type(3){
                    padding: 19px 0 19px 0;
                }
                &:nth-of-type(4){
                    padding: 12px 20px 12px 20px;
                }
                &:nth-of-type(5){
                    // flex: 0 0 auto!important;
                    // max-width: 178px!important;
                    padding: 19px 10px 19px 10px;
                }
                &:nth-of-type(6){
                    // flex: 0 0 auto!important;
                    // max-width: 116px!important;
                    padding: 19px 10px 19px 10px;
                    input{
                        text-align: left;
                    }
                }
                &:nth-last-child(2){
                    border-right: none;
                    // flex: 0 0 auto!important;
                    // max-width: 159px!important;
                    padding: 19px 10px 19px 20px;
                    input{
                        text-align: left;
                    }
                }
            }

            &.active{
                margin: 0 -40px;
                padding-left: 40px;
                padding-right: 40px;
                border-top: none;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
                background: white;

                .table_row_item{
                    border-right: 1px solid transparent;

                    textarea{
                        pointer-events: initial;
                    }

                    &:nth-of-type(2){
                        a{

                            &.left{
                                background-image: url(../images/estimate_left_color.svg);
                            }

                            &.right{
                                background-image: url(../images/estimate_right_color.svg);
                            }

                        }
                    }

                }

                .element_functions{
                    z-index: 2; 
                    opacity: 1;
                    &:before{
                      opacity: 1;
                      z-index: 2;
                    }
                    a{
                      z-index: 2;
                      position: relative;
                    }
                }
            }

        }

    }

    .not_move{

        .sticky_block{

            position: sticky;
            left: 0;
            top: 240.09px;
            width: 100%;
            background: #fff;
            z-index: 11;

            // &.header_not_line{
            //   top: 241.08px;
            // }

        }

        .button_row_wrapper{

          .max_row_count_wrapper{

            position: relative;
            width: 100%;

            .max_row_count{

              position: absolute;
              left: 0;
              top: 10px;
              padding: 20px 25px;
              border-radius: 5px!important; 
              width: 235px;
              background: #f4f4f4;
              display: none;

              span{
                line-height: 26px;
                font-size: 16px;
                margin-bottom: 18px;
                color: #474a4f;
              }

            }

          }

        }

        .column_title_row{

            margin-top: 32px;

            span{
                font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: #A1A1A1;
            }
            .column_title_row_item{
                border-right: 1px solid #ECECEC;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 6px;
                padding-bottom: 20px;
                &:first-of-type{
                    flex: 0 0 auto!important;
                    max-width: 49px!important;
                }
                &:nth-of-type(2){
                    max-width: 100px!important;
                }
                &:nth-of-type(5){
                    flex: 0 0 auto!important;
                    max-width: 116px!important;
                }
                &:nth-of-type(6){
                    flex: 0 0 auto!important;
                    max-width: 131px!important;
                }
                &:last-of-type{
                  border-right: none;
                }
            }
        }

        .table_row{

            .table_row_item{
                border-right: 1px solid #ECECEC;
                min-height: 55px;
                padding: 19px 10px 19px 10px;

                p{
                  font-size: 14px;
                  line-height: 20px;
                  color: #303030;
                  text-align: center;
                }

                &.unit_box{
                    .dropdown{
                        position: absolute; 
                        // height: calc(100% - 38px); //change select
                        // width: calc(100% - 20px);
                        // left: 10px;
                        // top: 19px;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: transparent;

                        .dropdown__header{
                            background: transparent;

                            .dropdown__text{
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%); 
                                left: 0;
                                padding-left: 15px;
                            }
                        }
                    }
                }
                
                &:first-of-type{
                    flex: 0 0 auto!important;  
                    max-width: 49px!important;
                    text-align: center;
                    padding: 19px 0 19px 0;
                }
                &:nth-of-type(2){
                    flex: 0 0 auto!important;
                    max-width: 100px!important;
                    padding: 19px 0 19px 0;
                }
                &:nth-of-type(3){
                    padding: 12px 20px 12px 20px;
                }
                &:nth-of-type(4){
                    padding: 12px 20px;
                    input{
                        text-align: left
                    }
                }
                &:nth-of-type(5){
                    flex: 0 0 auto!important;
                    max-width: 116px!important;
                    padding: 19px 10px 19px 20px;
                    input{
                        text-align: left;
                    }
                }
                &:nth-of-type(6){
                    flex: 0 0 auto!important;
                    max-width: 131px!important;
                    // padding: 19px 10px 19px 10px; //change select
                    padding: 0;
                    input{
                        text-align: left;
                    }
                }
                &:nth-last-child(2){
                  border-right: none;
                }
            }

        }
    }

    .estimate_sentences{

        .sticky_block{
            top: 168.09px;
        }

        .table_row{
            cursor: initial;

        .table_row_item{
            &.disabled{
                background: #FFFFFF;
                .dropdown__header {
                    .dropdown__text{
                        background: #FFFFFF;
                    }
                }
            }
        }

        &.table_section{
            .table_row_item{
                &.disabled{
                    background: #eaeaea;
                    .dropdown__header {
                        .dropdown__text{
                            background: #eaeaea;
                        }
                    }
                }
            }
        }

      }

      .column_title_row{
        margin-top: 0;
        span{
          line-height: 100%;
          display: inline-block;
          vertical-align: sub;
        }
        .column_title_row_item{
          padding: 19px 10px !important; 
          max-width: 100% !important; 
          flex: 1 0 0 !important;
          text-align: center;

          &:nth-of-type(2){
            flex: 0 0 90px !important;
            max-width: 90px !important;
          }
          &:nth-of-type(3){
            max-width: 100% !important;
            flex: 1 0 0 !important; 
            padding: 19px 20px !important;
            min-width: 234px !important;
          }
          &:nth-of-type(4){
            max-width: 100% !important;
            flex: 1 0 0 !important; 
            padding: 19px 20px !important;
            min-width: 116px !important;
          }
          &:nth-of-type(5){
            flex: 0 0 90px !important;
            max-width: 90px !important;
          }
          &:nth-of-type(6){
            flex: 0 0 90px !important;
            max-width: 90px !important;
          }
          &:nth-of-type(7){
            flex: 0 0 110px !important;
            max-width: 110px !important;
          }
          &:nth-of-type(8){
            flex: 0 0 124px !important;
            max-width: 124px !important;
          }
          &:nth-of-type(9){
            border-right: 1px solid #ececec;
            max-width: 100% !important;
            flex: 1 0 0 !important; 
          }
          &:last-of-type{
            max-width: 100% !important;
            flex: 1 0 0 !important; 
          }
        }
      }
      .table_row {
        .table_row_item{
          max-width: 100% !important; 
          flex: 1 0 0 !important;

          &:nth-of-type(2){
            flex: 0 0 90px !important;
            max-width: 90px !important;
          }
          &:nth-of-type(3){
            max-width: 100% !important;
            flex: 1 0 0 !important; 
            min-width: 234px !important;
          }
          &:nth-of-type(4){
            max-width: 100% !important;
            flex: 1 0 0 !important; 
            min-width: 116px !important;
          }
          &:nth-of-type(5){
            flex: 0 0 90px !important;
            max-width: 90px !important;
            padding: 19px 10px;
            input{
                text-align: center;
            }
          }
          &:nth-of-type(6){
            flex: 0 0 90px !important;
            max-width: 90px !important;
            padding: 19px 10px;
          }
          &:nth-of-type(7){
            flex: 0 0 110px !important;
            max-width: 110px !important;
          }
          &:nth-of-type(8){
            flex: 0 0 124px !important;
            max-width: 124px !important;
          }
          &:nth-of-type(9){
            max-width: 100% !important;
            border-right: 1px solid #ececec;
            flex: 1 0 0 !important; 
          }
          &:last-of-type{
            border-right: none;
            max-width: 100% !important;
            flex: 1 0 0 !important; 
            padding: 19px 10px;
          }
          &:nth-last-child(2){
            padding: 19px 10px;
          }
          &.unit_box{
            .dropdown{
                .dropdown__header{
                    .dropdown__text{
                        padding: 0 15px;
                        text-align: center;
                    }
                }
            }
          }
        }
      }
    }

    .without_button{
        .sticky_block{
            top: 168.09px;
        }
    }

}

.textarea_val{
  max-width: 420px;
  display: inline-block;
  font-family: "Lato-Medium";
  font-style: normal;
  font-weight: normal;
  background: transparent; 
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  border: none;
  position: absolute;
  left: -1000px;
  top: -5000px
}

#removeEstinate{

  .modal-dialog{

    max-width: 818px;
    .modal-header{

      border: none;
      padding-top: 56px;
      padding-left: 32px;
      padding-right: 129px;
      .modal-title{

        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        color: #303030;
        margin-bottom: 25px;

      }

      .subtitle{

        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: #303030;

      }

    }

    .modal-footer{

      padding-top: 32px;
      padding-bottom: 48px;
      justify-content: flex-start;
      border: none;
      .modal_button_fill{

        height: 50px;
        line-height: 50px;
        background: #FDA21A;
        border-radius: 4px!important;
        text-align: center;
        min-width: 166px;
        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none!important;
        cursor: pointer;
        margin-right: 24px;
        transition: background 0.4s ease;
        &:focus{
          border-radius: 4px!important;
          border: none!important;
        }
        &:active{
          border-radius: 4px!important;
          border: none!important;
        }
        &:hover{
          border-radius: 4px!important;
          border: none!important;
          background: #FF8A00;
        }

      }

      .modal_button_stroke{

        border: 1px solid #303030;
        border-radius: 4px!important;
        text-align: center;
        height: 50px;
        line-height: 50px;
        background: transparent;
        min-width: 139px;
        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #303030;
        cursor: pointer;
        margin: 0;
        transition: border 0.4s ease, background 0.4s ease, color 0.4s ease;
        &:focus{
          border-radius: 4px!important;
        }
        &:active{
          border-radius: 4px!important;
        }
        &:hover{
          border-radius: 4px!important;
          border: 1px solid transparent;
          background: #303030;
          color: #FFFFFF;
        }

      }

    }

  }
}

#modalComments{

  .modal-dialog{

    max-width: 818px;

    .modal-content{
        padding: 0;
    }

    .modal-header{

      padding-left: 32px;
      padding-top: 40px;
      padding-bottom: 40px;
      border: none;
      .modal-title{

        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #303030;

      }

      .close{

        width: 50px;
        height: 50px;
        font-size: 50px;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 25px;
        right: 30px;
        font-weight: 400;
        transition: opacity 0.4s ease;
        span{

          display: block;

        }

      } 

    }

    .modal-body{

      padding: 0 26px 38px 32px;
      height: 455px;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar{
        width: 6px;
      }

      &::-webkit-scrollbar-button{
        background-color: transparent;
        display: none;
      }

      &::-webkit-scrollbar-track{
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb{
        max-height: 236px; 
        background: #D8D8D8;
        border-radius: 30px;
      }

      .row{
        margin: 0;
      }

      .modal_block_comments{
        .template{
            display: none!important;
        }
        .comments_block_element{
          padding: 0;
          margin-bottom: 16px;
          .person_logo_wrapper{
            max-width: 71px;
            width: 100%;
            padding: 0;
            padding-right: 16px;
            .author_photo{
              display: inline-block;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              vertical-align: top; 
              border: 1px solid #E0E0E0;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
          .text_block_wrapper{
            background: #F5F5F5;
            border-radius: 0px 30px 30px 30px;
            padding: 20px;
            p{
              font-family: "Lato-Medium";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #303030;
              padding-right: 80px;
            }
            .name_author{
              display: block;
              font-family: "Lato-Medium";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #303030;
              margin-bottom: 8px;
            }
          }
          &.your_comment{
            .person_logo_wrapper{
              padding-right: 0;
              padding-left: 16px;
            }
            .text_block_wrapper{
              background: #1966FD;
              border-radius: 30px 0px 30px 30px;
              .name_author,
              p{
                color: #FFFFFF;
              }
            }
          }
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }

    }

    .modal-footer{

      border: none;
      justify-content: flex-start;
      padding: 0 32px 40px 32px;

      .button_block{
        padding: 0;
      }

      .modal_button_fill{

        height: 50px;
        line-height: 50px;
        background: #FDA21A;
        border-radius: 4px!important;
        text-align: center;
        min-width: 166px;
        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none!important;
        cursor: pointer;
        margin-right: 24px;
        transition: background 0.4s ease;
        &:focus{
          border-radius: 4px!important;
          border: none!important;
        }
        &:active{
          border-radius: 4px!important;
          border: none!important;
        }
        &:hover{
          border-radius: 4px!important;
          border: none!important;
          background: #FF8A00;
        }

      }

      .modal_button_stroke{

        border: 1px solid #303030;
        border-radius: 4px!important;
        text-align: center;
        height: 50px;
        line-height: 50px;
        background: transparent;
        min-width: 139px;
        font-family: "Lato-Medium";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #303030;
        cursor: pointer;
        margin: 0;
        transition: border 0.4s ease, background 0.4s ease, color 0.4s ease;
        &:focus{
          border-radius: 4px!important;
        }
        &:active{
          border-radius: 4px!important;
        }
        &:hover{
          border-radius: 4px!important;
          border: 1px solid transparent;
          background: #303030;
          color: #FFFFFF;
        }

      }

      .row{
        width: 100%;
        margin: 0;
      }

      .add_modal_comment{

        padding: 0;

        p{
          font-family: "Lato-Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #303030;
          margin-bottom: 22px;
        }

        textarea{

          resize: none;
          width: 100%;
          height: 172px;
          border: 1px solid #E0E0E0;
          border-radius: 4px!important;
          margin-bottom: 32px;
          padding: 20px;
          font-family: "Lato-Medium";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #303030;

        }

      }

    }

  }
}

.doc-item__title-image{
  padding-left: 80px !important;

  .full{
      display: inline-block;
      position: absolute;
      top: -11px;
      left: -8px;

      img{
        border-radius: 5px;
        max-width: 73px;
        max-height: 53px;
      }
  }
}

.packages_block_body{
    margin-left: -12px;
    margin-right: -12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    .small_section{
        padding: 0 12px;
        margin-bottom: 24px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
        display: inline-block; 

        .section-lk{
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            width: 100%;

            .section-lk__body{
                margin-bottom: 32px;
            }

            .section-lk__action{
                margin-top: auto;

                .btn{
                    min-width: 233px;
                }
            }

            .extend-form__form{
                .extend-form__input{
                    -webkit-box-flex: 0;
                        -ms-flex: 0 0 100%;
                            flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .long_section{
        padding: 0 12px;
        margin-bottom: 24px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
    }
}

.packages_change_block{

    .info-list{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        padding-bottom: 30px;
        border-bottom: 2px solid #ECECEC;

        .info-list__item{
            display: block;
            -webkit-box-flex: 0;
                -ms-flex: 0 0 33.333333%;
                    flex: 0 0 33.333333%;
            max-width: 33.333333%;

            .info-list__label{
                display: inline-block;
                -webkit-box-flex: 0;
                    -ms-flex: none;
                        flex: none;
                max-width: none;
            }
            .info-list__value{
                display: inline-block;
                -webkit-box-flex: 0;
                    -ms-flex: none;
                        flex: none;
                max-width: none;
            }
            .hint-block,
            .hint-parent{

                margin-left: 3px;

                .hint-text{
                    min-width: 330px;
                    max-width: 330px;
                    -webkit-transform: translateX(-40%);
                        -ms-transform: translateX(-40%);
                            transform: translateX(-40%); 
                    padding: 12px 19px 10px 12px;
                    -webkit-box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
                            box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
                }
            } 
        }
    }

    .section-lk__body{
        margin-bottom: 40px;

        &:last-of-type{
            margin-bottom: 0;
        }

        &.packages_range_wrapper{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        }

        .packages_range_block{
            -webkit-box-flex: 0;
                -ms-flex: 0 0 66.666666%;
                    flex: 0 0 66.666666%;
            max-width: 66.666666%;
            padding-right: 52px;

            form{
                width: 100%;

                .form-row{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                    margin-bottom: 50px;

                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }

                .title_range_element{
                    -webkit-box-flex: 0;
                        -ms-flex: 0 0 34.4%;
                            flex: 0 0 34.4%;
                    padding-right: 20px;

                    .control-label{
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 19px;
                        color: #303030
                    }

                }

                .range_element{
                    -webkit-box-flex: 0;
                        -ms-flex: 0 0 65.6%;
                            flex: 0 0 65.6%;

                    .range-box{
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                            flex-wrap: wrap;
                        width: 100%;
                        -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                                justify-content: space-between; 
                    }

                    .information_text{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                        color: #000000;
                        display: inline-block;
                        max-width: 299px;
                        small{
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 140%;
                            color: #000000;
                        }
                    }

                    .btn{
                        padding: 11px 39px;
                        vertical-align: top;
                        max-height: 42px;
                    }

                    .slider.slider-horizontal{
                        margin-top: 3px;

                        .slider-tick-label-container{
                            .slider-tick-label{

                                font-weight: normal;
                                font-size: 16px;
                                line-height: 19px;
                                color: #303030;
                                -webkit-transition: color 0.2s ease;
                                -o-transition: color 0.2s ease;
                                transition: color 0.2s ease; 
                                top: 32px;

                                &:last-child{
                                    left: auto!important;
                                    text-align: right;
                                    white-space: pre-wrap;
                                    right: -4px !important;
                                    width: auto!important; 
                                    max-width: 180px!important; 
                                }

                                &.label-is-selection{
                                    color: #FDA219;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }

        .packages_information_block{
            -webkit-box-flex: 0;
                -ms-flex: 0 0 33.333333%;
                    flex: 0 0 33.333333%;
            max-width: 33.333333%;
            text-align: right;

            .packages_information_inner{

                max-width: 246px;
                width: 100%;
                display: inline-block;

                .packages_information_inner_element{

                    margin-bottom: 10px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                    width: 100%;

                    &:last-of-type{
                        margin-bottom: 0;
                    }

                    .price_element{
                        display: inline-block;
                        -ms-flex-preferred-size: 0;
                            flex-basis: 0;
                        -webkit-box-flex: 1;
                            -ms-flex-positive: 1;
                                flex-grow: 1;
                        max-width: 94px;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 22px;
                        color: #303030;

                        &.total_sum{
                            font-weight: 700;
                        }
                    }

                    .price_name{
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 19px;
                        color: #303030;
                        -ms-flex-preferred-size: 0;
                            flex-basis: 0;
                        -webkit-box-flex: 1;
                            -ms-flex-positive: 1;
                                flex-grow: 1;
                        max-width: 100%;
                        text-align: left;

                        &.total_sum_title{
                            font-weight: 700;
                        }
                    }
                }
            }

            .section-lk__action{
                margin-top: 25px;

                .btn{
                    min-width: 245px;
                }
            }
        }
    }
    
}

.range_input{
    background: #E1E3E6;
    height: 6px;
    border-radius: 5px!important;
    width: 428px;
    display: inline-block;
    vertical-align: text-top;
    opacity: 0.7;
    cursor: pointer;

    &:hover{
        opacity: 1; 
    }

    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        appearance: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: url(../images/inputvalue.svg) no-repeat, #fff;
        background-position: center;
        cursor: pointer;
        border: 1px solid #FFCC33;
        opacity: 1;
    }
}

.slider.slider-horizontal{
    width: 100%;
    vertical-align: baseline;

    .slider-track{
        background: #E1E3E6;
        height: 6px;
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    .slider-selection{
        background: #E1E3E6;
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    .slider-handle{
        top: -5px;
        width: 24px;
        height: 24px;
        background: #FFFFFF;
        border: 8px solid #FDA219;
        margin-left: -12px;
    } 

    .tooltip{
        display: none;
    }

    .slider-tick-container{
        .slider-tick{
            display: none;
        }
        .in-selection{
            background: #FFCC33;
        }
    }

}

.feedback_main_block{
    padding-top: 5px;
    padding-bottom: 45px;

    .container{
        max-width: 1200px;
        padding: 0;
    }

    .row{
        margin: 0;
        >div{
            padding: 0;
        }
    }

    .title_block{
        margin-bottom: 32px;
    }

    .contacts_block{

        margin-bottom: 80px;

        .contacts_block_inner{
            background: #F9F9F9;
            padding: 48px 48px 58px 56px;
            border-radius: 4px;

            .contacts_information{

                .column_label{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #A1A1A1;
                    margin-bottom: 16px;
                }

                .mail_block_contacts{
                    padding-left: 40px;
                }

                .contacts_link{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 22px;
                    line-height: 26px;
                    color: #000000;
                    position: relative;
                    padding-left: 35px;
                    display: inline-block;
                    -webkit-transition: color 0.4s ease;
                    -o-transition: color 0.4s ease;
                    transition: color 0.4s ease; 

                    &:hover{
                        color: #FDA21A;
                    }

                    .icon{
                        position: absolute;
                        left: 0;
                        top: 1px;
                        display: inline-block;
                        width: 24px;
                        height: 24px;

                        &.icon_phone{
                            background-image: url(../images/phone_icon.png);
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        &.icon_mail{
                            background-image: url(../images/mail_icon.png);
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                }

                .social_links_block{
                    text-align: right;

                    .social_links_inner{
                        padding-right: 22px;

                        .social__link{
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            vertical-align: top;
                            margin-right: 17px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            -webkit-transition: background 0.4s ease;
                            -o-transition: background 0.4s ease;
                            transition: background 0.4s ease; 

                            &:last-of-type{
                                margin-right: 0;
                            }

                            &.facebook_link{
                                background-image: url(../images/feedback-facebook__bl.svg);

                                &:hover{
                                    background-image: url(../images/feedback-facebook__cl.svg);
                                }
                            }

                            &.inst_link{
                                background-image: url(../images/feedback-inst__bl.svg);

                                &:hover{
                                    background-image: url(../images/feedback-inst__cl.svg);
                                }
                            }

                            &.vk_link{
                                background-image: url(../images/feedback-vk__bl.svg);

                                &:hover{
                                    background-image: url(../images/feedback-vk__cl.svg);
                                }
                            }

                            &.linkin_link{
                                background-image: url(../images/feedback-linkin__bl.svg);

                                &:hover{
                                    background-image: url(../images/feedback-linkin__cl.svg);
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    .text_block_feedback{

        margin-bottom: 85px;

        .description_column{
            .description_column_inner{
                margin-bottom: 64px;

                &:last-of-type{
                    margin-bottom: 0;
                }

                h3{
                    margin-bottom: 24px;
                }

                p{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 150%;
                    color: #303030;

                    a{
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 150%;
                        color: #FAA21E;
                    }
                }
            }
        }

        .documents_column{
            padding-top: 50px;
            text-align: right;

            .documents_column_element{
                display: inline-block;
                text-align: left;
                max-width: 282px;
                -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
                        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
                border-radius: 4px;
                background: #FFFFFF;
                padding: 32px;
                padding-right: 40px;

                .doc_link{
                    width: 38px;
                    height: 38px;
                    display: inline-block;
                    background-image: url(../images/doc_link.svg);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-bottom: 24px;
                }

                .doc_name{
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    margin-bottom: 15px;
                }

                .doc_info{
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #a1a1a1;
                }

            }
        }
    }

    .feedback_form_block_inner{
        background: #F9F9F9;
        border-radius: 4px;
        padding: 48px;
        padding-top: 56px;

        h2{
            margin-bottom: 56px;
        }

        .feedback_form_row{
            margin: 0 -12px;

            >div{
                padding: 0 12px;
            }

            .form-group{
                margin-bottom: 32px;
            }

            .check-box{
                display: inline-block;
            }

            .consent_block{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                width: 100%;
                -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                        justify-content: space-between; 
                -webkit-box-align: center; 
                    -ms-flex-align: center; 
                        align-items: center;
                padding-top: 48px;
            }
        }
    }
}

.relibility_body{

    .section-lk{
        .info-list__label{
            -webkit-box-flex: 0;
                -ms-flex: 0 0 310px;
                    flex: 0 0 310px;
            max-width: 310px;
        }
    }

    .relibility_row{
        margin: 0;
    }

    .relibility_column{
        padding: 0;
    }

    .relibility_information_subtitle{
        margin-bottom: 40px;
        margin-top: 32px;
        span{
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            color: #303030;
        }
    }

    .relibility_information{

        .hint-text{
            width: 265px;
            min-width: 265px;
            -webkit-transform: translateX(-56%);
                -ms-transform: translateX(-56%);
                    transform: translateX(-56%); 

            p{
                font-size: 14px;
            }

            .hint_title{
                font-weight: bold;
            }

            .hint_list{
                li{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .relibility_row{
            margin: 0 -15px;
            margin-top: 32px;
            .info-list__label{
                -webkit-box-flex: 0;
                    -ms-flex: 0 0 280px;
                        flex: 0 0 280px;
                max-width: 280px;
                margin-right: 0;
            }
            .info-list__value{
                max-width: 100%;
                padding-left: 40px;
                padding-right: 10px;
            }
            .relibility_column{
                padding: 0 15px; 
                &:nth-child(even){
                    .info-list__value{
                        padding-left: 32px;
                        padding-right: 0;
                    }
                }
            }
            &:first-of-type{
                margin: 0;
                padding-bottom: 24px;
                border-bottom: 1px solid #ECECEC;
                .relibility_column{
                    padding: 0;
                    &:nth-of-type(2){
                        padding: 0 15px;
                    }
                }
            }

            .image_block{
                text-align: center;

                .circle{
                    display: inline-block;
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    border: 2px solid #FDA21A;
                    span{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 150px;
                        text-align: center;
                        color: #303030
                    }

                    &.green{
                        border: 2px solid #10AF59;
                    }

                    &.red{
                        border: 2px solid #FD1A35;
                    }
                }
            }
            .text_block{
                padding-top: 32px;
                text-align: center;
                padding-bottom: 24px;
                .relibility_column_link {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: center;
                    color: #000000;
                    display: inline-block;
                    margin-bottom: 12px;
                    position: relative;
                    &:after{
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: -4px;
                        background: #E2E2E2;
                    }
                }
                .open_block{
                    a{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        text-align: center;
                        color: #1967FD
                    }
                }
            }
        }

        .relibility_detail_information{
            padding-top: 36px;
            padding-bottom: 48px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            border-bottom: 1px solid #ECECEC;
            display: none;

            &:after,
            &:before {
               content: '';
               border: solid transparent;
               position: absolute;
               left: 50%;
               margin-left: -12px;
               top: -24px;
               width: 0;
            }

            &:before {
               border-bottom-color: #ECECEC;
               border-width: 12px;
               z-index: 1; 
            }

            &:after {
               border-bottom-color: #ffffff;
               border-width: 14px;
               margin-left: -14px;
               z-index: 2; 
            }

            &[data-click="creditworthiness"]{
                &:after,
                &:before{
                    left: 82.5%;
                }
            }

            &[data-click="steadiness"]{
                &:after,
                &:before{
                    left: 17.5%;
                }
            }

            .close{
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                right: 16px;
                top: 24px;
                &:before,
                &:after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    border-radius: 2px;
                    background: #C0C0C0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    -webkit-transition: background 0.4s ease;
                    -o-transition: background 0.4s ease;
                    transition: background 0.4s ease; 
                }
                &:before{
                    -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                }
                &:after{
                    -webkit-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                }
                &:hover{
                    &:after,
                    &:before{
                        background: #000000;
                    }
                }
            }

            span{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                color: #303030;
                margin-bottom: 40px;
                display: block;
            }
            [class^="col-"]{
                padding-top: 0;
                padding-bottom: 0;
            }
            p{
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                color: #303030;
                margin-bottom: 24px;
            }
            b{
                font-weight: bold;
                font-size: 18px;
                line-height: 38px;
                color: #303030;
                margin-bottom: 19px;
                display: block;
            }
            ul{
                padding-top: 10px;
                li{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 100%;
                    color: #303030;
                    margin-bottom: 16px;
                    padding-left: 18px;
                    position: relative;
                    &:before{
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: #FDA219;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 6px;
                    }
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .info-list__value{
        a{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #1967FD;
            display: inline-block;
            margin-left: 8px;
        }
    }

    .relibility_section{

        .info-list__label{
            -webkit-box-flex: 0;
                -ms-flex: 0 0 400px;
                    flex: 0 0 400px;
            max-width: 400px;
            margin-right: 32px;
        }

        .section-lk__body{
            margin-bottom: 23px;
        }

        .text-container{
            display: inline-block;
            min-width: 72px;
            padding-right: 10px;
        }

        .info-list__value {
            a {
                margin-left: 0 !important;
            }
        }

        .hint-text{
            width: 265px;
            min-width: 265px;
            -webkit-transform: translateX(-56%);
                -ms-transform: translateX(-56%);
                    transform: translateX(-56%); 

            p{
                font-size: 14px;
            }

            .hint_title{
                font-weight: bold;
            }

            .hint_list{
                li{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .relibility_subtitle{

            margin-bottom: 16px;

            p {  
                font-weight: bold;
                font-size: 18px;
                line-height: 38px;
                color: #303030
            }
        }
    }

}

.stats_count{
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin-left: 5px;

    img{
        display: inline-block;
        vertical-align: top;
    }

    span{
        display: inline-block;
        vertical-align: top;
        line-height: 1;
    }

    .arrow{
        width: 12px;
        height: 12px;
    }

    &.count_up{
        .arrow{
            background-image: url(../images/green_arrow.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .count_number{
            color: #10AF59;
        }
    }

    &.count_down{
        .arrow{
            background-image: url(../images/red_arrow.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .count_number{
            color: #FD1A1A;
        }
    }
}

.charts_block{
    display: none;
    padding-right: 2px;
    &.show{
        display: block;
    }
}

.charts_row{
    position: relative;
}

.charts_tabs{
    position: absolute;
    right: 20px;
    top: -74px;
    max-width: 600px;
    ul{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        li{
            display: inline-block;
            &:first-of-type{
                a{
                    border-radius: 4px 0px 0px 4px;;
                }
            }
            &:last-of-type{
                a{
                    border-radius: 0 4px 4px 0;;
                }
            }
            a{
                font-style: normal;
                font-weight: 800;
                font-size: 12px;
                line-height: 40px;
                text-align: center;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #303030;
                display: block;
                width: 143px;
                text-align: center;
                background: #FFFFFF;
                border: 1px solid #EBEBEB;
                -webkit-transition: color 0.4s ease, background 0.4s ease, border 0.4s ease;
                -o-transition: color 0.4s ease, background 0.4s ease, border 0.4s ease;
                transition: color 0.4s ease, background 0.4s ease, border 0.4s ease;
                &:hover{
                    color: #ffffff;
                    background: #303030;
                    border: 1px solid #303030;
                }
                &.active{
                    background: #E7E7E7;
                    border: 1px solid #DBDBDB;
                    &:hover{
                        color: #303030;
                        background: #E7E7E7;
                        border: 1px solid #DBDBDB;
                    }
                }
            }
        }
    }
}

.charts{
    .charts_row{
        padding: 40px 0;
    }
}

.custom_tooltip_block{

    min-width: 130px!important;
    padding: 16px 16px 16px 16px!important;
    table{ 
        width: 100%;
        tbody {
            tr {
                td { 
                    font-weight: 600!important;
                    font-size: 14px!important;
                    line-height: 1!important;
                    color: #303030!important;
                    text-align: center!important;
                }
            }
        }
    }
}

.table_chart_row{
    margin: 0;
    .table_number_element{
        padding: 0;
        .number_element_row{
            margin: 0;
            border-bottom: 1px solid #ECECEC;
            &:last-of-type{
                border-bottom: none;
            }
            &.title_row{
                .element_row_block{
                    span{
                        color: #A1A1A1;
                    }
                }
            }
            .element_row_block{
                padding: 0;
                -webkit-box-flex: 0;
                    -ms-flex: 0 0 16.666666%;
                        flex: 0 0 16.666666%;
                max-width: 16.666666%;
                padding: 20px 21px;
                border-left: 1px solid #ECECEC;
                &:first-of-type{
                    border-left: none;
                }
                span{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    color: #303030
                }
            }
        }
    }
}

.reliability_modal{
    .popup__close{
        top: 25px;
        right: 25px;
    }
    .popup__title{
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        color: #303030;
        margin-bottom: 40px;
    }
    .section-lk{
        padding: 0;
        padding-bottom: 32px;
        border-bottom: 1px solid #ECECEC;
        &:last-of-type{
            border-bottom: none;
            padding-bottom: 0;
        }
        a{ 
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #1A67FD;
        }
        .info-list__item{
            &:last-of-type{
                margin-top: 18px;
            }
        }
    }
    .tabs-block__content{
        padding-top: 40px;
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #000000;
        }
        b{
            display: block;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: #000000;
            margin-top: 40px;
            margin-bottom: 18px;
        }
        ul{
            padding-top: 10px;
            li{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                color: #303030;
                margin-bottom: 16px;
                padding-left: 18px;
                position: relative;
                &:before{
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background: #FDA219;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 6px;
                }
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}

#business_affairs{
    .popup__box{
        .popup__box-inner {
            padding-bottom: 0 !important;
        }
    }
}

.modal_activities_table{
    .row{
        margin: 0;
        >div{
            padding: 0;
        }
    }
    .number_element{
        -webkit-box-flex: 0!important;
            -ms-flex: 0 0 60px!important;
                flex: 0 0 60px!important;
        max-width: 60px!important;
    }
    .code_element{
        -webkit-box-flex: 0!important;
            -ms-flex: 0 0 135px!important;
                flex: 0 0 135px!important;
        max-width: 135px!important;
    }
    .number_element{
        -webkit-box-flex: 0!important;
            -ms-flex: 0 0 60px!important;
                flex: 0 0 60px!important;
        max-width: 60px!important;
    }
    span{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 38px;
        color: #303030;
    }
    .title_row{
        span{
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 38px;
            color: #303030;
        }
    }
}

.modal-center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    bottom: 0;
    right: 0;
    .popup__box{
        margin: auto;
    }
}

.stock_line{
    background: #FBFBFB;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.stock_text{
    p{
        font-family: "Roboto-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #1A1A1A;
        margin-bottom: 0;
        a{
            color: #FAA21E;
            font-family: "Roboto-Regular";
        }
    }
    .close_link{
        margin-left: 85px;
    }
}

.close_link{
    display: inline-block;
    position: relative;
    padding-right: 26px;
    font-size: 16px;
    line-height: 24px;
    color: #1A1A1A;
    &:after,
    &:before{
        content: '';
        display: inline-block;
        width: 16px;
        height: 2px;
        background: #2C2A6C;
        position: absolute;
        right: 1px;
        top: 12px;
        border-radius: 5px;
    }
    &:after{
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg); 
    }
    &:before{
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg); 
    }
}

.header_main_blue{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99; 
    padding-bottom: 25px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    -webkit-transition: -webkit-box-shadow 1s ease;
    transition: -webkit-box-shadow 1s ease;
    -o-transition: box-shadow 1s ease;
    transition: box-shadow 1s ease;
    transition: box-shadow 1s ease, -webkit-box-shadow 1s ease;
    .header_row{
        padding-top: 23px;
        -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; 
    }
    .logo{
        a{
            display: inline-block;
        }
        img{
            max-width: 135px;
        }
    }
    .btn_blue{
        margin-left: 38px;
    }
    .btn_stroke_blue{
        margin-left: 38px;
    }
    .blue_link{
        margin-left: 37px;
        &:first-of-type{
            margin-left: 0;
        }
    }
    // + div{
    //     padding-top: 212px;
    // }

}

.header_blue__profile{
    display: inline-block;
    .header-profile__name{
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #2C2A6C;
        position: relative;
        padding-right: 24px;
        &:after{
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            position: absolute;
            right: 0;
            top: calc(50% - 4px);
            background: url(../images/select_blue.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: transform 0.3s ease;
        }
    }
    .header-profile__logo{
        width: 56px;
        height: 56px;
    }
    .header-profile__inner{
        .header-profile__body{
            width: 254px;
            padding: 28px 28px 24px 24px;
            border-radius: 0;
            .header-profile__link{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #2C2A6C;
                transition: color 0.3s ease;
                &:hover{
                    color: #FAA21E;
                }
            }
            .header-profile__list{
                margin-bottom: 24px;
            }
            .header-profile__action{
                padding-top: 20px;
            }
        }
        &:hover{
            .header-profile__name{
                color: #4b4998;
                &:after{
                    transform: rotate(180deg);  
                }
            }
        }
    }
}

.orange-br{
    margin-top: 6px;
    margin-bottom: 26px;
    .container{
        padding: 0;
    }
    .breadcrumbs__list{
        .breadcrumbs__item{
            &:last-of-type{
                .breadcrumbs__label{
                    color: #FAA21E;
                }
            }
            &:nth-child(-n+2){
                &:before{
                    color: #FAA21E;
                }
            }
            .breadcrumbs__link,
            .breadcrumbs__label{
                font-family: 'Roboto-Regular';
                color: #1A1A1A;
            }
        }
    }
}

.content_inner_block{
    background: #ffffff;
}

.btn_blue{
    display: inline-block;
    background: #2C2A6C;
    border-radius: 4px;
    height: 60px;
    line-height: 60px;
    padding: 0 48px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase; 
    color: #FFFFFF; 
    -webkit-transition: background 0.3s ease; 
    -o-transition: background 0.3s ease; 
    transition: background 0.3s ease;
    &:hover{
       background: #4B4998;
    }
}

.btn_blue_small{
    display: inline-block;
    background: #2C2A6C;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    padding: 0 42px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase; 
    color: #FFFFFF; 
    -webkit-transition: background 0.3s ease; 
    -o-transition: background 0.3s ease; 
    transition: background 0.3s ease;
    &:hover{
       background: #4B4998;
    }
}

.btn_stroke_blue{
    display: inline-block;
    background: transparent;
    border-radius: 4px;
    border: 2px solid #2C2A6C;
    height: 60px;
    line-height: 60px;
    padding: 0 33px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #2C2A6C;
    -webkit-transition: background 0.3s ease, color 0.3s ease;
    -o-transition: background 0.3s ease, color 0.3s ease;
    transition: background 0.3s ease, color 0.3s ease;
    &:hover{
       color: #ffffff; 
       background: #2C2A6C;
    }
    &.width_icon{
        padding: 0 58px;
        svg{
            margin-bottom: -2px;
            margin-right: 14px;
        }
    }
}

.width_title{
    margin-bottom: 0!important;
    max-width: 420px;
    width: 100%;
    .dropdown{
        height: 60px;
        width: 100%;
        .dropdown__header{
            padding: 6.5px 0;
            height: 60px;
        }
        .dropdown__title{
            width: 100%;
            display: inline-block;
            font-family: 'Roboto-Regular';
            font-size: 12px;
            line-height: 20px;
            color: #7C7C7C;
            padding-left: 20px;
            padding-right: 37px;
        }
        .dropdown__text{
            font-family: 'Roboto-Regular';
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
        .dropdown__header--placeholder{
            .dropdown__text{
                color: #a1a1a1;
            }
        }
        .dropdown__arrow{
            background-image: url(../images/select_blue.svg);
            background-position: center;
            background-size: 13px 8px;
            background-repeat: no-repeat;
        }
        &.opened{
            .dropdown__arrow{
                background-image: url(../images/svg/sprite.svg);
                background-size: 915px 646px;
                background-position: -48px -35px;
            }
        }
    }
}

.filter-main_block{
    .filter__inner{
        // padding: 10px 13px 10px 15px;
        padding: 16px 26px;
        flex-wrap: wrap; 
        justify-content: space-between; 
        .filter__body{
            margin-left: 0;
            padding-left: 0;
        }
        .filter__head{
            padding-right: 20px;
        }
    }
    .width_title{
        width: 420px;
    }
}

.subscribtion_block{
    background: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 65px 58px 18px 70px;
    form{
        padding-left: 52px;
    }
    .title_section_block { 
        margin-bottom: 25px;
        .title_section_inner{
            font-size: 26px;
        }
    }
    .form-control{
        height: 60px;
        line-height: 60px;
        &::-webkit-input-placeholder{
            color: #000000;
            font-family: 'Roboto-Regular';
        }
        &::-moz-placeholder{
            color: #000000;
            font-family: 'Roboto-Regular';
        }
        &:-ms-input-placeholder{
            color: #000000;
            font-family: 'Roboto-Regular';
        }
        &::-ms-input-placeholder{
            color: #000000;
            font-family: 'Roboto-Regular';
        }
        &::placeholder{
            color: #000000;
            font-family: 'Roboto-Regular';
        }
    }
    .preview_text{
        padding: 0;
        padding-top: 45px;
        p{
            font-weight: bold;
            font-size: 24px;
            line-height: 38px;
            color: #2C2A6C;
            margin-bottom: 24px;
        }
        a{
            font-weight: bold;
            font-size: 24px;
            line-height: 38px;
            display: inline-block;
            color: #FAA21E;
            background-repeat: no-repeat;
            background-position: left bottom 3px;
            background-size: 100% 1px;
            background-image: -webkit-gradient(linear, left top, right top, from(#FAA21E), to(#FAA21E));
            background-image: -o-linear-gradient(left, #FAA21E, #FAA21E);
            background-image: linear-gradient(to right, #FAA21E, #FAA21E);
            -webkit-transition: color 0.3s ease;
            -o-transition: color 0.3s ease;
            transition: color 0.3s ease;
            &:hover{
                color: #FF8A00;
            }
        }
    }
}

.subscribtion_form_element{
    margin-bottom: 24px;
    .btn{
        display: block;
        width: 100%;
        height: 60px;
        padding: 0 43px;
        line-height: 60px;
    }
}

.blue_link{
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #2C2A6C;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease; 
    &:hover{
        color: #4B4998
    }
}

.popup__question{
    .popup__box{
        max-width: 820px;
    }
    .popup__title{
        margin-top: 16px;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        color: #1A1A1A;
        max-width: 620px;
        margin-bottom: 32px;
    }
    .question_element{
        margin-bottom: 12px;
    }
    .popup__body{
        margin-bottom: 48px;
    }
}

.popup__survey{
    .popup__box{
        max-width: 820px;
        min-height: 436px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        width: 100%;
    }
    .popup__box-inner{
        width: 100%;
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
    }
    .popup__footer{
        .popup__action{
            text-align: center;
        }
    }
    .popup__title{
        text-align: center;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        color: #1A1A1A;
        margin-bottom: 20px;
    }
    p{
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: #303030;
    }
}

.header_function{
    .btn_blue{
        vertical-align: top;
    }
    .inner_block{
      display: inline-block;
      vertical-align: middle;
      max-width: 200px;
    }
}

.header_nav_list{
    padding-top: 21px;
    ul{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; 
        li{
            display: inline-block;
            a{
                font-weight: bold;
                font-size: 18px;
                line-height: 36px;
                text-transform: uppercase;
                color: #2C2A6C;
                -webkit-transition: color 0.3s ease;
                -o-transition: color 0.3s ease;
                transition: color 0.3s ease;
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -32px;
                    width: 0;
                    height: 10px;
                    background: #FFE0B0;
                    -webkit-transition: left 0.4s ease, width 0.4s ease;
                    -o-transition: left 0.4s ease, width 0.4s ease;
                    transition: left 0.4s ease, width 0.4s ease; 
                }
                &:hover{
                    color: #4B4998;
                }
                &.active{
                    color: #FAA21E;
                    &:after{
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}

.about_screen{
    min-height: 625px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 30px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/about_screen.png);
    >.container{
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
    }
    h1{
        font-weight: 900;
        font-size: 42px;
        line-height: 56px;
        color: #FFFFFF;
    }
    .underline_block{
        background-position: left bottom 0;
        background-image: -webkit-gradient(linear, left top, right top, from(#FAA21E), to(#FAA21E));
        background-image: -o-linear-gradient(left, #FAA21E, #FAA21E);
        background-image: linear-gradient(to right, #FAA21E, #FAA21E); 
    }
}

.underline_block{
    padding: 0 4px;
    display: inline;
    position: relative; 
    z-index: 2; 
    background-repeat: no-repeat;
    background-position: left bottom 0;
    background-size: 100% 10px;
    background-image: -webkit-gradient(linear, left top, right top, from(#FFE0B0), to(#FFE0B0));
    background-image: -o-linear-gradient(left, #FFE0B0, #FFE0B0);
    background-image: linear-gradient(to right, #FFE0B0, #FFE0B0); 
}

.title_section_block{
    text-align: center;
    margin-bottom: 23px;
    .title_section_inner{
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #1A1A1A;
    }
    &.text-left{
        text-align: left;
    }
    &.long_title{
        .underline_block{
            background-repeat: no-repeat;
            background-position: center top 30px;
            background-size: 75% 10px;
            background-image: -webkit-gradient(linear, left top, right top, from(#FFE0B0), to(#FFE0B0));
            background-image: -o-linear-gradient(left, #FFE0B0, #FFE0B0);
            background-image: linear-gradient(to right, #FFE0B0, #FFE0B0);
        }
    }
}

.about_company{
    padding-top: 44px;
    padding-bottom: 60px;
    .title_section_block{
        margin-bottom: 66px;
    }
}

.about_company_element{
    width: 180px;
    .image_block{
        height: 105px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        width: 100%;
        padding-bottom: 7px;
        img{
            margin: auto;
            max-width: 98px;
            max-height: 98px;
        }
    }
    .text_block{
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #000000;
        }
    }
}

.our_advantages{
    padding-bottom: 59px;
    .title_section_block{
        margin-bottom: 58px;
    }
}

.advantages_column{
    width: 49%;
}

.advantages_inner{
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 39px 9px 24px 21px;
    height: 100%;
    .block-title{
        padding-left: 58px;
        position: relative;
        margin-bottom: 27px;
        .name{
            font-weight: bold;
            font-size: 28px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 6px;
        }
        .sub_name{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
        .img_title{
            position: absolute; 
            left: 0;
            top: 3px;
            img{
                width: 41px;
                height: 41px;
            }
        }
    }
}

.order_list{
    counter-reset: lists;
    li{
        padding-left: 60px;
        position: relative;
        margin-bottom: 23px;
        &:before {
            content: counters(lists, "");
            counter-increment: lists;
            display: block;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: #FFE0B0;
            text-align: center;
            line-height: 42px;
            position: absolute; 
            left: 0;
            top: 6px;
            font-weight: 900;
            font-size: 28px;
            line-height: 42px;
            color: #2C2A6C;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
        p{
            font-family: "Roboto-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    }
}

.how_work{
    .title_section_block{
        margin-bottom: 65px;
    }
}

.section_element_count{
    margin-bottom: 38px;
    .title-block_element{
        margin-bottom: 22px;
        p{
            font-weight: bold;
            font-size: 20px;
            line-height: 36px;
            color: #2C2A6C;
            display: inline-block;
            vertical-align: middle;
        }
        .section_element_number{
            margin-right: 16px;
            vertical-align: middle;
        }
    }
    .doc_link{
        margin-bottom: 22px;
    }
    &:nth-child(odd){
        padding-left: 24px!important;
        padding-top: 4px!important;
    }
}

.section_element_number{
    content: '';
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #FFE0B0;
    text-align: center;
    line-height: 42px;
    font-weight: 900;
    font-size: 28px;
    line-height: 42px;
    color: #2C2A6C;
}

.ordinary_list{
    li{
        font-family: "Roboto-Regular";
        padding-left: 27px;
        position: relative; 
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 22px;
        &:before{
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            background: #FAA21E;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 9.5px;
        }
    }
}

.doc_link{
    font-family: "Roboto-Regular";
    display: inline-block;
    padding-left: 46px;
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    color: #4B4998;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease; 
    &:before{
        content: '';
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: -1px;
        background: url(../images/pdf.png) center/cover no-repeat;
    }
    &:hover{
        text-decoration: underline;
        color: #2C2A6C;
    }
}

.place_tender{
    padding-top: 39px;
    padding-bottom: 63px;
    .title_section_block{
        margin-bottom: 108px;
    }
    .home-section__tabs{
        right: 50%;
        top: 82px;
        -webkit-transform: translateX(50%);
            -ms-transform: translateX(50%);
                transform: translateX(50%);
    }
    .home-section__tab{
        &.active{
            background: #2C2A6C;
        }
    }
}

.questions_block{
    background: url(../images/questions.png) center/cover no-repeat;
    padding: 25px 0;
    min-height: 642px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    >.container{
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
    }
    p{
        font-family: "Roboto-Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 26px;
        a{
            font-weight: bold;
            -webkit-transition: color 0.3s ease;
            -o-transition: color 0.3s ease;
            transition: color 0.3s ease;
            &:hover{
                color: #FF9900;
            }
        }
    }
    .section_name{
        font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #FFFFFF;
    }
    form{
        padding-left: 11px;
    }
}

.question-social{
    p{
        display: inline-block;
        margin: 0;
        margin-right: 16px;
        line-height: 1;
        vertical-align: bottom;
    }
    .social-icon-block{
        display: inline-block;
        a{
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            line-height: 1;
            background-repeat: no-repeat!important;
            background-position: center!important;
            -webkit-transition: background 0.3s ease!important;
            -o-transition: background 0.3s ease!important;
            transition: background 0.3s ease!important;
            &.facebook{
                background: url(../images/icon-footer-facebook.svg);
                &:hover {
                    background: url(../images/icon-footer-facebook-color.svg);
                }
            }
            &.inst{
                background: url(../images/icon-footer-inst.svg);
                &:hover{
                    background: url(../images/icon-footer-inst-color.svg);
                }
            }
            &.vk{
                background: url(../images/icon-foter-vk.svg);
                background-position: 72% 72%!important;
                &:hover{
                    background: url(../images/icon-foter-vk-color.svg);
                    background-position: 72% 72% !important;
                }
            }
            &.linkin{
                background: url(../images/icon-footer-linkin.svg);
                &:hover{
                    background: url(../images/icon-footer-linkin-color.svg);
                }
            }
        }
    }
}

.question-form_element{
    margin-bottom: 21px;
    .form-control{
        height: 60px;
        font-family: "Roboto-Regular";
        font-size: 16px;
        color: #000000;
        &::-webkit-input-placeholder{
            font-family: "Roboto-Regular";
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
        &::-moz-placeholder{
            font-family: "Roboto-Regular";
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
        &:-ms-input-placeholder{
            font-family: "Roboto-Regular";
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
        &::-ms-input-placeholder{
            font-family: "Roboto-Regular";
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
        &::placeholder{
            font-family: "Roboto-Regular";
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
    }
    textarea.form-control{
        height: 155px;
    }
    .btn{
        display: block;
        width: 100%;
        height: 60px;
        padding: 0 43px;
        line-height: 60px;
    }
    &:last-of-type{
        margin-bottom: 0;
    }
    &:first-of-type{
        margin-bottom: 21px;
    }
}

.checkbox_blue{
    display: inline-block;
    [type=checkbox]{
        ~.check-label{
            font-family: "Roboto-Regular";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            &:before{
                width: 20px;
                height: 20px;
                border: 1px solid #D5D5D5;
                -webkit-transition: background 0.3s ease, border 0.3s ease;
                -o-transition: background 0.3s ease, border 0.3s ease;
                transition: background 0.3s ease, border 0.3s ease;
            }
            &:after{
                background-image: url(../images/check.svg);
                background-position: center;
                background-size: 10px;
                background-repeat: no-repeat;
                left: 3px;
            }
        }
        &:checked{
            ~.check-label{
                &:before{
                    border: 1px solid #4B4998;
                    background: #4B4998;
                }
            }
        }
    }
}

.dark_label{
    display: inline-block;
    [type=checkbox]{
        ~.check-label{
            font-family: "Lato-Regular","Arial","Helvetica","Tahoma",sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #303030;
            &:before{
                top: 2px;
            }
            &:after{
                top: 12px;
            }
        }
    }
}

.footer--new{
    .footer__logo{
        margin-right: 74px;
        display: inline-block;
        vertical-align: middle;
    }
    .footer__nav{
        display: inline-block;
        vertical-align: middle;
    }
    .footer_row{
        padding-top: 13px;
        padding-bottom: 33px;
    }
    .footer__nav-item{
        margin-right: 39px;
    }
    .footer__contact-list{
        flex-wrap: wrap;
        .footer__contact-item{
            margin-right: 0;
            margin-bottom: 8px;
            &:last-of-type{
                margin: 0;
            }
            .footer__contact-link{
                font-family: "Lato-Bold";
                font-size: 20px;
                line-height: 28px;
                color: #FFFFFF;
                -webkit-transition: color 0.3s ease;
                -o-transition: color 0.3s ease;
                transition: color 0.3s ease; 
                &:hover{
                    color: #FAA21E;
                    text-decoration: none;
                }
            }
        }
    }
    .social__column{
        padding: 0;
        padding-left: 16px;
        padding-top: 8px;
    }
    .footer__contact{
        padding: 0;
        display: block;
        &:last-of-type{
          padding-left: 25px;
          .footer__contact-list{
            .footer__contact-item{
              margin-bottom: 14px;
              &:last-of-type{
                margin: 0;
              }
            }
          }
        }
    }
    .social-footer-icon-block{
        span{
            font-family: "Roboto-Regular";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1;
            vertical-align: bottom;
            color: #FFFFFF;
            display: inline-block;
            margin-right: 16px;
        }
        a{
            -webkit-transition: background 0.3s ease !important;
            -o-transition: background 0.3s ease !important;
            transition: background 0.3s ease !important;
        }
    }
    .footer__bottom{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        .bottom_element{
            padding: 0 10px; 
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
    }
}

.home-news{
    .title_section_block{
        margin-bottom: 90px;
    }
}

.news_container{
    padding-bottom: 96px;
    &.home-news{ 
        .slick-arrow{
            width: 52px;
            height: 52px;
            top: -74px;
            right: -40px;
            &:before{
                width: 7px;
                height: 22px;
                background-image: url(../images/arrow-downn.svg); 
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);  
                -webkit-transition: background 0.3s ease;  
                -o-transition: background 0.3s ease;  
                transition: background 0.3s ease; 
            }
            &.slick-prev{
                right: 21px;
                &:before {
                    -webkit-transform: translate(-50%,-50%) rotate(180deg);
                        -ms-transform: translate(-50%,-50%) rotate(180deg);
                            transform: translate(-50%,-50%) rotate(180deg);
                }
            }
            &.slick-disabled{
                &:before {
                    width: 7px;
                    height: 22px;
                    background-image: url(../images/arrow-downn-grey.svg); 
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
        .home-news__grid{
            margin: 0;
            padding-left: 12px;
        }
        .home-news__main{
            padding: 0;
            padding-right: 6px;
        }
        .home-news__right{
            padding: 0 35px;
            padding-left: 37px;
        }
        .news-item--big{
            .news-item__content{
                .news-item__title{
                    line-height: 34px;
                    margin-bottom: 17px;
                }
                .news-item__description{
                    font-family: 'Roboto-Regular';
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    margin-bottom: 9px;
                }
            }
        }
        .news-item__title{
            &:hover{
                color: #4B4998;
            }
        }
        .news-item__date{
            font-family: 'Roboto-Regular';
            font-weight: bold;
        }
        .home-news__right{
            height: 588.2px;
        }
        .slick-list{
            height: 588.2px !important; 
        } 
        .home-news__slide{
            padding-bottom: 22px;
        }
        .section__action{
            margin-top: 69px;
            .btn_stroke_blue{
                padding: 0 47px; 
            }
        }
    }
}

.partners_block{
    padding-top: 19px;
    padding-bottom: 76px;
    .title_section_block{
        margin-bottom: 45px;
    }
}

.partners_slider{
    padding: 0 28px;
    .slick-arrow{
        width: 18px;
        height: 18px;
        background-position: center;
        background-size: 18px;
        background-repeat: no-repeat;
        -webkit-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%); 
        &.slick-disabled{
            opacity: 0.5;
        }
    }
    .slick-prev{
        background-image: url(../images/estimate_left_color.svg); 
        left: -28px;
    }
    .slick-next{
        background-image: url(../images/estimate_right_color.svg);  
        right: -28px;
    }
    .partners-slider__item{
        height: 107px;
        padding: 0 18px;
        .inner_block{
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            img{
                margin: auto;
                max-width: 100%
            }
        }
    }
}

.mobile_btn_menu_container{
  display: none;
}
.blue-header_mobile_menu{
  display: none;
  width: 30px;
  height: 21px;
  vertical-align: middle;
  margin-left: 30px;
  position: relative;
  overflow: hidden;
  span{
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 1.5px);
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background: #2c2a6c;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: .35s ease-in-out .3s;
    -o-transition: .35s ease-in-out .3s;
    transition: .35s ease-in-out .3s;
  }
  &:after,
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: #2c2a6c;
    position: absolute;
    left: 0;
    border-radius: 3px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  &:before{
    top: 0;
  }
  &:after{
    bottom: 0;
  }
  &.is-open{
    span{
      opacity: 0;
      left: -60px;
      -webkit-transition: .35s ease-in-out;
      -o-transition: .35s ease-in-out;
      transition: .35s ease-in-out;
    }
    &:after{
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
      bottom: calc(50% - 1.5px);
      -webkit-transition: .5s ease-in-out .1s;
      -o-transition: .5s ease-in-out .1s;
      transition: .5s ease-in-out .1s;
    }
    &:before{
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: calc(50% - 1.5px);
      -webkit-transition: .5s ease-in-out .12s;
      -o-transition: .5s ease-in-out .12s;
      transition: .5s ease-in-out .12s;
    }
  }
}

@-webkit-keyframes fadeIn {
   0%{
    opacity: 0;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
   }
   100%{
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
   }
}
@keyframes fadeIn {
   0%{
    opacity: 0;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
   }
   100%{
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
   }
}

@-webkit-keyframes fadeOut {
   0%{
    opacity: 1;
    right: 0;
   }
   100%{
    opacity: 0;
    -webkit-transform: translate3d(150%,0,0);
    transform: translate3d(150%,0,0);
   }
}
@keyframes fadeOut {
   0%{
    opacity: 1;
    right: 0;
   }
   100%{
    opacity: 0;
    -webkit-transform: translate3d(150%,0,0);
    transform: translate3d(150%,0,0);
   }
}

.fadeOut{
  right: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}

.mobile_menu-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255,255,255,0.4);
  z-index: -100;
  opacity: 0;
  -webkit-transition: z-index 0s ease 1s, opacity 1s ease;
  -o-transition: z-index 0s ease 1s, opacity 1s ease;
  transition: z-index 0s ease 1s, opacity 1s ease;
}

.popup-opened{
  header{
    box-shadow: none;
  }
  .mobile_menu-overlay{
    z-index: 97;
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
  }
}

.mobile_company_links_container{
  display: none;
  .mobile_company_links{
    margin-bottom: 15px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.mobile-block_menu{
  position: fixed;
  top: 0;
  right: -150%;
  width: 320px;
  height: 100%;
  background: #fff;
  z-index: 98;
  overflow: hidden;
  -webkit-box-shadow: -4px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -4px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: -4px 0px 8px 0px rgba(34, 60, 80, 0.2);
  .inner_block{
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 0;
      height: 0;
      background: transparent;
    }
    scrollbar-width: none;
    -ms-overflow-style : none; /* IE 10+ */
    overflow: -moz-scrollbars-none;
  }
  ul{
    display: block;
    width: 100%;
    padding-bottom: 35px;
    li{
      display: block;
      margin-bottom: 10px;
      a{
        display: inline-block;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #2C2A6C;
        text-transform: uppercase;
      }
    }
  }
  .mobile_enter_elements{
    margin-bottom: 15px;
    &:last-of-type{
      margin-bottom: 35px;
    }
  }
  .mobile_enter_title{
    margin-bottom: 15px;
    p{
      font-size: 18px;
      line-height: 28px;
      color: #000;
    }
  }
  &.is-show{
    right: 0;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn; 
  }
}

.draft-remove{
  width: 20px;
  height: 20px;
  margin-left: 20px;
  svg{
    width: 20px;
    height: 20px;
    fill: #A1A1A1;
    -webkit-transition: fill 0.3s ease;
    -o-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  &:hover{
    svg{
      fill: #000;
    }
  }
}

.tender-item__foot {
  // padding-bottom: 18px !important;
  .tender-item__option{
    padding: 8px 0;
  }
}

.draft-remove{
  display: none;
}

.tender-item__draft{
  .draft-remove{
    display: inline-block;
  }
}

.popup--notification{
  .popup__box{
    max-width: 650px;
    padding: 65px 32px 56px 32px;
    .popup__footer{
      padding-top: 24px;
    }
  }
}

.participate-block__action{
  .btn{
    padding: 11px 43px;
  }
}

.notification_information_width_icon{
  padding-top: 13px;
  padding-bottom: 21px;
  .notification-icon_element{
    text-align: center;
    margin-bottom: 29px;
  }
  .notification_information-text_block{
    .notification_information-text{
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      color: #303030;
      display: block;
      text-align: center;
    }
  }
}

.conditions-block{
  &:not(.conditions-block--edit){
    .conditions-block__inner{
      .conditions-block__note{
        &:last-of-type{
          margin-bottom: 45px;
        }
      }
      ._title{
        margin-bottom: 12px;
      }
      .conditions-block__left{
        .info-list__item{
          margin-bottom: 12px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .conditions-block__inner{
    ._title{
      font-family: "Lato-Heavy","Arial","Helvetica","Tahoma",sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      margin-bottom: 16px;
      span{
        color: #FDA21A;
      }
    }
    .conditions-block__note{
      margin-bottom: 32px;
      margin-top: 0;
      padding: 0;
      background: transparent;
      &:last-of-type{
        margin-bottom: 30px;
      }
      .form-group{
        textarea{
          &.form-control{
            height: 90px;
            padding: 10px;
          }
        }
      }
    }
    .conditions-block__right{
      margin-left: 0;
      -webkit-box-flex: 0;
        -ms-flex: 0 0 480px;
            flex: 0 0 480px;
      max-width: 480px;
    }
    .conditions-block__left{
      margin-right: 0;
      -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 480px);
            flex: 0 0 calc(100% - 480px);
      max-width: calc(100% - 480px);
      padding-right: 69px;
      .section-lk__label{
        margin-bottom: 26px;
        font-size: 16px;
        line-height: 20px;
      }
      .info-list__item{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
        .info-list__label{
          -webkit-box-flex: 0;
            -ms-flex: 0 0 calc(100% - 140px);
                flex: 0 0 calc(100% - 140px);
          max-width: calc(100% - 140px);
          margin-right: 0;
          padding-right: 20px;
        }
        .info-list__value{
          -webkit-box-flex: 0;
            -ms-flex: 0 0 140px;
                flex: 0 0 140px;
          max-width: 140px;
          .dropdown__header--placeholder{
            .dropdown__text{
              color: #303030;
            }
          }
        }
      }
    }
  }
}

.field-textarea{
  position: relative; 
  .limit_block{
    // position: absolute;
    // right: 8px;
    // right: 19px;
    // bottom: 8px;
    // bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #B8B8B8;
    text-align: right;
  }
}

.acco-work__footer{
  .acco-work__total{
    -webkit-box-flex: 0;
      -ms-flex: 0 0 810px;
          flex: 0 0 810px;
    max-width: 810px;
    hr{
      color: #ececec;
      border-bottom: 1px solid #ececec;
      border-top: none;
      margin-bottom: 17px;
    }
    .acco-work__total-item{
      ._label{
        -webkit-box-flex: 0;
          -ms-flex: 0 0 calc(100% - 200px);
              flex: 0 0 calc(100% - 200px);
        max-width: calc(100% - 200px);
      }
      ._value{
        -webkit-box-flex: 0;
          -ms-flex: 0 0 200px;
              flex: 0 0 200px;
        max-width: 200px;
      }
    }
  }
}

.condition-block_function{
  align-items: center;
  div{
    ._title{
      margin-bottom: 0;
    }
  }
  .element_name{
    display: inline-block;
    font-family: "Lato-Heavy","Arial","Helvetica","Tahoma",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-right: 24px;
  }
  .add-work__action{
    margin: 0;
  }
}

.change_win-btn{
  &.is-active{
    background: #10AF59;
    svg{
      fill: #fff;
    }
    &:hover{
      svg{
        fill: #fff;
      }
    }
  }
}

.info-list__value{
  .value-link{
    text-transform: uppercase;
    color: #1967FD;
    font-weight: 600;
    font-size: 16px;
    transition: color 0.3s ease;
  }
}

.text-right{
  text-align: right;
}

.estimate-lk{
  padding-left: 0;
  padding-bottom: 40px;
  background: #fff;
  + .section-lk__acco{
    margin-top: 24px;
  }
  .estimate_sentences{
    .column_title_row{
      .column_title_row_item{
        &:nth-of-type(5),
        &:nth-of-type(6){
          -webkit-box-flex: 0 !important;
          -ms-flex: 0 0 140px !important;
              flex: 0 0 140px !important;
          max-width: 140px !important;
        }
      }
    }
    .table_row{
      .table_row_item{
        &:nth-of-type(5),
        &:nth-of-type(6){
          -webkit-box-flex: 0 !important;
          -ms-flex: 0 0 140px !important;
              flex: 0 0 140px !important;
          max-width: 140px !important;
        }
      }
    }
  }
}

div{
    &.change-tariff__col--small { 
        form{
            height: 100%;
        }
    }
}

.tariff-item__price_old, .tariff-item__price_individual_old {
    text-decoration: line-through;
    font-size: 14px;
    color: #9d9d9d;
    text-align: right;
    padding-right: 8px;
}

.tariff-item__radio {
    &:last-of-type {
        margin-bottom: 0;
    }
}

.tariff-item__price {
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 700 !important;
}

.tariff-item__radio {
    span {
        &.radio-label {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.tariff-item__foot { 
    &.center-text {
        padding: 0;
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
        align-items: center;
        min-height: 118px;
        padding-top: 24px;
    }
}

.container--tarification {
    max-width: 1360px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
}

.grey {
    color: #9d9d9d;
}

.tariff-item__list {
    li {
        margin-bottom: 16px !important; 
        padding-right: 24px;
        .tariff-item__info-icon{
            top: 50%;
            transform: translateY(-50%); 
            right: 0;
        }
        .tariff-item__info {
            z-index: 50;
            height: unset !important;
            right: 20px;
            left: auto !important;
            padding-bottom: 11px;
            top: calc(50% + 10.5px) !important;
        }
        &.grey{
            &:before{
                position: absolute;
                right: 16px;
                z-index: 1;
                display: block;
                background-color: transparent;
                background-image: url(../images/uncheck.svg);
                width: 21px;
                height: 21px;
                background-repeat: no-repeat;
                background-position: unset;
                background-size: contain;
            }
        }
        &.hr{
            border-top: 1px solid #ececec;
            &:before {
                background-image: none;
            }
        }
    }
}

.tariff-item__inner {
    padding: 24px 16px !important; 
}

.change-tariff__inner {
    .section__action {
        .btn {
            margin-right: 16px;
        }
    }
}

.errors {
    color: #dc3545;
    padding-bottom: 20px;
}

.tariff-item__special{
    &.temporary-free {
        float: none;
        width: 100% !important;
        display: block;
        padding: 16px 50px 16px 60px !important;
        p{
            margin: 0 0 10px 0 !important;
            &:before{
                top: 18px !important;
                width: 24px !important;
                height: 24px !important;
                left: 20px !important;
                background-size: contain !important;
                background-position: center !important; 
                background-repeat: no-repeat !important;
            }
        } 
    }
} 

.fillter_element,
.blue--style-info_list{
    display: inline-block;
    margin-right: 24px;
    position: relative;
    &:last-of-type{
        margin-right: 0;
    }
    .element-title{
        position: relative;
        padding-right: 22px;
        line-height: 1;
        cursor: pointer;
        span{
            display: inline-block;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #2C2A6C;
            transition: color .3s ease;
        }
        .icon{
            width: 12px;
            height: 12px;
            position: absolute;
            right: 0;
            top: calc(50% - 5px);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform .3s ease;
            svg{
                width: 12px;
                height: 12px;
                path{
                    stroke: #2C2A6C;
                    transition: stroke .3s ease;
                }
                &.selected{
                    display: none;
                }
            }
        } 
    }
    .element-dropdown{
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(135, 135, 135, 0.33);
        position: absolute;
        top: calc(100% + 15px);
        // left: 0;
        min-width: 100%;
        // width: 356px;
        border-radius: 10px;
        z-index: 15; 
        // padding: 32px 0;
        display: none;
        .inner{
            // padding: 0 32px 0 32px;
            max-height: 220px;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style : none; /* IE 10+ */
            overflow: -moz-scrollbars-none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
            }
            .dropdown-element_block{
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        &:before{
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            position: absolute;
            left: 20%;
            top: 0;
            transform: translateY(-50%) rotate(45deg);
            background: #fff;
        }
        .dropdown-element_block{
            margin-bottom: 22px;
        }
        .radio-box,
        .check-box{
            .radio-label,
            .check-label{
                font-size: 16px;
                line-height: 20px;
                &:before{
                    width: 20px;
                    height: 20px;
                    top: 0;
                    margin: 0;
                }
                &:after{
                    top: 9px;
                    left: 3px;
                }
            }
        }
    }
    &.open{
        .element-dropdown{
            display: block;
        }
        .element-title{
            span{
                color: #FDA219;
            }
            .icon{
                transform: rotate(180deg);
                svg{
                    path{
                        stroke: #FDA219;
                    }
                }
            }
        }
    }
    .dropdown-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #2C2A6C;
    }
}

.blue--style-info_list {
    .element-dropdown{
        width: 340px;
        left: -15px;
        padding: 28px 0;

        .inner {
            padding: 0 28px 0 28px;
        }
    }
}

.fillter_element {
    .element-dropdown{
        width: 356px;
        left: 0;
        padding: 32px 0;

        .inner {
            padding: 0 12px 0 12px;
            .elements-block{
                padding: 0 20px 0 20px;
            }
        }
    }
    &.selected{
        .element-title {
            span{
                color: #FDA219;
            }
            .icon { 
                transform: rotate(180deg); 
                svg {
                    display: none;
                    &.selected{
                        display: block;
                    }
                    path{
                        stroke: #FDA219;
                    }
                }
            }
        }
    }
}

.fillter_sort{
    padding-top: 24px;
    .width_title{
        max-width: auto;
        width: auto;
        display: inline-block;
        text-align: left;
    }
    .fields-block{
        text-align: right;
    }
    .text-block{
        p {
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            color: #303030;
        }
    }
}

.width_title{
    .blue--style{
        height: 40px;
        .dropdown__header {
            padding: 0;
            height: 40px;
            background: transparent;
            border: none;
        }
    }
}

.blue--style{
    height: 40px;
    .dropdown__box{
        min-width: 300px;
        max-width: 356px;
        width: 100%;
        left: auto;
        right: 0;
        z-index: 3 !important;
        transform: translateY(5px); 
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(135, 135, 135, .33);
        padding: 20px 30px;
    } 
    .dropdown__list {
        span {
            padding: 0;
            border: none;
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:hover {
                background: transparent;
            }
        }
        a {
            overflow: visible;
            white-space: normal;
            text-overflow: unset;
            transition: color .3s ease;
            line-height: 18px;
        }
    }
    .dropdown__header {
        padding: 0;
        height: 40px;
        background: transparent;
        border: none;
        .dropdown__title{
            font-size: 13px;
            line-height: 16px;
            color: #7C7C7C;
            padding-right: 26px;
            margin-bottom: 4px;
        }
        .dropdown__text{
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #2C2A6C;
            padding-right: 26px;
        }
        .dropdown__arrow{
            right: 0;
            top: auto;
            transform: none;
            bottom: 9px;
            transition: transform .3s ease; 
        }
    }
    &.opened{
        .dropdown__header {
            .dropdown__arrow{
                background-image: url(../images/select_blue.svg);
                background-position: center;
                background-size: 13px 8px;
                background-repeat: no-repeat;
                transform: rotate(180deg)
            }
        }
    }
}

.change-tariff__col--small-inner{
    display: flex;
    flex-direction: column;
    .tariff-item{
        height: auto;
        flex: 1 0;
    }
    .button_container{
        padding-top: 20px;
        .btn{
            width: 100%;
            padding: 15px 5px;
            width: 100%;
        }
    }
}

.disabled--grey{
    background: #ECECEC !important;
    color: #A1A1A1 !important;
    border-color: #ECECEC !important;
}

.wrapper--lk .estimate {
    padding-left: 0;
    .estimate_inner{
        max-width: 100%;
    }
}

.wrapper--lk {
    .section-lk{
        .estimate{
            padding-bottom: 24px;
            // .estimate_inner{
            //     padding: 0;
            // }
        }
    }
}

.table_top_padding {
    padding-top: 7px;
    display: block;
}

.company-item_information{
    padding-top: 32px;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    align-items: center;

    .links-container{
        max-width: 60%;
    }
    .info_container {
        display: inline-block;
        margin-right: 24px;
        &:last-of-type{
            margin-right: 0;
        }
    }
}

.important-block{
    padding-top: 40px;
}

.important-text_block{
    position: relative;
    padding-left: 16px;
    border-left: 4px solid #FDA21A;
    .text-element{
        display: block;
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #474A4F;
    }
}

.arrow-pag-start,
.arrow-pag-end{
    display: inline-block;
    width: 18px;
    height: 10px;
    fill: currentColor;
}

.banner-parent--block {
    .banner-column {
        display: none;
    }
}

.tender-item--construction{
    .tender-item__body{
        padding: 24px;
    }
    .tender-item__head{
        padding: 16px 24px;
    }
    .tender-item__title{
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #303030;
        padding-right: 30%;
        a{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .tender-item__foot{
        padding: 8px 24px 24px;
        display: block;
    }
}

.tender-construction--text{
    margin-bottom: 8px;
    &:last-of-type{
        margin-bottom: 0;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A1A1A1;
        span{
            padding-left: 4px;
        }
    }
    span{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000;
    }
}

[disabled-table] {
    .dropdown{
        pointer-events: none !important;
        .dropdown__header{
            pointer-events: none !important;
        }
    }
}

.section-lk__label {
    font-size: 16px;
}

.section-lk{
    &.p-0{
        .section-lk__action{
            padding: 0 40px 40px 40px;
        }
    }
}


@media screen and (min-width: 1920px) {

    .banner-parent--block{
        position: relative;
        .banner-column{
            position: absolute;
            top: 0;
            width: 296px;
            display: block;
            &--left{
                left: -328px;
            }
            &--right{
                right: -328px;
            }
            .partners--banner{

                width: 100%;
                height: 663.2px;
                margin-bottom: 24px;
                border: 2px solid #000;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                &-sm{
                    height: 320px;
                }
            }
        }
    }

    .banner-element--reclame{
        display: none;
    }
}

@media screen and (max-width: 1919px) {
    .banner-element--reclame{
        display: block;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        border: 2px solid #000;
        height: 306px;
        margin-bottom: 24px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}



@media screen and (min-width: 1231px) {
    
    .news_container{
        &.home-news{ 
            .news-item--vertical {
                .news-item__content{
                    padding-right: 20px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1350px) {

  .estimate {
    .estimate_inner{
      padding: 40px 40px 0 40px;
    }

    .estimate_sentences{
      margin: 0 -40px;
      padding-left: 40px;

      .scrolled_parent{
        min-width: auto;
        overflow: hidden;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style : none; /* IE 10+ */
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
      }

      .button_row_wrapper,
      .sticky_block{
        flex: none;
        max-width: none;
        width: calc(100% - 40px);
        scrollbar-width: none;
        -ms-overflow-style : none; /* IE 10+ */
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
        >div{
          padding-left: 0;
          padding-right: 0;
        }
      }

      .js-table-wrapper{
        flex: none;
        max-width: none;
        width: calc(100% - 40px);
        padding: 0;
        .estimate_table_container{
          padding: 0;
          margin-left: 0;
        }
      }

      [data-syncscroll]{
        overflow: hidden;
        overflow-x: scroll;
      }

      >div{
        >div{
          min-width: 1200px;
        }
      }

      .scroll_block{
        min-width: calc(100% - 40px);
        overflow: hidden;
        overflow-x: scroll;
      }
    }
  }
}

@media screen and (max-width: 1230px) {
    .side-nav{
        margin-top: 83px;
    }

    .estimate{
        .button_row_wrapper{
          top: 83.09px;
        }

        .not_move{
          .sticky_block{
            top: 155.09px;
          }
          &.estimate_sentences{
            .sticky_block{
                top: 83.09px;
            }
          }
        }
        .without_button{ 
            .sticky_block{
                top: 83.09px;
            }
        }
    }

    .filter-main_block {
        .filter__inner {
            .filter__body {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 32px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
  
  .estimate {
    .estimate_inner{
      padding: 40px 40px 0 40px;
    }

    .estimate_main{
      margin: 0 -40px;
      padding-left: 20px;

      .scrolled_parent{
        min-width: auto;
        overflow: hidden;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style : none; /* IE 10+ */
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
      }

      .button_row_wrapper,
      .sticky_block{
        flex: none;
        max-width: none;
        width: calc(100% - 40px);
        scrollbar-width: none;
        -ms-overflow-style : none; /* IE 10+ */
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
        >div{
          padding-left: 20px;
          padding-right: 40px;
        }
      }

      .js-table-wrapper{
        flex: none;
        max-width: none;
        width: calc(100% - 40px);
        padding: 0;
        .estimate_table_container{
          padding: 0 40px 0 20px;
          margin-left: 0;
        }
      }

      [data-syncscroll]{
        overflow: hidden;
        overflow-x: scroll;
      }

      >div{
        >div{
          min-width: 1024px;
        }
      }

      .scroll_block{
        min-width: calc(100% - 40px);
        overflow: hidden;
        overflow-x: scroll;
      }
    }

    .estimate_sentences{

      padding-left: 40px;

      .js-table-wrapper {
        .estimate_table_container{
          padding: 0;
        }
      }

      .button_row_wrapper,
      .sticky_block{
        flex: none;
        max-width: none;
        width: calc(100% - 40px);
        >div{
          padding-left: 0;
          padding-right: 0;
        }
      }

      >div{
        >div{
          min-width: 1590px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1230px){

  .mobile_hide{
    display: none;
  }

  header{
    .mobile_hide{
      display: none;
    }
  }

  .stock_text {
    p {
      -ms-flex-preferred-size: 0;
        flex-basis: 0;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
    }
  }

  .blue-header_mobile_menu{
    display: inline-block;
  }

  .mobile_btn_menu_container{
    display: block;
  }

  .header_main_blue{
    padding-bottom: 10px;
    .blue_link{
      vertical-align: middle;
    }
    .header_row{
      padding-top: 10px;
    }
  }

  .about_screen{
    min-height: 400px;
    padding: 60px 0;
    h1{
      font-size: 36px;
      line-height: 48px;
    }
  }

  .about_company{
    padding-bottom: 45px;
    .title_section_block{
      margin-bottom: 45px;
    }
  }
  .about_company_element{
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-bottom: 20px;
  }

  .how_work{
    >.container{
      >.row{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    .section_element_count{
      margin-bottom: 15px;
      &:nth-child(2n+1){
        padding-left: 0 !important;
      }
    }
  }

  .place_tender{
    padding-bottom: 45px;
    .home-section__tabs{
      -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    }
    .home-tenders__list{
      padding-top: 30px;
    }
  }

  .partners_block{
    padding-bottom: 45px;
  }

  .home-news{
    .title_section_block{
      margin-bottom: 40px;
    }
    .news-item__inner{
      height: auto;
    }
  }

  .news_container{
    &.home-news{
      .home-news__right{
        height: auto;
      }
    }
  } 

  .questions_block{
    padding: 35px 0;
    >.container{
      >.row{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
      }
    }
    form{
      padding-left: 0;
    }
    .questions_block_elements{
      -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
            flex: 0 0 80%;
      max-width: 80%;
      margin-bottom: 35px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }

  .footer--new{
    .footer_row{
      -webkit-box-align: end;
        -ms-flex-align: end;
      align-items: flex-end;
    }
    .footer-navigation_container{
      -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
      max-width: 25%;
    }
    .social__column{
      -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
      max-width: 75%;
      padding-left: 0;
    }
    .footer__nav-list{
      -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    }
    .footer__nav{
      margin-top: 20px;
      display: block;
      vertical-align: initial;
      .footer__nav-item{
        margin-bottom: 10px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    .footer__bottom{
      .bottom_element{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
        max-width: 50%;
        padding: 0;
        padding-right: 20px;
      }
    }
  }

    .wrapper--tarification{
        main{
            padding: 0 24px;
        }
    }

    .orange-br{
        margin-top: 48px;
        margin-bottom: 22px;
    }

    .default__inner{
        .orange-br{
            margin-top: 8px;
        }
    }

    .tariff-item__special{
        &.temporary-free {
            padding: 16px 20px 16px 60px !important; 
        }
    }

    .change-tariff{
        &.change-tariff--zero-padding{
            padding: 0;
        }
    }

    .change-tariff__body{
        padding-top: 40px !important; 
    }

    .change-tariff__row{
        flex-wrap: wrap; 
    }

    .change-tariff__col{
        margin-bottom: 40px;
        &:nth-last-child(-n+2){
            margin-bottom: 0;
        }
    }

    .tariff-item__foot {
        &.center-text {
            min-height: auto;
        }
    }

    .filter-main_block {
        .filter__inner {
            padding: 16px;
        }
    }

    .blue--style-info_list,
    .fillter_element {
        margin-right: 20px;
        .element-title {
            span {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .filter__body {
        .filter__action{
            button {
                padding: 12px 16px;
            }
        }
    }

    .fillter_element,
    .blue--style-info_list{
        .element-dropdown{
            // left: -20px;
            left: -15px;
            .check-box,
            .radio-box {
                .check-label,
                .radio-label {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    &:before{
                        width: 18px;
                        height: 18px;
                    }
                    &:after{
                        top: 8px;
                        left: 2px;
                    }
                }
            }
        }
        [type=radio]{
            &:checked{
                ~ .radio-label{
                    &:before{
                        box-shadow: inset 0 0 0 5px #fda21a
                    }
                }
            }
        }
    }

    .filter{
        margin-bottom: 24px !important; 
    }

    .company-item__title {
        ._link {
            color: #303030 !important; 
            &:hover {
                color: #303030 !important; 
            }
        }
    }

    .company-item--blue_style {
        .company-item__table-body {
            p {
                line-height: 20px;
                -webkit-line-clamp: 4;
            }
        }
        .company-item__main__about {
            flex: 0 0 calc(100% - 160px);
            max-width: calc(100% - 160px);
        }
    }

    .company-item_information {
        > * {
            order: 2;
        }

        .links-container {
            order: 1;
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 32px;
            display: grid;
            grid-template-columns: repeat(2, 240px);
            grid-gap: 15px;

            .info_container{
                margin-right: 0;
                &:nth-child(even) {
                    .blue--style-info_list{
                        .element-dropdown {
                            left: auto;
                            right: -20px;

                            &:before{
                                left: auto;
                                right: 20%;
                            }
                        }
                    }
                }
            }
        }
    }

    .important-text_block {
        padding: 0 20px 0 16px;
    }

    .pagination {
        + .important-block {
            padding-top: 20px;
        }
    }

    .tender-item--construction{
        .tender-item__options{
            max-width: 75%;
            .tender-item__option{
                margin-right: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .tender-item__title{
            padding-right: 0;
        }
    }  

    .company-item--blue_style{
        .company-item__body{
            min-height: 396.7px;
            .company-item__main{
                display: flex;
                flex-direction: column; 
            }
            .company-item_information{
                margin-top: auto;
            }
        }
    }
    .tender-item--construction {
        .tender-item__inner {
            min-height: 431px;
        }
    }
}

@media screen and (max-width: 767px){
    .wrapper--tarification{
        main{
            padding: 0 16px;
        }
    }
    .orange-br{
        margin-top: 40px;
        margin-bottom: 20px;
        .breadcrumbs__list{
            .breadcrumbs__item{
                &:nth-child(n+2){
                    display: none;
                }
                &:first-of-type{
                    padding-right: 0;
                    padding-left: 20px;
                    &:before {
                        content: '';
                        background-color: transparent;
                        background-image: url(../images/svg/sprite.svg);
                        -webkit-background-size: 915px 646px;
                        background-size: 915px 646px;
                        width: 13px;
                        height: 8px;
                        background-position: -60px -38px;
                        width: 16px;
                        height: 16px;
                        right: auto;
                        left: 0;
                        top: 2px;
                        transform: rotate(90deg);  
                    }
                }
            }
        }
    }
    .title_section_block {
        .title_section_inner {
            font-size: 24px;
        }
    }
    .underline_block{
        background-size: 100% 7px;
    }
    .tariff-item__special{
        &.temporary-free {
            padding: 16px 20px 16px 60px !important; 
        }
    }
    .change-tariff{
        &.change-tariff--zero-padding{
            padding: 0;
        }
    }

    .change-tariff__body{
        padding-top: 40px !important; 
    }
    .change-tariff__row{
        flex-wrap: wrap; 
    }
    .tariff-item__foot{
        &.center-text{
            min-height: auto;
        }
    }
    .change-tariff__col{
        margin-bottom: 40px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    .default__inner{
        padding: 40px 16px 0 16px;
        .orange-br{
            margin-top: 0;
        }
    }

    .title_section_block {
        .title_section_inner {
            line-height: 29px;
        }
    }

    .filter-main_block {
        .filter__inner {
            padding: 24px 16px;
        }
        .filter__head{
            justify-content: flex-start;
            flex-wrap: wrap; 
            margin-bottom: 32px;
            .fillter_element {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 16px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .element-title {
                    display: inline-block;
                }
            }
        }
    }

    .blue--style-info_list,
    .fillter_element {
        margin-right: 20px;
        .element-title {
            span {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .filter__body {
        .filter__action{
            button {
                padding: 12px 16px;
            }
        }
    }

    .filter-main_block {
        .filter__inner {
            .filter__body {
                padding-top: 0;
            }
        }
    }

    .fillter_element,
    .blue--style-info_list{
        .element-dropdown{
            left: -16px;
            width: calc(100vw - 32px); 
            max-width: 356px;
            min-width: auto;
            .inner {
                padding: 0 8px; 
                max-height: 200px;
                .elements-block{
                    padding: 0 10px;
                }
            }
            .check-box,
            .radio-box {
                .check-label,
                .radio-label {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    padding-left: 32px;
                    &:before{
                        width: 18px;
                        height: 18px;
                    }
                    &:after{
                        top: 8px;
                        left: 2px;
                    }
                }
            }
        }
        [type=radio]{
            &:checked{
                ~ .radio-label{
                    &:before{
                        box-shadow: inset 0 0 0 5px #fda21a
                    }
                }
            }
        }
    }

    .blue--style-info_list{
        .element-dropdown{
            left: -16px;
            width: calc(100vw - 32px); 
            max-width: 356px;
            min-width: auto;
            .inner {
                padding: 0 18px; 
                max-height: 200px;
            }
        }
    }

    .filter{
        margin-bottom: 24px !important; 
    }

    .company-item__title {
        ._link {
            color: #303030 !important; 
            &:hover {
                color: #303030 !important; 
            }
        }
    }

    .company-item--blue_style {
        .company-item__table-body {
            p {
                line-height: 20px;
                -webkit-line-clamp: 4;
            }
        }
        .company-item__main__about {
            flex: 0 0 calc(100% - 160px);
            max-width: calc(100% - 160px);
        }
    }

    .company-item_information {
        > * {
            order: 2;
        }

        .btn{
            padding: 15px 20px;
        }

        .links-container {
            order: 1;
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 32px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 15px;

            .info_container{
                margin-right: 0;
            }
        }
    }

    .important-text_block {
        padding: 0 0 0 16px;
    }

    .pagination {
        + .important-block {
            padding-top: 20px;
        }
    }

    .fillter_sort {
        .fields-block {
            text-align: left;
        }
    }

    .company-item__table-body{
        height: auto !important; 
        max-height: 80px;
    }

    .fillter_sort{
        .text-block{
            margin-bottom: 24px;
        }
        .width_title{
            max-width: 100%;
        }
    }

    .blue--style {
        .dropdown__box{
            padding: 20px;
            right: auto;
            left: 0;
            min-width: 288px;
            max-width: 356px;
            width: auto;
        }
        .dropdown__header {
            .dropdown__title {
                padding-left: 0;
            }
            .dropdown__text{
                padding-left: 0;
            }
        }
    }

    .company-item--blue_style {
        .company-item__main__about {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .company-item_information{
        padding-top: 0;
    }

    .pagination__list{
        flex-wrap: wrap;
        .pagination__link{
            // width: 40px;
            height: 40px;
            padding: 10px 5px;
            min-width: 40px;
            font-size: 14px;
            line-height: 20px;
        }

        .pagination__item{
            margin-bottom: 5px;
        }

        .pagination__item--end,
        .pagination__item--next,
        .pagination__item--prev,
        .pagination__item--start {
            .pagination__link {
                padding: 8px 5px;
            }
        }

    }

    .icon-arrow-pag-next, 
    .icon-arrow-pag-prev,
    .arrow-pag-end, 
    .arrow-pag-start {
        width: 16px;
        height: 8px;
    }

    .tender-item--construction{
        .tender-item__options{
            flex: 0 0 100%;
            max-width: 100%;
            order: 2;
            padding-top: 16px;
            .tender-item__option{
                margin-right: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .tender-item__title{
            padding-right: 0;
            font-size: 20px;
            line-height: 24px;
        }
        .tender-item__head{
            flex-wrap: wrap;
            padding: 24px 16px 16px 16px;
        }
        .tender-item__status{
            margin-left: 0;
            order: 1;
        }
        .tender-item__body{
            padding: 16px;
            .tender-construction--text{
                p,span {
                    font-size: 12px;
                }
            }
        }
        .tender-item__foot{
            padding: 8px 16px 16px;
        }
    }  

    .tender-construction--text{
        margin-bottom: 5px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    .company-item--blue_style{
        .company-item__body{
            min-height: 460.2px;
            display: flex;
            flex-direction: column;
            .company-item__main{
                display: flex;
                flex-direction: column;
                flex-basis: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;
            }
            .company-item_information{
                margin-top: auto;
            }
        }
    }
    .tender-item--construction {
        .tender-item__inner {
            min-height: 414.5px;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 767px){

  .mobile_company_links_container{
    display: block;
  }

  .mobile_hide{
    display: none;
  }

  header{
    .mobile_hide{
      display: none;
    }
  }

  .header_function{
    display: none;
  }

  .stock_line{
    padding: 10px 12px;
    .close_link{
      margin-left: 0;
      span{
        display: none;
      }
    }
  }

  .stock_text {
    p {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
              flex-grow: 1;
    }
  }

  .blue-header_mobile_menu{
    display: inline-block;
  }

  .mobile_btn_menu_container{
    display: block;
  }

  .header_main_blue{
    padding-bottom: 10px;
    .blue_link{
      vertical-align: middle;
    }
    .header_row{
      padding: 0 12px;
      padding-top: 10px;
    }
  }

  .about_screen{
    min-height: 315px;
    padding: 35px 12px;
    h1{
      font-size: 24px;
      line-height: 42px;
    }
  }

  .about_company{
    padding: 44px 12px 35px 12px;
    .title_section_block{
      margin-bottom: 35px;
    }
  }
  .about_company_element{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    .text_block{
      padding: 0 20px;
    }
  }

  .our_advantages{
    padding: 0 12px 40px 12px;
    .advantages_column{
      width: 100%;
      margin-bottom: 20px;
      &:last-of-type{
        margin-bottom: 0;
      }
      .advantages_inner{
        padding: 15px 20px 25px 10px;
        .block-title{
          .name{
            font-size: 22px;
            line-height: 32px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .how_work{
    padding: 0 12px;
    .title_section_block{
      margin-bottom: 30px;
    }
    .section_element_count{
      margin-bottom: 15px;
      &:nth-child(2n+1){
        padding-left: 0 !important;
      }
      .title-block_element{
        position: relative;
        padding-left: 58px;
        .section_element_number{
          margin-right: 0;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .place_tender{
    padding-bottom: 45px;
    .home-section__tabs{
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .home-tenders__list{
      padding-top: 30px;
    }
  }

  .partners_block{
    padding-bottom: 45px;
  }

  .home-news{
    .title_section_block{
      margin-bottom: 40px;
    }
    .news-item__inner{
      height: auto;
    }
  }

  .news_container{
    &.home-news{
      padding-bottom: 40px;
      .home-news__grid{
        padding-left: 0;
        .home-news__main{
          padding-right: 0;
        }
      }
      .home-news__right{
        height: auto;
      }
      .section__action{
        margin-top: 20px;
      }
    }
  } 

  .questions_block{
    padding: 35px 12px;
    >.container{
      >.row{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    form{
      padding-left: 0;
      .form-group{
        .help-block{
          &.help-block-error{
            right: 0;
          }
        }
      }
    }
    .questions_block_elements{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 35px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }

  .footer--new{
    .footer_row{
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    .footer-navigation_container{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .social__column{
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      margin-top: 15px;
    }
    .footer__nav-list{
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    .footer__nav{
      margin-top: 0;
      display: block;
      vertical-align: initial;
      .footer__nav-item{
        margin-bottom: 10px; 
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    .footer__bottom{
      .bottom_element{
        -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        padding-right: 20px;
      }
    }
    .footer__contact{
      &:last-of-type{
        margin-top: 23px;
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  
  .place_tender{
    &.home-tenders{
      .home-section__tabs{
        top: 120px;
      }
    }
  }
}

.jsHasError{
    border: 1px solid red !important; 
}