.page-landing {
  @include desktop {
    padding-bottom: 60px;
  }

  @include tablet {
    padding-bottom: 80px;
  }

  @include mobile {
    padding-bottom: 48px;
  }

  .page-title__title {
    @include desktop {
      margin: 50px 0 40px;
    }

    @include tablet {
      margin: 32px 0 40px;
    }

    @include mobile {
      margin: 17px 0 32px;
    }
  }
}