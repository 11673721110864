.gallery-block {
  &__inner {

  }

  &__view {
    position: relative;

    @include no-mobile {
      margin-bottom: 24px;
    }

    .slick {
      &-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        font-size: 0;
        z-index: 99;
        transition: .3s;
        opacity: 1;

        &:hover {
          border-color: rgba(255, 255, 255, 1);
          opacity: 1;
          padding: 1px;
        }

        &:before {
          content: '';
          @include sprite('slick-arrow');
          @include align(true, true);
        }
      }

      &-prev {
        left: 32px;

        @include tablet {
          left: 24px;
        }
      }

      &-next {
        right: 32px;

        @include tablet {
          right: 24px;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      &-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        li {
          padding: 2px;

          &:last-child:not(.slick-active), &:first-child:not(.slick-active) {
            button {
              width: 4px;
              height: 4px;
            }
          }

          button {
            width: 8px;
            height: 8px;
            background-color: #E4E4E4;
            border-radius: 50%;
            font-size: 0;
            transition: .3s;
          }

          &.slick-active {
            button {
              background-color: #faa21e;
            }
          }
        }
      }
    }

    &-list {
      overflow: hidden;
      font-size: 0;
      position: relative;

      @include no-mobile {
        &:before {
          content: '';
          display: block;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) -57.76%, rgba(0, 0, 0, 0) 88.82%);
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 191px;
          z-index: 1;
        }

        &:after {
          content: '';
          display: block;
          background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) -57.76%, rgba(0, 0, 0, 0) 88.82%);
          border-radius: 4px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 191px;
          z-index: 1;
        }
      }

      @include desktop {
        height: 600px;
      }

      @include tablet {
        height: 320px;
      }

      @include mobile {
        height: 180px;
        padding-bottom: 24px;
      }

      &.slick-initialized {
        height: auto;
        overflow: auto;
      }
    }

    &-item {
      ._item {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include desktop {
          height: 600px;
        }

        @include tablet {
          height: 320px;
        }

        @include mobile {
          border-radius: 4px;
          overflow: hidden;

          &:before {
            content: '';
            display: block;
            padding-top: 72.6%;
          }
        }
      }
    }
  }

  &__nav {
    @include mobile {
      display: none;
    }

    &-list {
      height: 110px;
      overflow: hidden;
      font-size: 0;
      margin: -10px;

      @include tablet {
        height: 115px;
      }

      &.slick-initialized {
        height: auto;
        overflow: auto;
      }
    }

    .slick-current {
      ._item {
        border-color: #FDA21A;
      }
    }

    &-item {
      display: inline-block;
      vertical-align: top;
      padding: 10px;

      &:last-child {
        margin-right: 0;
      }

      ._item {
        height: 114px;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 2px solid transparent;

        @include tablet {
          height: 115px;
        }

        img {
          max-height: 100%;
        }
      }

    }
  }
}