.popup-sale {
  padding-top: 40px;
  display: none;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 16px;
    @include bold;
    letter-spacing: .02em;
    margin-left: 40px;
  }

  &__field {
    flex: 0 0 170px;
    max-width: 170px;

    .form-control {
      border-radius: 4px 0 0 4px;
    }
  }

  &__action {
    flex-shrink: 0;

    .btn {
      border-radius: 0 4px 4px 0;
    }
  }
}