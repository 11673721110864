.company-main {
  @include desktop {
    margin-bottom: 64px;
  }

  @include tablet {
    margin-bottom: 43px;
  }
  @include mobile {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    background-color: #fff;
    border-radius: 4px;

    @include desktop {
      padding: 40px;
    }

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 16px 16px 24px;
    }
  }

  &__head {
    @include no-mobile {
      display: flex;
      align-items: center;
    }

    @include desktop {
      margin-bottom: 65px;
    }

    @include tablet {
      margin-bottom: 40px;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__aside {
    @include no-mobile {
      flex: 0 0 108px;
      max-width: 108px;
      margin-right: 40px;
    }

    @include mobile {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__logo {
    border: 3px solid #FDA21A;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    width: 108px;
    height: 108px;

    @include mobile {
      flex: 0 0 50px;
      height: 50px;
      max-width: 50px;
      margin-right: 16px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ._img {
      margin: auto;
    }
  }

  &__rating {
    text-align: center;

    @include no-mobile {
      margin-top: -20px;
    }

    ._value {
      font-size: 14px;
      color: #FFFFFF;
      @include heavy;
      background: #10AF59;
      border-radius: 4px;
      line-height: 31px;
      padding: 0 6px;
      display: inline-block;
    }
  }

  &__main {
    flex: 1;
  }

  &__breadcrumbs {
    margin-bottom: 16px;

    @include mobile {
      display: none;
    }
  }

  &__title {
    @include tablet {
      line-height: 34px;
      font-size: 30px;
    }

    @include mobile {
      font-size: 20px;
      line-height: normal;
    }
  }

  &__body {
    @include tablet {
      align-items: center;
    }

    @include no-mobile {
      display: flex;
    }
  }

  &__option {
    @include mobile {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-list {
      display: flex;

      @include mobile {
        justify-content: space-between;
      }
    }

    &-item {
      color: #303030;
      margin-right: 80px;
      text-align: center;

      @include tablet {
        margin-right: 55px;
      }

      @include mobile {
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }

      ._value {
        @include heavy;

        @include desktop {
          line-height: 52px;
          font-size: 54px;
        }

        @include tablet {
          line-height: 52px;
          font-size: 44px;
          margin-bottom: 5px;
        }

        @include mobile {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 10px;
        }
      }

      ._label {
        @include no-mobile {
          font-size: 16px;
        }

        @include mobile {
          font-size: 14px;
        }
      }
    }
  }

  &__action {
    @include no-mobile {
      margin-left: auto;
      flex-shrink: 0;
      padding-left: 10px;
    }

    .btn {
      @include mobile {
        width: 100%;
        padding: 15px 5px;
      }
    }
  }
}