.address {
  &-check {
    ._input {
      &:checked {
        ~ .address-field {
          display: none;
        }
      }
    }

    .address-field {
      margin-top: 20px;
    }
  }

  &-field {
    &__row {
      display: flex;
      margin: -12px;
    }

    &__col {
      padding: 12px;

      &:last-child {
        margin-right: 0;
      }

      &._col-1,
      &._col-2{
        flex: 0 0 20%;
        max-width: 20%;
      }

      &._col-3,
      &._col-4{
        flex: 0 0 30%;
        max-width: 30%;
      }

      &._col-5{
        flex: 0 0 40%;
        max-width: 40%;
      }

      &._col-6,
      &._col-7{
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }
}

