$fonts_dir: '../fonts/';

@mixin font($family, $name) {
  $font_file_path: $fonts_dir + $family;

  @font-face {
    font-family: $name;
    src: url($font_file_path + '.woff') format('woff'),
    url($font_file_path + '.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin font-mixin($font_name) {
  font-family: "#{$font_name}", 'Arial', 'Helvetica', 'Tahoma', sans-serif;
  font-weight: normal;
  font-style: normal;
}

@include font("Lato/Light", "Lato-Light");
@mixin light() {
  @include font-mixin("Lato-Light");
}

@include font("Lato/Regular", "Lato-Regular");
@mixin regular() {
  @include font-mixin("Lato-Regular");
}

@include font("Lato/Medium", "Lato-Medium");
@mixin medium() {
  @include font-mixin("Lato-Medium");
}

@include font("Lato/Semibold", "Lato-Semibold");
@mixin semi-bold() {
  @include font-mixin("Lato-Semibold");
}

@include font("Lato/Bold", "Lato-Bold");
@mixin bold() {
  @include font-mixin("Lato-Bold");
}

@include font("Lato/Heavy", "Lato-Heavy");
@mixin heavy() {
  @include font-mixin("Lato-Heavy");
}

@include font("Lato/Black", "Lato-Black");
@mixin black() {
  @include font-mixin("Lato-Black");
}

@include font("Lato/Italic", "Lato-Italic");
@mixin italic() {
  @include font-mixin("Lato-Italic");
}

@include font("Roboto/Light", "Roboto-Light");
@mixin roboto-light() {
  @include font-mixin("Roboto-Light");
}

@include font("Roboto/Regular", "Roboto-Regular");
@mixin roboto-regular() {
  @include font-mixin("Roboto-Regular");
}

@include font("Roboto/Medium", "Roboto-Medium");
@mixin roboto-medium() {
  @include font-mixin("Roboto-Medium");
}

@include font("Roboto/Bold", "Roboto-Bold");
@mixin roboto-bold() {
  @include font-mixin("Roboto-Bold");
}