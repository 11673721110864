.section-lk {
  margin-bottom: 24px;
  padding: 40px;
  background-color: #fff;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &__acco {
    margin-bottom: 24px;
    background-color: #fff;
    padding: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &-head {
      padding: 12px 12px 12px 23px;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        @include sprite('arrow-small-dark');
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(-90deg);
        transition: .3s;
      }

      &.active {
        &:before {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }

    &-title {
      font-size: 16px;
    }

    &-body {
      display: none;
      padding: 32px 12px 12px;
    }
  }

  &__head {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }

    &--border {
      padding-top: 48px;
      border-top: 1px solid #ececec;
    }

    &--edit {
      margin-bottom: 26px;
    }
  }

  &__title {
    @include heavy;
    font-size: 30px;
    line-height: 36px;
  }

  &__label {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    ._item {
      display: inline-block;
      line-height: 20px;
      font-size: 14px;
      color: #303030;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__info {
    display: flex;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    .section-lk__label {
      flex: 0 0 51.5%;
      max-width: 51.5%;

      &--big {
        flex: 0 0 80%;
        max-width: 80%;
      }
    }

    &-action {
      padding-left: 20px;
      margin-left: auto;

      .btn-lk {
        display: block;
        min-width: 262px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__body {
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__action {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }

    .btn-more {
      background-color: #ffffff;
    }

    .total-value {
      font-size: 20px;
      color: #303030;
      @include heavy;
      line-height: 50px;
      margin-left: auto;
    }

    .btn {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &--margin-t-big {
      margin-top: 72px;
    }

    &--flex {
      display: flex;
    }
  }

  &--conditions {
    .section-lk {
      &__label {
        font-size: 16px;
      }
    }
  }

  &--table {
    padding-top: 0;
    padding-bottom: 0;
  }
}