.compare-slider {
  .slick {
    &-arrow {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      font-size: 0;
      z-index: 10;
      transition: .3s;

      &:hover {
        border-color: #303030;
      }

      &:before {
        content: '';
        @include sprite('arrow-pag-next');
        @include align();
      }
    }

    &-next {

    }

    &-prev {
      right: 48px;

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &-disabled {
      opacity: .5;
      cursor: default;
      pointer-events: none;

      &:hover {
        border-color: #EBEBEB;
      }
    }
  }

  &__common {
    display: flex;

    @media (max-width: 1910px) {
      justify-content: flex-end;
    }
  }

  &__aside {
    flex: 0 0 550px;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1910px) {
      flex-basis: 100%;
      max-width: 50%;
    }

    .compare-slider__item {
      padding: 0;
    }

    @media (max-width: 1910px) {
      display: none;
    }

    &-hidden {
      margin: 0 6px;

      @media (max-width: 1910px) {
        display: none;
      }
    }

    .compare-slider__item {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1px;

    @media (max-width: 1910px) {

    }

    @media (max-width: 1365px) {
      // flex: 0 0 543px;
      // max-width: 542px;
    }
  }

  &__list {
    position: relative;
    margin: 0 -5px;
    height: 427px;
    overflow: hidden;

    &.slick-initialized {
      height: auto;
      overflow: visible;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      float: none;
      display: block;
    }
  }

  &__item {
    padding: 0 5px;
  }

  &__action {
    margin-top: auto;
    margin-bottom: 0;

    .btn {
      padding: 11px 40px;
    }
  }
}

.slick-slide {
  width: calc(29vw - 10px);
  // for absorbing width from @Ken Wheeler answer
  box-sizing: border-box;
}

.compare-slider__item--collapse {
  width: 0 !important; // переопределяет стили slick-slider`a
  min-height: 370px;
  position: relative;
  overflow: hidden;

  .offer-item-compare,
  .offer-item-compare__inner,
  .compare-params,
  .compare-slider__action {
    display: none;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;

    margin: 0;
    margin-left: 3px;
    background: #DDDDDD;
    border-radius: 6px;
    margin-right: 5px;
    cursor: pointer;
  }
}
