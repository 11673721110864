.extend-form {
  &__inner {

  }

  &__form {
    display: flex;
  }

  &__input {
    flex: 0 0 257px;
    max-width: 257px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__action {
    .btn {
      margin-right: 13px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}