.improve-block {
  margin-bottom: 56px;
  display: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    cursor: default;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    @include sprite('popup-close');
  }

  &__head {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    @include heavy;
    font-size: 30px;
  }

  &__body {
    display: flex;
  }

  &__desc {
    max-width: 772px;
    line-height: 20px;
    font-size: 14px;
    margin-right: 20px;
  }

  &__action {
    margin-left: auto;
    flex-shrink: 0;
    margin-right: 47px;
  }

  &.showed {
    display: block;
  }
}