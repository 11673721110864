.auth {
  .header {
    > .container {
      @include no-mobile {
        max-width: none;
        padding: 0 80px;
      }

      @include tablet {
        padding: 0 40px;
      }
    }
  }

  &-page {
    flex-grow: 1;
    position: relative;
    min-height: 590px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: calc(100% - 410px);
      background: url($images_dir + 'auth.jpg') no-repeat right;
      background-size: cover;

      @include tablet-and-phone {
        display: none;
      }
    }
  }

  &__block {
    padding: 40px;
    width: 431px;
    max-width: 95%;
    background: #fff;
    @include align(false, true);
    left: 55px;
    border-radius: 4px;

    @include tablet-and-phone {
      @include align(true, true);
    }

    @include mobile {
      padding: 20px;
    }

    &--login {
      .auth__title {
        text-align: center;
      }

      .auth__form-footer a {
        font-size: 16px;
      }
    }
  }

  &__title {
    font-size: 30px;
    @include heavy;
    display: block;
    margin-bottom: 22px;
  }

  &__form {
    &-row {
      margin-bottom: 35px;

      @include mobile {
        margin-bottom: 25px;
      }
    }

    &-footer {
      display: flex;
      margin-top: 42px;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        display: block;
      }

      a, .btn {
        @include mobile {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
          display: inline-block;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      a {
        color: #1966FD;
        font-size: 14px;
        white-space: nowrap;
        &:hover {
          color: darken(#1966FD, 5%);
        }
      }

      &--no-link {
        display: block;
        overflow: hidden;

        .btn {
          float: right;
        }
      }
    }
  }

  &__note {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 28px;
    margin-top: -10px;
  }
}