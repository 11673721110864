.doc-item {
  &__inner {
    padding: 20px 18px;
    border-radius: 4px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    position: relative;
    transition: .3s;

    &:hover {
      border-color: #D8D8D8;

      .doc-item {
        &__action {
          opacity: 1;
          visibility: visible;
        }

        &__size {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &__title {
    padding-left: 34px;
    padding-right: 40px;
    position: relative;
    font-size: 16px;

    &:before {
      content: '';
      @include sprite('doc');
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  &__action {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    ._remove {
      padding: 8px;

      &:before {
        content: '';
        @include sprite('icon-remove');
      }
    }

    ._download {
      &:before {
        content: '';
        @include sprite('download')
      }
    }
  }

  &__size {
    font-size: 16px;
    color: #303030;
    @include bold;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: .15s;
  }
}