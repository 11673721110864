.default {
  flex-grow: 1;

  &__inner {
    padding-top: 40px;
    margin-bottom: 80px;

    @include tablet {
      margin-bottom: 56px;
    }

    @include mobile {
      margin-bottom: 40px;
    }
  }
}