.create-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__label {
    line-height: 20px;
    font-size: 16px;
    @include bold;
    margin-right: 24px;
  }
}