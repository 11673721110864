.option-menu {
  position: relative;

  &.opened {
    .option-menu {
      &__action {
        color: #303030;
      }

      &__body {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__action {
    color: #a1a1a1;
    margin-right: 16px;
    transition: .3s;
    height: 20px;
    width: 30px;
    display: flex;

    .icon {
      margin: auto;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #303030;
    }
  }

  &__body {
    position: absolute;
    top: calc(100% + 5px);
    opacity: 0;
    visibility: hidden;
    padding: 16px;
    right: -5px;
    //width: 210px;
    background: #FFFFFF;
    box-shadow: 0 1px 8px rgba(48, 48, 48, 0.15);
    z-index: 2;
    border-radius: 8px;
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-color: #fff;
      position: absolute;
      right: 13px;
      top: -7px;
      transform: rotate(45deg);
      box-shadow: -2px -2px 2px rgba(48, 48, 48, .05);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: -9px;
      height: 10px;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    font-size: 14px;
    color: #303030;
    transition: .3s;

    &:hover {
      color: #fda21a;
    }
  }

  &--compare {
    .option-menu {
      &__action {
        width: auto;
        height: auto;
      }

      &__body {
        top: calc(100% + 7px);
        padding: 24px;
        width: 535px;
      }

      &__list {
        counter-reset: section;
      }

      &__item {
        position: relative;
        display: flex;
        @include regular;
        font-size: 14px;
        line-height: 30px;

        &::before {
          counter-increment: section; /* Increment the value of section counter by 1 */
          content: counter(section) '. ';
          margin-right: 5px;
        }

        &.current {
          &::after {
            content: '';
            position: absolute;
            top: 12px;
            left: -12px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #10AF59;
          }

          .option-menu__value {
            @include heavy;
            color: #10AF59;
          }
        }
      }

      &__label {
        margin-left: 0;
        margin-right: 40px;
      }

      &__value {
        margin-left: auto;
        margin-right: 0;
        width: 125px;
        text-align: right;
      }
    }
  }
}