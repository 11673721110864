.compare {
  .main {
    &__head {
      align-items: flex-start;
    }

    &__info {
      margin-right: 62px;
      flex: 0 0 523px;
      max-width: 523px;

      @include media(1740) {

      }

      @include media(1365) {
        max-width: 400px;
        margin-right: 40px;
      }

      .btn-download {
        padding: 15px 32px;
      }
    }

    &__title {
      margin-bottom: 24px;
      font-size: 30px;
      line-height: 40px;
    }
  }

  .info-list {
    margin-bottom: 20px;

    &.opened {
      margin-bottom: 32px;
    }
  }

  &__offers {
    flex: 1;
    min-width: 1px;
  }
}