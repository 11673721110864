.company-feedback {
  margin-bottom: 24px;

  @include mobile {
    padding-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    background-color: #ffffff;
    border-radius: 4px;
    position: relative;

    @include desktop {
      padding: 73px 173px 40px 146px;
    }

    @include tablet {
      padding: 53px 40px 24px 130px;
    }

    @include mobile {
      padding: 72px 16px 24px;
    }

    &:before {
      content: '';
      @include sprite('icon-quote');
      position: absolute;
      top: 24px;
      left: 24px;

      @include tablet {
        transform: scale(.75);
      }

      @include mobile {
        transform: scale(.54);
        transform-origin: left top;
        top: 16px;
        left: 16px;
      }
    }
  }

  &__desc {
    line-height: 26px;
    font-size: 16px;
    color: #474A4F;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    color: #303030;
    @include heavy;
    margin-bottom: 32px;

    @include tablet-and-phone {
      margin-bottom: 24px;
    }

    @include mobile {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__option {
    &-list {

    }

    &-item {
      margin-bottom: 12px;
      display: flex;
      line-height: 20px;
      font-size: 14px;

      @include mobile {
        margin-bottom: 16px;
        line-height: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #1a67fd;

        &:hover {
          text-decoration: underline;
          color: #1a67fd;
        }
      }

      ._label {
        color: #A1A1A1;
        flex: 0 0 120px;
        max-width: 120px;

        @include tablet {
          flex: 0 0 92px;
          max-width: 92px;
        }

        @include mobile {
          flex: 0 0 90px;
          max-width: 90px;
        }
      }

      ._value {
        padding-left: 20px;

        @include mobile {
          padding-left: 10px;
        }
      }

      ._qty {
        @include heavy;
      }

      .green {
        color: #10af59;
      }

      .orange {
        color: #fda21a;
      }

      .red {
        color: $red-color;
      }
    }
  }
}