.selected-tariff {
  background-color: #f5f5f5;
  padding: 0 0 0 88px;

  &__inner {
    padding: 48px 40px 55px;
    cursor: default;
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 40px 40px 12px;
    background-color: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__close {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    ._btn {
      display: inline-block;
      min-width: 50px;
      height: 50px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px #EBEBEB;
      background-color: #ffffff;
      font-size: 16px;
      color: #3c4a51;
      @include bold;
      line-height: 17px;
      transition: .3s;
      padding: 15px 5px;
    }
  }

  &__title {
    @include heavy;
    line-height: 42px;
    font-size: 30px;
    color: #303030;
  }

  &__body {
    padding: 40px;
    background-color: #fff;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__main {
    flex: 0 0 550px;
    max-width: 550px;
    margin-right: 208px;
  }

  &__sub-title {
    @include heavy;
    font-size: 20px;
    color: #303030;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    margin-bottom: 32px;
    line-height: normal;
    font-size: 16px;
    color: #303030;
    @include heavy;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    position: relative;

    &:before {
      content: '';
      display: block;
      background: #ECECEC;
      border-radius: 4px;
      height: 6px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  &__col {
    ._input {
      display: none;

      &:checked {
        ~ ._label {
          color: #FDA219;
          @include heavy;

          &:after  {
            opacity: 1;
            visibility: visible;
          }
        }

        ~ ._line {
          opacity: 1;
        }
      }
    }

    &:first-child {
      ._label {
        &:after {
          left: 0;
        }
      }
    }

    &:last-child {
      ._label {
        &:after {
          left: auto;
          right: 0;
        }
      }
    }

    ._label {
      display: inline-block;
      font-size: 16px;
      position: relative;
      padding-top: 30px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 0;
        width: calc(100% + 20px);
        min-width: 40px;
        height: 100%;
        transform: translateX(-50%);
      }

      &:after {
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: block;
        background: #FFFFFF;
        border: 8px solid #FDA219;
        position: absolute;
        top: -9px;
        left: 50%;
        margin-left: -12px;
        opacity: 0;
        visibility: hidden;
        z-index: 2;
      }
    }

    ._line {
      position: absolute;
      display: block;
      background: #FDA219;
      border-radius: 4px;
      height: 6px;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  &__aside {
    padding-top: 47px;
  }

  &__price {
    @include heavy;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}