.tender-item {
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 4px;
  transition: .3s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);

    .tender-item__edit {
      opacity: 1;
      visibility: visible;
    }
  }

  &__inner {

  }

  &__head {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 14px;
    color: #A1A1A1;
    padding: 12px 32px;
    border-bottom: 1px solid #ECECEC;
  }

  &__options {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  &__option {
    &--margin-l {
      margin-right: 0;
      margin-left: 264px;

      &:first-child {
        margin-left: 0;
      }
    }

    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }

    ._label {
      color: #a1a1a1;
    }

    ._value {
      color: #303030;
    }
  }

  &__status {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__body {
    padding: 32px;
    position: relative;
    display: flex;

    &-left {
      flex: 1;
    }

    &-right {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  &__edit {
    line-height: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include heavy;
    color: #1967FD;
    position: absolute;
    top: 43px;
    right: 30px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    &:hover, &:active {
      color: #1967FD;
    }

    svg {
      margin-right: 7px;
    }
  }

  &__title {
    line-height: normal;
    font-size: 24px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__desc {
    line-height: 20px;
    font-size: 14px;
  }

  &__offer {
    font-size: 0;

    ._qty {
      line-height: 32px;
      font-size: 18px;
      color: #1967FD;
      vertical-align: middle;
    }

    ._new {
      margin-left: 18px;
      vertical-align: middle;
      border: 1px solid rgba(48, 48, 48, 0.15);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 8px;
      font-size: 12px;
      letter-spacing: 1px;
      color: #303030;
      @include heavy;
    }
  }

  &__condition {
    line-height: 20px;
    font-size: 14px;
    color: #303030;
    flex-shrink: 0;
    padding-top: 5px;

    b {
      @include heavy;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 14px;
    color: #303030;
    padding: 8px 32px 32px;

    .tender-item__option {
      margin-right: 72px;

      &:last-child {
        margin-right: 0;
      }

      &--margin-l {
        margin-right: 0;
        margin-left: 264px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__action {
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    padding-left: 20px;

    b {
      @include heavy;
    }

    .btn-add {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__qty {
    line-height: 32px;
    font-size: 18px;
    color: #1967FD;
  }

  &__acco {
    font-size: 16px;
    color: #1967FD;
    display: inline-block;
    padding-right: 20px;
    position: relative;

    &:before {
      content: attr(data-text-open);
      display: inline-block;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @include sprite('arrow-small-blue');
      z-index: 1;
      transition: transform .3s;
    }

    .active & {
      &:before {
        content: attr(data-text-close);
      }

      &:after {
        @include sprite('arrow-small-blue');
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__content {
    padding: 32px;
    border-top: 1px solid #ececec;
    display: none;

    &-title {
      margin-bottom: 30px;
      @include heavy;
      font-size: 18px;
      color: #303030;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__table {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    &-row {
      display: flex;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-col {
      padding-right: 20px;

      &:last-child {
        padding-right: 0;
      }

      &._col-1 {
        flex: 0 0 41.4%;
        max-width: 41.4%;
      }

      &._col-2 {
        flex: 0 0 20.2%;
        max-width: 20.2%;
      }

      &._col-3 {
        flex: 0 0 13.3%;
        max-width: 13.3%;
      }

      &._col-4 {
        flex: 0 0 18.5%;
        max-width: 18.5%;
      }

      &._col-5 {
        margin-left: auto;
        flex-shrink: 0;

        .btn-comment {
          margin: -7px 0;
        }
      }
    }

    &-head {
      line-height: 20px;
      font-size: 14px;
      color: #A1A1A1;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-body {
      line-height: 20px;
      font-size: 14px;
      color: #303030;
    }
  }

  &--adaptiv {
    .tender-item {
      &__head {
        @include tablet {
          position: relative;

          .tender-item {
            &__options {
              margin: -10px;
            }

            &__option {
              flex: 0 0 50%;
              max-width: 50%;
              margin: 0;
              padding: 10px;

              &:nth-child(even) {
                text-align: right;
              }
            }
          }
        }

        @include mobile {
          display: block;
          padding: 16px;
          position: relative;

          &-action {
            position: absolute;
            bottom: 16px;
            right: 16px;
          }

          .tender-item {
            &__options {
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            &__option {
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0;
              }

              ._inline {
                display: block;
              }
            }
          }

          .status-block {
            text-align: left;
            margin-bottom: 7px;
          }
        }
      }

      &__status {
        @include tablet {
          position: absolute;
          bottom: 20px;
          right: 24px;
        }
      }

      &__body {
        @include tablet {
          padding: 24px 24px 16px;
        }

        @include mobile {
          padding: 24px 16px;
        }
      }

      &__title {
        @include tablet-and-phone {
          color: #1967fd;
        }

        @include mobile {
          margin-bottom: 26px;
        }
      }

      &__foot {
        @include tablet {
          padding: 0 24px 24px;
          align-items: flex-end;
        }

        @include mobile {
          display: block;
          padding: 0 16px 16px;
        }

        .tender-item {
          &__options {
            @include tablet-and-phone {
              display: block;
            }
          }

          &__option {
            margin-right: 0;
            display: flex;

            @include desktop {
              flex: 0 0 50%;
              max-width: 50%;
              padding-right: 20px;
            }

            @include tablet {
              &:first-child {
                margin-bottom: 32px;

                &:last-child {
                  margin-bottom: 0;
                }
              }

              &:last-child {
                margin-top: 24px;

                &:first-child {
                  margin-top: 0;
                }
              }
            }

            @include mobile {
              display: block;

              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            ._label {
              margin-right: 8px;
            }
          }
        }
      }

      &__action {
        @include mobile {
          display: none;
        }
      }
    }
  }
}