.tariff-item {
  height: 100%;

  &__inner {
    border: 2px solid #fff;
    padding: 14px;
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__head {
    border-bottom: 1px solid #ECECEC;
    padding: 8px 0 24px;
  }

  &__title {
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    @include heavy;
  }

  &__body {
    padding: 24px 0;
  }

  &__list {
    line-height: 20px;
    font-size: 14px;
    color: #303030;

    li {
      padding-left: 38px;
      position: relative;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        @include sprite('icon-list');
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__foot {
    border-top: 1px solid #ECECEC;
    padding-top: 24px;
    margin-top: auto;
  }

  &__price {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 1px;
    color: #303030;
    margin-bottom: 24px;
    @include heavy;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__action {
    ._btn {
      color: #fda219;
    }
  }

  &.active {
    .tariff-item {
      &__inner {
        border: 2px solid #FDA219;
      }
    }
  }
}