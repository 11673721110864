.advanced-search {
  &__inner {

  }

  &__section, .form-group {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    > .control-label {
      font-size: 16px;
      color: #303030;
      @include heavy;
      margin-bottom: 24px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__head {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 16px;
    color: #303030;
    @include heavy;
  }

  &__caption {
    font-size: 16px;
    color: #303030;
    @include heavy;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__body {

  }

  &__row {
    @include no-mobile {
      display: flex;
      margin: -16px;
      flex-wrap: wrap;
    }

    &--type-2 {
      @include no-mobile {
        margin: -8px -16px;
      }

      .advanced-search__col {
        @include no-mobile {
          padding: 8px 16px;
        }

        @include desktop {
          &:nth-child(3n+1) {
            flex: 0 0 31.5%;
            max-width: 31.5%;
          }

          &:nth-child(3n+2) {
            flex: 0 0 40%;
            max-width: 40%;
          }

          &:nth-child(3n+3) {
            flex: 0 0 28.5%;
            max-width: 28.5%;
          }
        }
      }
    }
  }

  &__fields {
    @include no-mobile {
      display: flex;
      margin: -16px;
      flex-wrap: wrap;
    }

    > label, > .form-group {
      @include no-mobile {
        padding: 16px;
        margin: 0;
      }

      @include desktop {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }

      @include tablet {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include mobile {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .check-box {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--type-2 {
      @include no-mobile {
        margin: -8px -16px;
      }

      .advanced-search__col, & > label, & > .form-group {
        @include no-mobile {
          padding: 8px 16px;
        }

        @include desktop {
          &:nth-child(3n+1) {
            flex: 0 0 31.5%;
            max-width: 31.5%;
          }

          &:nth-child(3n+2) {
            flex: 0 0 40%;
            max-width: 40%;
          }

          &:nth-child(3n+3) {
            flex: 0 0 28.5%;
            max-width: 28.5%;
          }
        }
      }
    }

    &--type-3 {
      max-width: 782px;

      @include no-mobile {
        margin: -8px -16px;
      }

      .advanced-search__col, & > label, & > .form-group {
        @include no-mobile {
          padding: 8px 16px;
        }

        @include desktop {
          &:nth-child(2n+1) {
            flex: 0 0 46%;
            max-width: 46%;
          }

          &:nth-child(2n+2) {
            flex: 0 0 54%;
            max-width: 54%;
          }
        }
      }
    }
  }

  &__col {
    @include no-mobile {
      padding: 16px;
    }

    @include desktop {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }

    @include tablet {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include mobile {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .check-box {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}