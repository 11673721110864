.filter {
  margin-bottom: 24px;

  @include tablet {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 10px 10px 10px 24px;
    background-color: #ffffff;
    border-radius: 4px;

    @include no-mobile {
      display: flex;
      align-items: center;
    }

    @include mobile {
      padding: 16px;
    }
  }

  &__head {
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 24px;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 96px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    font-size: 16px;
    color: #303030;
    margin-right: 23px;

    @include mobile {
      margin-right: 8px;
    }
  }

  &__select {
    margin-right: 20px;
    font-size: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__body {
    @include no-mobile {
      margin-left: auto;
      padding-left: 20px;
    }
  }

  &__action {
    .btn {
      @include desktop {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }

      @include mobile {
        width: 100%;
      }

      &-outline-secondary {
        @include desktop {
          padding: 15px 30px;
        }
      }

      &-primary {
        padding: 15px 40px;
      }
    }
  }

  &--lk {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}