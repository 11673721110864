.participate-block {
  &__inner {
    display: flex;
  }

  &__left {
    flex: 1;
  }

  &__right {
    margin-left: auto;
    padding-left: 20px;
    flex-shrink: 0;
  }

  &__action {
    .btn {
      min-width: 218px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}