.home {
  &-slider {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;

    @include tablet-and-phone {
      background-size: cover;
    }
    @include mobile {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(450deg, rgba(8, 8, 8, 0.7) -3.43%, rgba(0, 0, 0, 0) 112.04%);
      }
    }

    &__inner {
      position: relative;
      display: flex;
      flex-direction: column;

      @include desktop {
        min-height: 635px;
        padding: 102px 0 64px;
      }

      @include tablet {
        min-height: 460px;
        padding: 45px 0 56px;
      }

      @include mobile {
        min-height: 401px;
        padding: 23px 0 33px;
      }
    }

    &__title {
      display: block;
      @include heavy;
      max-width: 810px;
      margin-bottom: 20px;

      @include desktop {
        font-size: 50px;
        line-height: 64px;
      }

      @include tablet {
        line-height: 52px;
        font-size: 40px;
      }

      @include mobile {
        line-height: 36px;
        font-size: 24px;
        color: #FFFFFF;
      }
    }

    &__digits {
      margin-top: auto;
      display: flex;

      @include no-mobile {
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @include mobile {
        flex-direction: column;
      }
    }

    &__digit {
      @include no-mobile {
        text-align: center;
      }

      @include mobile {
        display: flex;
        align-items: center;

        &:first-child {
          order: 10;
        }
      }

      &_title {
        display: block;
        line-height: 1;
        @include heavy;

        @include desktop {
          margin-bottom: 5px;
          font-size: 72px;
        }

        @include tablet {
          font-size: 50px;
          margin-bottom: 12px;
        }

        @include mobile {
          line-height: 52px;
          font-size: 26px;
          min-width: 85px;
          flex-shrink: 0;
        }
      }

      &_text {
        @include regular;

        @include desktop {
          font-size: 18px;
          line-height: 22px;
        }

        @include tablet {
          font-size: 16px;
          line-height: normal;
        }

        @include mobile {
          line-height: 15px;
          font-size: 14px;
          padding-left: 10px;
          flex: 1;
        }
      }
    }
  }

  &-static {
    p {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 0;
      max-width: 1050px;

      @include mobile {
        line-height: 26px;
        font-size: 14px;
      }
    }
  }

  &-tenders {
    &__list {
      @include no-mobile {
        display: flex;
        flex-wrap: wrap;
        margin: -7px;
      }

      @include tablet {
        padding-top: 57px;
      }

      @include mobile {
        padding-top: 18px;
      }
    }

    &__item {
      @include no-mobile {
        padding: 7px;
      }

      @include desktop {
        flex: 0 0 20%;
        max-width: 20%;
      }

      @include tablet {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }

      @include mobile {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      display: block;
      min-height: 230px;
      border: 1px solid #EFEFEF;
      border-radius: 4px;
      position: relative;

      @include no-mobile {
        text-align: center;
      }

      @include mobile {
        padding: 33px 30px 33px 94px;
        min-height: 100px;
      }

      &:hover {
        border-color: transparent;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

        .home-tenders__img {
          display: none;

          &--hover {
            display: inline;
          }
        }
      }
    }

    &__img {
      @include no-mobile {
        padding-top: 60px;
      }

      @include mobile {
        position: absolute;
        top: 24px;
        left: 24px;
        max-width: 50px;
        max-height: 50px;
      }

      &--hover {
        display: none;
      }
    }

    &__name {
      line-height: 20px;
      font-size: 14px;

      @include no-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        height: 40px;
      }
    }

    .home-section__header {
      margin-bottom: 58px;
    }
  }

  &-section {
    @include desktop {
      margin: 62px 0 120px;
    }

    @include tablet {
      padding: 38px 0;
    }

    @include mobile {
      padding: 20px 0;

      &:first-child {
        padding-top: 32px;
      }
    }

    &__inner {
      position: relative;
    }

    &:last-child {
      @include desktop {
        margin-bottom: 40px;
      }
      @include tablet {
        padding-bottom: 16px;
      }

      @include mobile {
        padding-bottom: 0;
      }
    }

    &__header {
      margin-bottom: 78px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        margin-bottom: 40px;
      }

      @include mobile {
        margin-bottom: 32px;
      }

      &_title {
        font-size: 40px;
        line-height: 47px;

        @include mobile {
          margin-bottom: 0px;
          line-height: 34px;
          font-size: 26px;
        }
      }

      &--small {
        margin-bottom: 40px;

        @include mobile {
          margin-bottom: 24px;
        }
      }

      &--tabs {
        overflow: hidden;

        @include desktop {
          padding-right: 270px;
        }

        @include tablet {
          height: 47px;
        }

        @include mobile {
          height: 68px;
        }
      }
    }

    &__tabs {
      display: flex;
      position: absolute;

      @include desktop {
        right: 0;
        top: 7px;
      }

      @include tablet-and-phone {
        left: 0;
        top: 84px;
      }

      @include mobile {
        right: 0;
      }
    }

    &__tab {
      height: 33px;
      min-width: 129px;
      padding: 0 10px;
      line-height: 30px;
      border: 1px solid #e8e8e8;
      border-left: 0;
      font-size: 12px;
      letter-spacing: 1px;
      @include bold;
      text-transform: uppercase;

      @include tablet {
        height: 40px;
        line-height: 38px;
      }

      &:first-child {
        border-left: 1px solid #e8e8e8;
      }

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      &.active {
        background: #1967FD;
        color: #fff;
      }

      @include mobile {
        height: 39px;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  &-how {
    &__list {
      @include no-mobile {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
      }

      @include desktop {
        justify-content: space-between;

      }

      @include tablet {
        flex-direction: column;
        padding: 15px 0;
      }
    }

    &__col {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -135px;
        top: 7px;
        @include sprite('home-how-arrow');
        z-index: 1;

        @include mobile {
          right: auto;
          left: 0;
          @include sprite('home-how-arrow-mobile');
          top: calc(100% + 15px);
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      @include tablet {
        flex: 0 0 50%;
        max-width: 60%;
        margin: -10px 0;

        &:nth-child(even) {
          margin-left: auto;
          min-width: 293px;

          &:after {
            transform: rotate(135deg) scale(1.2);
            right: auto;
            left: 6px;
            bottom: -85px;
            top: auto;
          }
        }

        &:nth-child(3) {
          padding-left: 65px;
          margin-top: 70px;
        }

        &:after {
          transform: rotate(21deg) scale(1.2);
          right: 19px;
          bottom: 62px;
          top: auto;
        }
      }

      @include mobile {
        margin-bottom: 48px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__title {
      font-size: 20px;
      display: block;
      line-height: 23px;
      letter-spacing: 2px;
      margin-bottom: 38px;
      @include heavy;

      @include tablet {
        font-size: 20px;
        letter-spacing: 2px;
        color: #303030;
        margin-bottom: 39px;
      }

      @include mobile {
        font-size: 16px;
        letter-spacing: 2px;
        line-height: normal;
        margin-bottom: 25px;
      }
    }

    ul {
      list-style: none;
    }

    li {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
      padding-left: 19px;
      position: relative;

      @include mobile {
        font-size: 14px;
        line-height: 17px;
        padding-left: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        @include align(false, true);
        background: #FDA21A;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        display: block;

        @include mobile {
          width: 5px;
          height: 5px;
          transform: none;
          top: 6px;
        }
      }
    }
  }

  &-demo {
    &__grid {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &__col {
      &--left {
        width: 340px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--right {
        width: 765px;
      }
    }

    &__navigation {
      &-item {
        padding: 35px 0 40px;
        border-bottom: 1px solid #ECECEC;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0 none;
          padding-bottom: 0;
        }

        a {
          color: #A1A1A1;
          @include heavy;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.01em;

          &.active {
            color: #303030;
            padding-left: 20px;
            position: relative;
            display: block;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              @include align(false, true);
              background: #FDA21A;
              border-radius: 100%;
              width: 6px;
              height: 6px;
              display: block;
            }
          }
        }
      }
    }

    &__video {
      background: #FBFBFB;
      padding: 55px 42px 44px;

      @include desktop {
        padding: 38px 40px 40px;
      }

      &_text {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 30px;
        max-width: 200px;
      }

      &_button {
        height: 48px;
        line-height: 48px;
        padding-left: 65px;
        font-size: 12px;
        text-transform: uppercase;
        @include heavy;
        position: relative;
        letter-spacing: 0.08em;

        &:before {
          content: '';
          z-index: 1;
          height: 48px;
          width: 48px;
          position: absolute;
          left: 0;
          display: block;
          top: 0;
          background: #fff;
          border-radius: 100%;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
        }

        &:after {
          content: '';
          position: absolute;
          z-index: 2;
          @include sprite('video-play');
          @include align(false, true);
          left: 20px;
        }
      }
    }

    &__text {
      font-size: 16px;
      line-height: 26px;
      display: block;
      margin-bottom: 32px;
    }

    &__tabs {
      @include tablet-and-phone {
        display: none;
      }
    }

    &__content {
      @include desktop {
        display: none;
      }

      h3 {
        font-size: 24px;
        letter-spacing: 0.01em;
        margin-bottom: 24px;
        margin-top: 56px;
        @include heavy;

        @include mobile {
          line-height: 24px;
          font-size: 18px;
          color: #303030;
          margin-bottom: 16px;
          margin-top: 32px;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      p {
        line-height: 26px;
        font-size: 16px;
        color: #474A4F;

        @include mobile {
          line-height: 26px;
          font-size: 14px;
        }
      }
    }
  }

  &-news {
    .section-action {
      margin-top: 50px;
    }

    .home-section__header {
      margin-bottom: 38px;
    }

    &__grid {
      @include desktop {
        display: flex;
        margin: 0 -12px;
      }
    }

    &__main {
      @include desktop {
        padding: 0 12px;
        flex: 0 0 50%;
        max-width: 50%;

        .news-item {
          height: 100%;

          &__inner {
            height: 100%;
          }
        }
      }

      @include tablet-and-phone {
        margin-bottom: 24px;
      }
    }

    &__right {
      position: relative;

      @include desktop {
        padding: 0 12px;
        flex: 0 0 50%;
        max-width: 50%;
        height: 586px;
      }
    }

    .slick {
      &-arrow {
        position: absolute;
        top: -86px;
        right: 0;
        height: 44px;
        width: 44px;
        border-radius: 100%;
        border: 1px solid #EEEEEE;
        font-size: 0;

        &:before {
          content: '';
          @include sprite('video-play');
          @include align(true, true);
          transform: translate(-50%, -50%) rotate(90deg);
        }

        &.slick-disabled {
          &:before {
            @include sprite('video-play-disabled');
          }
        }
      }

      &-prev {
        right: 52px;

        &:before {
          transform: translate(-50%, -50%) rotate(270deg);
        }
      }

      &-list {
        height: 586px !important;;
      }
    }

    &__slide {
      @include desktop {
        padding-bottom: 20px;
      }

      @include tablet-and-phone {
        margin-bottom: 24px;
        display: none;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          display: block;
        }
      }
    }
  }
}