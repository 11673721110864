.article {
  &__gallery {
    margin-bottom: 70px;

    @include tablet {
      margin-bottom: 48px;
    }

    @include mobile {
      margin-bottom: 32px;
      padding-bottom: 24px;
      position: relative;
    }

    .slick {
      &-arrow {
        background: #333333;
        opacity: 0.7;
        height: 49px;
        width: 49px;
        font-size: 0;
        position: absolute;
        top: 50%;
        @include align(false, true);
        left: 18px;
        z-index: 1;

        &:before {
          content: '';
          @include sprite('slider-article-arrow');
          @include align(true, true);
        }
      }

      &-next {
        right: 18px;
        left: auto;

        &:before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      &-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        line-height: 1;
        margin: 0;

        li {
          padding: 2px;
          margin: 0;
          line-height: 1;

          &:before {
            display: none;
          }

          &:last-child:not(.slick-active), &:first-child:not(.slick-active) {
            button {
              width: 4px;
              height: 4px;
            }
          }

          button {
            width: 8px;
            height: 8px;
            background-color: #E4E4E4;
            border-radius: 50%;
            font-size: 0;
            transition: .3s;
          }

          &.slick-active {
            button {
              background-color: #faa21e;
            }
          }
        }
      }

      &-slide {
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}