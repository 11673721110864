* {
  outline: none !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  min-width: 320px;
  @include regular;
  font-size: 14px;
  color: $base-color;
  background-color: #F5F5F5;

  &.popup-opened {
    overflow: hidden;
    overflow-scrolling: touch;

    @include mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  //&.search-opened {
  //  overflow: hidden;
  //  overflow-scrolling: touch;
  //
  //  @include mobile {
  //    position: fixed;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //  }
  //}

  &.landing {
    background: #fff;
  }
}

input {
  -webkit-appearance: none;
  border-radius: 0;
  @include regular;
  font-size: 14px;

  &::-ms-clear {
    display: none !important;
  }

  &::-ms-reveal {
    display: none !important;
  }
}

button {
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  @include regular;
  font-size: 14px;
}

main {
  display: block;
}

.hidden-lg {
  @include desktop {
    display: none !important;
  }
}

.hidden-md {
  @include tablet {
    display: none !important;
  }
}

.hidden-xs {
  @include mobile {
    display: none !important;
  }
}

.text-orange {
  color: #fda21a;
}

.link {

}

.light {
  @include light;
}

.regular {
  @include regular;
}

.medium {
  @include medium;
}

.semi-bold {
  @include semi-bold;
}

.bold {
  @include bold;
}

.heavy {
  @include heavy;
}

.black {
  @include black;
}

.italic {
  @include italic;
}