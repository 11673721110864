.tabs-block {
  &__inner {

  }

  &__head {
    border-bottom: solid 1px #E0E0E0;
    display: flex;
  }

  &__list {
    flex: 1;
  }

  &__btn {
    position: relative;
    display: inline-block;
    @include heavy;
    line-height: 1;
    padding-bottom: 28px;
    font-size: 14px;
    text-transform: uppercase;
    color: $secondary-color;
    transition: .3s;
    margin-right: 48px;

    @include tablet {
      margin-right: 30px;
    }

    @include mobile {
      font-size: 12px;
      padding-bottom: 20px;
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #3c4a51;

      &:before {
        background-color: $primary-color;
        left: 0;
        right: 0;
      }
    }

    &:hover:not(.active) {
      color: $blue-color;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      height: 5px;
      border-radius: 5px;
      background-color: transparent;
      left: 50%;
      right: 50%;
      transition: .3s;
      margin-left: 0;
    }

    ._qty {
      top: -.5em;
      font-size: 80%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
      margin-left: 3px;

      @include mobile {
        margin-left: 0;
      }
    }
  }

  &__action {
    margin-left: auto;

    .btn-compare {
      margin-top: -14px;
    }
  }

  &__body {
  }

  &__content {
    display: none;
    padding-top: 20px;

    &:first-child {
      display: block;
    }
  }

  &--link {
    .tabs-block {
      &__body {
        padding-top: 40px;

        @include mobile {
          padding-top: 24px;
        }
      }

      &__content {
        padding-top: 0;
      }
    }
  }

  &--lk {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .tabs-block__content {
      padding-top: 40px;

      @include mobile {
        padding-top: 24px;
      }
    }
  }
}
