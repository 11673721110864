.dropdown {
  position: relative;

  &__header {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    height: 50px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #ffffff;

    &--placeholder {
      .dropdown__text {
        color: #A1A1A1;
      }
    }

    &:hover {
      border-color: #C0C0C0;
    }

    //.opened & {
    //  border-color: $blue-color;
    //  border-radius: 2px 2px 0 0;
    //}
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    @include sprite('arrow-small');
    z-index: 1;

    //.opened & {
    //  @include sprite('arrow-small-blue');
    //  transform: translateY(-50%) rotate(180deg);
    //}
  }

  &__text {
    display: block;
    padding: 0 37px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 48px;
  }

  &__box {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: #ffffff;
    max-height: 300px;
    border: 1px solid $blue-color;
    border-top: 0;
    overflow: auto;
    z-index: 10;
    border-radius: 0 0 2px 2px;
    max-width: 100%;

    //.opened & {
    //  z-index: 2;
    //}

    div {
      display: block;
      white-space: nowrap;
    }
  }

  &__list {
    display: block;
    white-space: nowrap;

    span {
      border-bottom: 1px solid #E0E0E0;
      display: block;
      padding: 0 20px;

      &:hover {
        background-color: #F5F5F5;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    a {
      display: block;
      color: #000;
      text-transform: none;
      line-height: 50px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: #000;
      }
    }
  }

  &--inline {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    .dropdown {
      &__header {
        border: 0 none;
        height: auto;
        background-color: transparent;
      }

      &__text {
        padding-left: 0;
        padding-right: 21px;
        line-height: 18px;
        color: $blue-color;
      }

      &__arrow {
        right: 0;
        @include sprite('arrow-small-blue');
        transform: translateY(-50%);
      }

      &__box {
        margin-top: 6px;
        max-width: none;
        background: #FFFFFF;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 16px;
        border: 0;
      }

      &__list {
        display: block;
        white-space: nowrap;

        span {
          border-bottom: 0;
          display: block;
          padding: 0;
          margin-bottom: 15px;

          &:hover {
            background: transparent;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          display: block;
          color: #000;
          text-transform: none;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            color: $blue-color;
          }
        }
      }
    }

    &.opened {
      .dropdown {
        &__arrow {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &__empty {
    font-size: 14px;
    text-align: center;
    color: #A1A1A1;
    min-height: 50px;
    line-height: 50px;
    padding: 0 5px;
    letter-spacing: 0.02em;
    white-space: normal !important;

    @include tablet-and-phone {
      line-height: 20px;
      min-height: 0;
      padding: 10px 5px;
    }

    button {
      color: #1966FD;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.opened {
    .dropdown {
      &__header {
        border-color: $blue-color;
        border-radius: 2px 2px 0 0;
      }

      &__arrow {
        @include sprite('arrow-small-blue');
        transform: translateY(-50%) rotate(180deg);
      }

      &__box {
        z-index: 2;
      }

      &--inline {
        .dropdown {
          &__arrow {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}