.date-period {
  @include no-mobile {
    display: flex;
  }

  @include mobile {
    margin: 0 -10px;
  }

  &__item {
    position: relative;

    @include no-mobile {
      width: 194px;
      margin-right: 21px;

      &:before {
        content: '';
        display: block;
        width: 13px;
        height: 1px;
        background-color: #303030;
        position: absolute;
        top: 50%;
        right: -17px;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-right: 0;

        &:before {
          display: none;
        }
      }
    }

    @include mobile {
      margin-bottom: 6px;
    }
  }
}