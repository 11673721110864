.form {
  &-group {
    position: relative;

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    textarea.form-control {
      height: 182px;
      padding: 20px;

      @include mobile {
        height: 160px;
        padding: 17px 20px;
      }
    }

    &--select-error {
      .help-block {
        bottom: 63px;
        right: 0;
      }

      .dropdown__header {
        border-color: $red-color;

        &:hover {
          border-color: $red-color;
        }
      }
    }

    &--search {
      ._btn {
        position: absolute;
        top: 53%;
        left: 20px;
        transform: translateY(-50%);
        opacity: .5;
        transition: .3s;

        &:hover {
          opacity: .8;
        }
      }

      .form-control {
        padding-left: 53px;
      }
    }

    &--date {
      .form-control {
        color: #303030;
        padding-right: 40px;
        background: #fff url($images_dir + "icon-date.svg") 88% center no-repeat;

        @include mobile {
          background-position: 93% center;
        }

        &:focus, &:active {
          background: #fff url($images_dir + "icon-date-blue.svg") 88% center no-repeat;

          @include mobile {
            background-position: 93% center;
          }
        }

        @include desktop {
          @include placeholder {
            font-size: 14px;
            color: #A1A1A1;
            opacity: 1;
          }
        }

        @include tablet {
          @include placeholder {
            opacity: 0;
          }
        }

        @include mobile {
          @include placeholder {
            opacity: 1;
            color: #a1a1a1;
          }
        }
      }

      .help-block {
        top: 100%;
        bottom: auto;
        right: 0;
      }
    }

    &--disabled {
      .dropdown__header {
        border-color: #E0E0E0;
        background-color: #ECECEC;
        cursor: default;
        pointer-events: none;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: transparent;
          z-index: 5;
        }

        &:hover {
          border-color: #E0E0E0;
        }
      }
    }

    .control-label {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 16px;
      font-size: 16px;
      @include bold;
      letter-spacing: 0.02em;
    }

    &.has-error {
      .form-control {
        border-color: $danger-color;

        &:active, &:focus, &:hover {
          border-color: $danger-color;
        }
      }
    }

    .help-block {
      position: absolute;
      bottom: 13px;
      right: 15px;
      font-size: 12px;
      color: $danger-color;
      z-index: 1;

      &:hover {
        .help-block__body {
          opacity: 1;
          visibility: visible;
        }
      }

      &__head {
        padding: 5px;
        cursor: pointer;

        &:before {
          content: '';
          @include sprite('icon-error');
          background-color: transparent;
          width: 14px;
          height: 14px;
          display: none;
          position: absolute;
          background-size: 802px 529px;
          background-position: -388px -271px;
          top: 57%;
          right: 49px;
        }
      }

      &__body {
        width: 170px;
        position: absolute;
        color: #fff;
        background-color: $red-color;
        padding: 16px;
        font-size: 14px;
        line-height: 20px;
        z-index: 10;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        right: -32px;
      }
    }

    ._show-password {
      position: absolute;
      bottom: 14px;
      right: 20px;
      width: 26px;
      height: 21px;

      &::before {
        content: '';
        @include sprite('eyeShow');
      }

      &.showed {
        &::before {
          @include sprite('eyeHide');
        }
      }
    }

    &--autocomplete {

      .autocomplete-select {
        position: relative;
      }

      &.form-group {
        ._select {
          position: absolute;
          z-index: -1;
          visibility: hidden;
        }

        ._dropdown {
          position: relative;

          &__box {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 100;
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            max-height: 300px;
            overflow: auto;
            border-radius: 0 0 2px 2px;
            border: 1px solid $blue-color;
            border-top: 0;
            background-color: #ffffff;
            display: none;
          }

          &__item {
            border-bottom: 1px solid #E0E0E0;
            padding: 0 30px 0 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 48px;
            transition: .3s;

            &:hover {
              background-color: #f5f5f5;
            }
          }

          &.opened {
            ._dropdown__box {
              display: block;
            }

            ._input {
              border: 1px solid $blue-color;
            }
          }

          &--multiple {
            .form-control {
              position: relative;
              display: flex;
              justify-content: space-between;
              padding: 0;
              background-color: #fff;
              transition: .3s;

              &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                height: 70%;
                width: 1px;
                background-color: #e0e0e0;
                transform: translate(-50%, -50%);
                transition: .25s;
              }
            }

            ._text-container,
            ._input {
              box-sizing: border-box;
              padding: 0 20px;
              width: 50%;
            }

            ._text-container {
              display: flex;
              align-items: center;
            }

            ._qty {
              margin-left: 10px;
              padding: 5px 8px;
              box-sizing: border-box;
              @include heavy;
              font-size: 11px;
              line-height: 1;
              letter-spacing: 1px;
              color: #303030;
              background: #e7e7e7;
              border-radius: 4px;
            }

            ._input {
              border: 0 !important;
            }

            ._dropdown {
              &__box {
                border: 0;
              }

              &__item {
                position: relative;
                display: block;
                padding: 0 20px 0 52px;
                box-sizing: border-box;
                width: 100%;
              }
            }

            ._checkbox {
              position: absolute;
              z-index: -1;
              opacity: 0;
              visibility: hidden;

              &:checked {
                & + ._checkbox-pseudo::after {
                  opacity: 1;
                }
              }

              &-pseudo {
                display: block;
                margin-right: 10px;
                width: 24px;

                &:hover {
                  color: #000;
                  background-color: #F5F5F5;
                }

                &:before {
                  content: '';
                  position: absolute;
                  z-index: 1;
                  top: 12px;
                  left: 20px;
                  width: 24px;
                  height: 24px;
                  background: #fff;
                  border: 1px solid #E0E0E0;
                  border-radius: 4px;
                  transition: .3s;
                }

                &:after {
                  content: '';
                  position: absolute;
                  z-index: 1;
                  top: 23px;
                  left: 26px;
                  transform: translateY(-50%);
                  @include sprite('checked-blue');
                  opacity: 0;
                }
              }
            }

            &._dropdown {
              &.opened {
                ._dropdown__box {
                  border: 1px solid $blue-color;
                  border-top: 0;
                }

                .form-control {
                  border-color: #1967fd;

                  &::before {
                    background-color: #1967fd;
                  }
                }
              }
            }
          }
        }

        ._error-result {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 100;
          min-width: 100%;
          min-height: 50px;
          max-width: 100%;
          max-height: 300px;
          display: flex;
          align-items: center;
          padding: 0 30px 0 20px;
          text-overflow: ellipsis;
          border-radius: 0 0 2px 2px;
          border: 1px solid #fd1a35;
          border-top: 0;
          background-color: #ffffff;
          overflow: auto;
        }
        .form-error {
          ._input {
            border-color: #fd1a35;
          }
        }
      }
    }
  }

  &-control {
    width: 100%;
    display: block;
    height: 50px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 0 20px;
    font-size: 14px;
    transition: .3s;
    resize: none;

    &:hover {
      border-color: #C0C0C0;
    }

    &:active, &:focus {
      border-color: $blue-color;
    }

    @include placeholder {
      color: #A1A1A1;
      font-size: 14px;
    }

    &:disabled {
      background: #ECECEC;
      border: 1px solid #E0E0E0;

      &:active, &:focus, &:hover {
        background: #ECECEC;
        border: 1px solid #E0E0E0;
      }
    }
  }
}

//-----Styles for error-block-----//

.form-group.has-error .help-block__head::before {
  display: block;
}

.form-group .help-block.help-block-error {
  position: absolute;
  color: #fff;
  background-color: #fd1a35;
  line-height: 20px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  right: -32px;
  width: 190px;
  padding: 16px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  top: 90%;
  min-height: 75%;
}

.has-error .check-label::before {
  border: 1px solid #fd1a35 !important;
}

.has-error .check-box .help-block.help-block-error {
  display: block;
  opacity: 1;
  visibility: visible;
  background: none !important;
  color: #fd1a35;
  min-width: 300px;
  left: -18px;
  transition: 0s;
}

.form-control.has-error, .help-block.help-block-error {
  font-size: 14px;
  transition: 0.3s;
}

.form-group.has-error .help-block__head:hover .help-block {
  opacity: 1;
  visibility: visible;
}