.check {
  &-box {
    position: relative;
    display: block;
  }
}

[type="checkbox"] {
  display: none;

  &:checked ~ .check-label {
    &:after {
      opacity: 1;
    }
  }

  ~ .check-label {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    line-height: 24px;
    margin: 0;
    cursor: pointer;
    vertical-align: top;
    font-size: 16px;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      transition: .3s;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 6px;
      transform: translateY(-50%);
      background: transparent;
      @include sprite('checked');
      opacity: 0;
    }

    &:hover {
      &:before {
        border-color: #C0C0C0;
      }
    }
  }
}