.popup-user {
  &__inner {

  }



  textarea.form-control {
    line-height: 22px;
    font-size: 14px;
    padding-right: 30px;

    @include placeholder {
      color: #A1A1A1;
    }
  }

  &__btn {
    margin-top: 16px;
    font-size: 10px;
    letter-spacing: 1px;
    color: #1967FD;
    @include heavy;

    &:before {
      content: '';
      @include sprite('add-button', inline-block);
      margin-right: 7px;
      vertical-align: middle;
    }
  }
}