.compare-params {
  margin-bottom: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #FFFFFF;

  &__body {
    padding: 24px;
  }

  &__option {
    &-title {
      @include heavy;
      line-height: 30px;
      font-size: 20px;
      color: #303030;
      margin-bottom: 20px;
    }

    &-item {
      margin-bottom: 12px;

      @include no-mobile {
        display: flex;
        margin-bottom: 16px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      ._total {
        font-size: 20px;
        @include heavy;
      }

      ._label {
        font-size: 16px;
        color: #303030;

        @include no-mobile {
          font-size: 16px;
          position: relative;
          flex: 1;
          margin-right: 5px;

          span {
            display: inline-block;
            background-color: #fff;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            z-index: 1;
            padding-right: 10px;
          }

          &:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #ECECEC;
            position: absolute;
            bottom: 4px;
            right: 0;
            left: 0;
          }
        }

        @include mobile {
          font-size: 14px;
          line-height: 24px;

          span {
            &:after {
              content: ':';
            }
          }
        }
      }

      ._value {
        flex-shrink: 0;
        font-size: 16px;
        line-height: 20px;
        color: #303030;

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }

      ._bold {
        @include heavy;
      }
    }
  }

  ._btn {
    margin-top: 24px;
    @include heavy;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1967FD;

    &:hover {
      text-decoration: underline;
    }
  }
}