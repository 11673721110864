.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-add {
  width: 28px;
  height: 28px;
}
.icon-add-button {
  width: 17px;
  height: 17px;
}
.icon-arrow-pag-next {
  width: 18px;
  height: 10px;
}
.icon-arrow-pag-prev {
  width: 18px;
  height: 10px;
}
.icon-btn-arrow {
  width: 12px;
  height: 12px;
}
.icon-btn-user {
  width: 12px;
  height: 12px;
}
.icon-check {
  width: 23px;
  height: 21px;
}
.icon-close {
  width: 20px;
  height: 20px;
}
.icon-comment {
  width: 22px;
  height: 22px;
}
.icon-cup {
  width: 21px;
  height: 26px;
}
.icon-download {
  width: 18px;
  height: 18px;
}
.icon-download-arrow {
  width: 18px;
  height: 18px;
}
.icon-edit {
  width: 16px;
  height: 16px;
}
.icon-favorite {
  width: 28px;
  height: 28px;
}
.icon-filter {
  width: 15px;
  height: 14px;
}
.icon-hide {
  width: 16px;
  height: 12px;
}
.icon-more-set {
  width: 29px;
  height: 6px;
}
.icon-move {
  width: 20px;
  height: 20px;
}
.icon-nav-1 {
  width: 26px;
  height: 26px;
}
.icon-nav-2 {
  width: 26px;
  height: 28px;
}
.icon-nav-3 {
  width: 26px;
  height: 23px;
}
.icon-popup-close {
  width: 20px;
  height: 20px;
}
.icon-print {
  width: 24px;
  height: 25px;
}
.icon-remove {
  width: 16px;
  height: 18px;
}
.icon-search {
  width: 22px;
  height: 22px;
}
.icon-settings {
  width: 22px;
  height: 22px;
}
