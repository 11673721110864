.breadcrumbs {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {

  }

  &__list {
    @include no-mobile {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__item {
    line-height: 20px;
    font-size: 16px;
    padding-right: 35px;
    position: relative;

    @include mobile {
      display: inline;
      font-size: 14px;
      padding-right: 26px;
    }

    &:before {
      content: '/';
      color: #A1A1A1;
      position: absolute;
      font-size: 16px;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      padding-right: 0;

      &:before {
        display: none;
      }
    }
  }

  &__link {
    color: #A1A1A1;

    &:hover {
      text-decoration: underline;
    }
  }

  &__label {
    color: #A1A1A1;
    transition: .3s;
  }

  &--mobile {
    display: none;

    @include mobile {
      display: block;
    }
  }
}