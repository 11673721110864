.tariff-item__special{
	position: relative;
    display: inline-block;
    margin: 6px 0 0;
    padding: 14px 50px 12px 90px;
    background-color: #303030;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 716px;
    float: right;

    p{
    	margin: 0;
	    padding: 0;
	    color: #fff;
	    font-weight: 500;
	    font-size: 16px;
	    line-height: 1.4;
	    letter-spacing: .3px;

	    &:before{
	    	content: "";
		    display: block;
		    background-color: transparent;
		    background-image: url('../images/svg/sprite.svg');
		    -webkit-background-size: 972px 705px;
		    background-size: 972px 705px;
		    width: 41px;
		    height: 43px;
		    background-position: -281px -247px;
		    position: absolute;
		    top: 13px;
		    left: 22px;
	    }
    }

    &.temporary-free{
    	width: 80%;
    	min-height: 70px;

    	p{
    		margin: 8px auto;

    		&:before{
    			content: "";
			    display: block;
			    background-color: transparent;
			    background-image: url('../images/alert.png');
			    width: 36px;
			    height: 36px;
			    background-position: 36px 36px;
			    position: absolute;
			    top: 16px;
			    left: 22px;
			    background-size: auto;
    		}
    	}
    }

    @media only screen and (max-width: 1197px){
    	width: 100%;
    	padding: 14px 50px 12px 90px;

    	&.temporary-free{
    		width: 100%;
    	}
    }

    @media only screen and (max-width: 767px){
    	padding: 54px 15px 12px 15px;
    }
}

.title-tarification{
	.page-title__title{
		display: inline-block;
		float: left;
		width: auto;
	}
}

.tariff-item--gray-background{
  background-color: #f9f9f9;
  padding-left: 18px;
  padding-right: 10px;
  padding-bottom: 28px;
}

.tariff-item__users-amount {
    position: relative;
    font-size: 13px;
    color: #a1a1a1;
    text-align: center;
    padding-top: 8px;
}

.tariff-item__mobile-control {
    display: none;
    color: #2770fd;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    width: 100%;
    text-align: left;
    padding-right: 20px;
    position: relative;

    @media only screen and (max-width: 767px){
    	display: block;
    }

    &:after{
    	position: absolute;
	    content: "";
	    width: 9px;
	    height: 9px;
	    -webkit-border-radius: 2px;
	    border-radius: 2px;
	    border-left: 2px solid #2770fd;
	    border-bottom: 2px solid #2770fd;
	    -webkit-transform: rotate(
	-45deg
	);
	    -ms-transform: rotate(-45deg);
	    transform: rotate(
	-45deg
	);
		right: 0;
		top: calc(50% - 4px);
		-webkit-transition: -webkit-transform 0.4s ease;
		transition: -webkit-transform 0.4s ease;
		-o-transition: transform 0.4s ease;
		transition: transform 0.4s ease;
		transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    }

    &.active{
    	&:after{
	    	-webkit-transform: rotate(135deg);
		    -ms-transform: rotate(135deg);
		    transform: rotate(135deg);
    	}

    	~ .tariff-item__list{
    		display: block;
    	}
    }
    &.open{
    	&:after{
    		transform: rotate(135deg);
    	}
    }
}

.tariff-item__list{
	display: block;

	// @media only screen and (max-width: 767px){
	// 	padding-top: 30px;
 //    	display: none;
 //    }
}

// .change-tariff__row{
// 	@media only screen and (max-width: 767px){
// 	    margin-left: -16px;
// 	    margin-right: -23px;
// 	    max-width: 100vw;
// 	    overflow-x: scroll;
// 	    overflow-y: hidden;
// 	    padding-left: 23px;
// 	    padding-right: 23px;
// 	    padding-bottom: 70px;
// 	}
// }

.tariff-item__radio{
	-webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 18px;
        display: flex;

    [type=radio]~.radio-label{
		font-size: 14px;
	}
}

.tariff-item{
	&.active{
		.tariff-item__inner{
			border: 2px solid #313131;
    		position: relative;

    		&:before{
    			z-index: 1;
			    content: "Ваш текущий тариф";
			    text-transform: uppercase;
			    position: absolute;
			    color: #fff;
			    width: calc(100% + 4px);
			    height: 45px;
			    bottom: -60px;
			    left: -2px;
			    text-align: center;
			    font-weight: 700;
			    font-size: 13px;
			    letter-spacing: .5px;
    		}

    		&:after{
    			content: "";
			    position: absolute;
			    background-color: #000;
			    width: calc(100% + 4px);
			    height: 45px;
			    bottom: -45px;
			    left: -2px;
			    -webkit-border-bottom-left-radius: 5px;
			    border-bottom-left-radius: 5px;
			    -webkit-border-bottom-right-radius: 5px;
			    border-bottom-right-radius: 5px;
    		}
		}
	}
}

.tariff-item__price{
	font-size: 14px;
    margin-bottom: 72px;
}

.tariff-item__info-icon{
	z-index: 2;
    cursor: pointer;
    display: block;
    background-color: transparent;
    background-image: url('../images/icon_info.svg');
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -4px;
    right: 22px;

    &:hover{
    	+.tariff-item__info{
    		display: block;
    	}
    }
}

.tariff-item__info{
	position: absolute;
    display: none;
    top: 13px;
    left: 42px;
    font-size: 14px;
    line-height: 20px;
    padding-top: 11px;
    padding-left: 12px;
    padding-right: 10px;
    width: 208px;
    height: 61px;
    text-align: left;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #303030;
}

.tariff-item__foot{
    margin-top: auto;

	&--big-top-padding{
	    padding-top: 58px;
	}
}












