.section {
  &__inner {

  }

  &__body {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__action {
    margin-top: 40px;
    text-align: center;

    @include mobile {
      margin-top: 24px;
    }

    &:first-child {
      margin-top: 0;
    }

    .btn-more {
      background-color: #ffffff;

      @include no-mobile {
        min-width: 315px;
      }

      @include mobile {
        width: 100%;
      }
    }

    .btn {
      @include mobile {
        padding: 15px;
        width: 100%;
      }
    }
  }
}