.add-work {
  &__list {
    .form {
      &-group {
        .control-label {
          font-size: 14px;
          min-height: 32px;
          display: flex;
          align-items: center;
        }
      }

      &-control {
        font-size: 13px;
      }
    }

    .dropdown {
      &__text {
        font-size: 13px;
      }

      &__list a {
        font-size: 13px;
      }
    }
  }

  &__item {
    padding: 24px 0;
    border-bottom: 1px solid #ECECEC;

    &:first-child {
      padding-top: 0;
    }
  }

  &__row {
    display: flex;
  }

  &__col {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &._col-1 {
      flex: 1;
    }

    &._col-2 {
      flex: 0 0 110px;
      max-width: 110px;
    }

    &._col-3 {
      flex: 0 0 98px;
      max-width: 98px;
    }

    &._col-4 {
      flex: 0 0 120px;
      max-width: 120px;
    }

    &._col-5 {
      flex: 0 0 151px;
      max-width: 151px;
      margin-right: 50px;
    }

    &._col-6 {
      flex: 0 0 124px;
      max-width: 124px;
    }

    &._col-7 {
      flex: 0 0 112px;
      max-width: 112px;
    }
  }

  &__value {
    line-height: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }

  &__action {
    margin-top: 25px;

    ._btn {
      margin-right: 26px;
      font-size: 10px;
      line-height: 18px;
      letter-spacing: 1px;
      @include heavy;

      &:before {
        content: '';
        margin-right: 7px;
        vertical-align: middle;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        vertical-align: middle;
      }
    }

    ._add-comment {
      color: #1967FD;

      &:before {
        @include sprite('add-button', inline-block);
      }
    }

    ._remove {
      color: #A1A1A1;

      &:before {
        @include sprite('icon-remove', inline-block);
      }
    }
  }
}