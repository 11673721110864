.side-nav {
  background-color: #292929;
  position: fixed;
  left: 0;
  width: 88px;
  bottom: 0;
  top: 0;
  z-index: 10;

  &__inner {

  }

  &__main {

  }

  &__section {
    padding: 20px;
    border-bottom: 1px solid #404040;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__list {

  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      .side-nav__link {
        color: #FDA21A;
        border-color: #FDA21A;
      }
    }

    &--main {
      .side-nav__link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FDA21A;
        font-size: 31px;
        @include heavy;
        color: #fff;

        &:hover, &:active {
          background: darken(#FDA21A, 8%);
          color: #fff;
        }
      }
    }

    &--profile {
      position: relative;
      padding-top: 17px;

      &:before {
        content: '';
        @include sprite('helmet');
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }

      .side-nav__link {
        font-size: 0;
        border: 0;
        overflow: hidden;

        ._img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    &--link {
      position: relative;

      &:before {
        content: '';
        @include sprite('icon-link');
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &__link {
    display: flex;
    width: 48px;
    height: 48px;
    background-color: #333333;
    border-radius: 50%;
    overflow: hidden;
    color: #a9a9a9;
    border: 2px solid #333333;

    &:hover, &:focus {
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.75);
      color: #a9a9a9;
    }

    .icon, img, svg {
      margin: auto;
      max-width: 80%;
      max-height: 80%;
    }
  }

  &--show-in-popup {
    z-index: 99999;
  }
}