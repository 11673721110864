ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

h1, .h1 {
  margin: 0;
  @include heavy;
  font-size: 40px;
  line-height: 47px;

  @include mobile {
    font-size: 27px;
    line-height: 36px;
  }
}

h2, .h2 {
  margin: 0;
  @include heavy;
  font-size: 30px;
  line-height: 35px;

  @include mobile {
    font-size: 22px;
    line-height: 30px;
  }
}

h3, .h3 {
  margin: 0;
  @include bold;
  font-size: 22px;
  line-height: 30px;

  @include mobile {
    font-size: 18px;
    line-height: 24px;
  }
}

h4, .h4 {
  margin: 0;
  @include bold;
  font-size: 18px;
  line-height: 24px;

  @include mobile {
    font-size: 15px;
    line-height: 20px;
  }
}

p {
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 18px;
  color: #474A4F;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    font-size: 13px;
    line-height: 18px;
  }

  a {

  }
}

b, strong {
  @include bold;
}