.compare-table {
  .table-work {
    &__head {
      .table-work {
        &__row {
          padding: 0;
          line-height: 14px;
          height: 60px;

          @media (max-width: 1910px) {
            // padding-left: 20px;
          }
        }
      }
    }

    &__row {
      padding: 0;
      height: 55px;
    }

    &__col {
      margin-right: 0;
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }

      &._col-1 {

      }

      &._col-2 {
        flex: 0 0 100px;
        max-width: 100px;
      }

      &._col-3 {
        flex: 0 0 50px;
        max-width: 50px;
      }

      &._col-4 {
        flex: 0 0 24%;
        max-width: 24%;
      }

      &._col-5 {
        flex: 0 0 24%;
        max-width: 24%;
      }

      &._col-6 {
        flex: 0 0 26%;
        max-width: 26%;
      }

      &._col-7 {
        flex: 0 0 26%;
        max-width: 26%;
        text-align: right;
      }
    }
  }

  .acco-work__head {
    height: 54px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__head {
    margin-bottom: 24px;
  }

  &__title {
    font-size: 18px;
    line-height: 21px;
  }

  &__body {
    display: flex;

    .mCSB_scrollTools {
      width: 3px;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 3px;
    }
    .mCSB_outside + .mCSB_scrollTools {
      right: -40px;
    }
  }

  &__common {
    flex: 0 0 580px;
    max-width: 580px;
    margin-right: 5px;
    background-color: #fff;
    padding: 0 20px;

    @media (max-width: 1439px) {
      margin-right: 0;
      padding-right: 0;
    }

    @media (max-width: 1365px) {
      flex: 0 0 435px;
      max-width: 435px;
    }
  }

  &__aside {
    flex: 0 0 550px;
    max-width: 550px;
    margin-right: 18px;
    background-color: #fff;
    padding: 0 20px;

    @media (max-width: 1910px) {
      display: none;
    }

    @media (min-width: 1910px) {
      flex-basis: 100%;
      max-width: calc((100% - 592px) / 2);
    }

    .compare-table__item {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  &__main {
    flex: 1;
    min-width: 1px;
    padding: 0;

    @media (max-width: 1910px) {
      padding-left: 0;

      .table-work {
        &__row {
          // padding-left: 20px;
        }
      }
    }
  }

  &__slider {
    position: relative;
    max-height: 200px;
    overflow: hidden;

    &.slick-initialized {
      height: auto;
      overflow: visible;
    }
  }

  &__item {
    padding: 0 5px;
    background-color: transparent;
  }

  &__item-wrap {
    padding: 0 20px;
    background-color: #ffffff;
  }

  &__result {
    margin-left: -5px;

    .option-menu__body {
      border-radius: 8px;

      &:before {
        right: 22px;
      }
    }
  }
}

.compare-table__item--collapse {
  width: 0 !important; // переопределяет стили slick-slider`a
  position: relative;

  .compare-table__item-wrap {
    display: none;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;

    margin: 0;
    margin-left: 3px;
    background: #DDDDDD;
    border-radius: 6px;
    margin-right: 5px;
    cursor: pointer;
  }
}
