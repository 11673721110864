.edit-logo {
  &__inner {
  }

  &__head {
    margin-bottom: 32px;
  }

  &__title {
    font-size: 16px;
    color: #303030;
    @include heavy;
  }

  &__main {
    display: flex;
  }

  &__img {
    position: relative;
    flex: 0 0 123px;
    max-width: 123px;
    margin-right: 47px;

    ._btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
      z-index: 1;
    }

    &-inner {
      width: 123px;
      height: 123px;
      border: 3px solid #FDA21A;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      ._text {
        align-self: center;
        @include heavy;
        line-height: 32px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000000;
        margin: auto;
      }

      ._img {
        margin: auto;
        max-height: 100%;
      }
    }
  }

  &__body {
    padding-top: 12px;
  }

  &__action {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    ._input {
      display: none;
    }

    ._label {

    }
  }

  &__desc {
    line-height: 20px;
    font-size: 14px;
    color: #A1A1A1;
    max-width: 364px;
  }

  &--adaptive {
    .edit-logo {
      &__main {
        @include mobile {
          display: block;
        }
      }

      &__body {
        @include mobile {
          padding-top: 16px;
        }
      }
    }
  }
}