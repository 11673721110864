.header-menu {
  &.opened {
    .header-menu {
      &__btn {
        position: relative;
        z-index: 101;

        &:before {
          content: '';
          @include sprite('close-menu');
        }
      }

      &__body {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__head {

  }

  &__btn {
    &:before {
      content: '';
      @include sprite('burger');
    }
  }

  &__body {
    padding: 57px 32px;
    background-color: #ffffff;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
  }

  &__title {
    line-height: 40px;
    font-size: 30px;
    color: #303030;
    @include bold;
    margin-bottom: 16px;
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid #ECECEC;
  }

  &__link {
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    color: #303030;
    line-height: 14px;
    text-transform: uppercase;
    padding: 32px 0;
    @include bold;
  }
}