.popup-feedback {
  &__inner {

  }

  &__table {
    font-size: 16px;
  }

  &__head {
    color: #a1a1a1;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mobile {
      display: none;
    }
  }

  &__body {

  }

  &__row {
    @include desktop {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }

    @include mobile {
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__col {
    @include no-mobile {
      padding-right: 10px;
      flex: 1;

      &:last-child {
        padding-right: 0;
      }

      &._col-1 {
        flex: 0 0 286px;
        max-width: 286px;
      }
    }

    @include mobile {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include no-mobile {
      justify-content: space-between;
    }
    @include mobile {
      margin: -5px -6px;
    }
  }

  &__item {
    @include no-mobile {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }

    @include mobile {
      padding: 5px;
    }
  }

  &__check {
    ._input {
      display: none;

      &:checked {
        ~ ._label {
          background-color: $primary-color;
          border-color: $primary-color;
          color: #fff;
        }
      }
    }

    ._label {
      display: inline-block;
      min-width: 50px;
      height: 50px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px #EBEBEB;
      background-color: #ffffff;
      font-size: 16px;
      color: #3c4a51;
      @include bold;
      line-height: 17px;
      padding: 16px 5px;
      transition: .3s;
      cursor: pointer;
    }
  }
}