.footer {
  flex-shrink: 0;
  background-color: #fff;

  a:hover {
    text-decoration: underline;
  }

  &__inner {

  }

  .footer__logo {
    @include sprite('logo');
  }

  &__top {
    @include no-mobile {
      display: flex;
      align-items: center;
      padding: 15px 0 20px;
    }

    @include mobile {
      padding: 24px 0;
    }
  }

  &__logo {
    @include sprite('logo-white');
    flex: 0 0 116px;
    max-width: 116px;

    @include desktop {
      margin-right: 80px;
    }

    @include tablet {
      margin-right: 56px;

    }

    @include mobile {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__nav {
    @include tablet {
      margin-left: auto;
    }

    &-list {
      @include no-mobile {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-item {
      @include no-mobile {
        margin-right: 34px;
      }

      @include tablet {
        margin-right: 32px;
      }

      @include mobile {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    &-link {

    }
  }

  &__contact {
    margin-left: auto;
    display: none;

    @include desktop {
      display: block;
    }

    &-list {
      display: flex;
    }

    &-item {
      margin-right: 42px;

      a[href*=mailto] {
        color: $blue-color;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__bottom {
    line-height: 48px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-top: 1px solid rgba(51, 51, 51, 0.1);

    @include mobile {
      padding: 24px 0;
    }
  }

  &__copy {

  }

  &__made {
    margin-left: auto;

    a {
      color: $blue-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--black {
    background: #303030;
    color: #fff;

    a, &__made a {
      color: #fff;
    }

    .footer__bottom {
      border-color: rgba(255, 255, 255, 0.08);;
    }

    .footer__logo {
      @include sprite('logo-white');
    }
  }
}
