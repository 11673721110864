.conditions-block {
  &__inner {
    display: flex;
  }

  &__left {
    margin-right: 130px;
    flex-shrink: 0;

    .info-list {
      &__label {
        flex: 0 0 450px;
        max-width: 450px;
      }

      &__value {
        flex: auto;
      }
    }
  }

  &__right {
    margin-left: auto;
    flex: 0 0 440px;
    max-width: 440px;
  }

  &__note {
    background: #F5F5F5;
    border-radius: 4px;
    padding: 32px;
    color: #303030;
    margin-top: -60px;

    ._title {
      @include heavy;
      font-size: 16px;
      margin-bottom: 16px;
    }

    ._item {
      line-height: 20px;
      font-size: 14px;
    }
  }

  &--edit {
    .conditions-block {
      &__left {
        margin-right: 130px;
        flex-shrink: 0;

        .info-list {

          &__item {
            align-items: center;
          }

          &__label {
            flex: 0 0 450px;
            max-width: 450px;
          }

          &__value {
            flex: 0 0 100px;
            max-width: 100px;
          }
        }
      }

      &__right {
        margin-left: auto;
        flex: 0 0 480px;
        max-width: 480px;
      }

      &__note {
        background: transparent;
        border-radius: 0;
        padding: 0;
        color: #303030;
        margin-top: 0;

        ._title {
          @include heavy;
          font-size: 16px;
          margin-bottom: 16px;
        }

        ._item {
          line-height: 20px;
          font-size: 14px;
        }

        .form-group textarea.form-control {
          height: 144px;
        }
      }
    }
  }
}